import React from 'react';
import { Image } from '../../components';
import { useSelector } from 'react-redux';

import { requestFile, error, success } from '../../tools';
import MicrosoftWordIcon from './icons/microsoft-word.svg';
import { translation } from 'common_constants/translation';


const CourtPracticeItem = ({ item }) => {
  const lang = useSelector((state) => state.common.language);
  const onClickDownloadFile = () => {
    const fileData = {
      fileId: item.fileId,
      fileName: item.fileName,
    };

    requestFile(
      '/profile/courtPractice/downloadFileForClient',
      fileData,
      () => {
        success('', translation.clientCInfDownloadFileSuccess[lang]);
      },
      (err) => {
        error('', translation.clientCInfDownloadFileError1[lang]);
      },
    );
  };

  return (
    <div className="lawyer-info__underline _bottom court-practice-item">
      <b className="court-practice-item__name">{item.name.slice(0, 100)}</b>

      <div className="court-practice-item__top-wrapper">
        <div>
          <Image className="court-practice-item__file" onClick={onClickDownloadFile} preview={false} src={MicrosoftWordIcon} />
        </div>
        <div>
          <p className="court-practice-item__briefly">{item?.briefly}</p>
        </div>
      </div>
      {item?.description ? <p className="court-practice-item__description">{item.description?.substr(0, 150)}</p> : null}
    </div>
  );
};

export default CourtPracticeItem;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { request, requestFile, success, error } from '../../tools';
import { SwiperInsurance } from '../../components/SwiperInsurance/SwiperInsurance';
import AddInsurance from '../../Modals/AddInsurance';
import TransactionsButton from '../ClientHome/TransactionsButton/TransactionsButton';
import ModalWindow from '../../Modals/ModalWindow';
import { Spin } from '../../components';
import Transactions from '../ClientHome/Transactions/Transactions';
import { translation } from 'common_constants/translation';
import { DownloadOutlined } from '@ant-design/icons';
import Button from '../../components/Button';

export const InsuranceList = ({ insurances = [], getInsurances, insuranceTypes = [] }) => {
  const lang = useSelector((state) => state.common.language);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const [nameFiles, setNameFiles] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [transactionsModal, setTransactionsModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const [currentType, setCurrentType] = useState(null);

  const findType = (type) => {
    const insuranceObject = insuranceTypes.find((item) => item.i === Number(type));
    return insuranceObject || { name: 'Не визначено', services: [{ name: 'Не визначено', cost: 0 }] };
  };

  const getNameFiles = () => {
    request('/insurance/getNameFilesForClient', {}, ({ files }) => {
      setNameFiles(files);
      setLoading(false);
    });
  };

  const downloadFile = async (fileId, insuranceId, contractId, fileName) => {
    const transactionData = {
      insuranceId,
      fileId,
      formatFile: 'docx',
      fileName,
    };

    if (contractId) {
      transactionData.contractId = contractId;
    }

    setLoading(true);

    requestFile(
      '/insurance/downloadFileForClient',
      transactionData,
      () => {
        setLoading(false);
        success('', translation.clientCInfDownloadFileSuccess[lang]);
      },
      (err) => {
        setLoading(false);
        error('', translation.clientCInfDownloadFileError[lang], err);
      },
    );
  };

  const handleAddClick = () => {
    setAddModal(true);
  };

  const handleTransactions = () => {
    setTransactions(insurances[activeSlide]?.transactions || []);
    if (insurances[activeSlide]?.transactions) {
      const type = findType(insurances[activeSlide]?.type);
      setCurrentType(type);
    }
  };

  useEffect(() => {
    setLoading(true);

    getNameFiles();
  }, []);

  useEffect(() => {
    handleTransactions();
  }, [activeSlide]);

  return (
    <div className="insurance_list">
      <SwiperInsurance insurances={insurances} insuranceTypes={insuranceTypes} handleAddClick={handleAddClick} setActiveSlide={setActiveSlide} />
      <Spin spinning={loading} fill={false}>
        <div className="files">
          {insurances[activeSlide] &&
            nameFiles.length > 0 &&
            (insurances[activeSlide]?.dateActivation
              ? nameFiles.map((file) => (
                  <Button
                    key={file.L}
                    onClick={() => downloadFile(file.L, insurances[activeSlide]?._id, insurances[activeSlide]?.contractId, file.n)}
                  >
                    <DownloadOutlined /> {file.n}
                  </Button>
                ))
              : nameFiles.map((file) =>
                  file.n === 'Договір ЮС' ? (
                    <Button key={file.L} onClick={() => downloadFile(file.L, insurances[activeSlide]?._id, false, file.n)}>
                      <DownloadOutlined /> {file.n}
                    </Button>
                  ) : null,
                ))}
        </div>
      </Spin>
      {activeSlide <= insurances.length - 1 && (
        <div className="wrapper">
          <TransactionsButton insurance={transactions} insuranceType={currentType} modal={transactions.length && setTransactionsModal} />
        </div>
      )}
      <AddInsurance setInsuranceModal={setAddModal} visible={addModal} getInsurances={getInsurances} insuranceTypes={insuranceTypes} />
      <ModalWindow modal={transactionsModal} setModal={setTransactionsModal}>
        <Transactions insuranceTransactions={transactions} insuranceType={currentType} />
      </ModalWindow>
    </div>
  );
};

export default InsuranceList;

import { useSelector } from 'react-redux';
import { ROUTES } from 'common_constants/routes';

import ClientButton from '../../components/ClientButton';
import SectionName from '../../components/SectionName';
import { translation } from 'common_constants/translation';
import { BookOutlined, FileSearchOutlined } from '@ant-design/icons';

import './Register.scss';

const Register = () => {
  const lang = useSelector((state) => state.common.language);

  return (
    <div className="register">
      <SectionName text={translation.registerTitles.search[lang]} />
      <ul className="links">
        <li>
          <ClientButton
            text={translation.registerTitles.allRegisters[lang]}
            link={ROUTES.REGISTER_LIST}
            antIcon={<BookOutlined style={{ fontSize: '48px', color: '#334768' }} />}
          />
        </li>
        <li>
          <ClientButton
            text={translation.registerTitles.findMe[lang]}
            link={ROUTES.REGISTER_SELF_SEARCH}
            antIcon={<FileSearchOutlined style={{ fontSize: '48px', color: '#334768' }} />}
          />
        </li>
      </ul>
    </div>
  );
};

export default Register;

import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { PaymentManagement, EditableContractName } from '../../../components';

import './DetailsBalance.scss';

const DetailsBalance = ({ contract }) => {
  const [isFullOpen, setIsFullOpen] = useState(false);

  const onExpandDetailsHandle = () => {
    setIsFullOpen(true);
  };

  return (
    <div className={clsx('contract-details', isFullOpen ? '' : 'hide')}>
      <EditableContractName contract={contract} className="contract-details-name" />
      <PaymentManagement contract={contract} totalBalance={true} />
      <button onClick={onExpandDetailsHandle} className={clsx('btn-expand', isFullOpen ? 'hidden' : '')}>
        <DownOutlined />
      </button>
    </div>
  );
};

export default DetailsBalance;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { request } from '../../tools';
import { LoadingOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';

import './Webinar.scss';

const Webinar = () => {
  const params = useParams();
  const [webinar, setWebinar] = useState({});
  const lang = useSelector((state) => state.common.language);
  const frameContainer = (
    <iframe className="webinar-video" src={webinar.link || webinar.teaser} title={webinar.name} allowFullScreen frameBorder="0"></iframe>
  );

  const getInitialData = async () => {
    request(`/webinars/${params.id}`, {}, (res) => {
      setWebinar(res.data);
    });
  };

  useEffect(() => {
    getInitialData();
  }, [params.id]);

  return (
    <div>
      <section className="webinar">
        {webinar && (
          <div className="webinar__body">
            <h3>
              <span>{webinar.link ? '' : translation.webinarTeaser[lang] + ': '}</span>
              <b>{webinar.name}</b>
            </h3>
            <div>
              {webinar.link ? (
                frameContainer
              ) : webinar.teaser ? (
                frameContainer
              ) : webinar.teaserImg ? (
                <div>
                  <img className="teaser-image" src={process.env.REACT_APP_API + `/webinarImages/${webinar.teaserImg}.${webinar.extension}`} alt="" />
                </div>
              ) : (
                <LoadingOutlined />
              )}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Webinar;

import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';

import { Spin } from '../../components';
import HotlineMeetInvite from '../../components/HotlineMeetInvite';
import { request, error } from '../../tools';
import { setHotlineMeetData } from '../../store/commonReducer';

import './HotlineMeetInfo.scss';

const HotlineMeetInfo = () => {
  const history = useHistory();
  const { hash } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [filii, clientAuth] = [useSelector((state) => state.common.filii), useSelector((state) => state.common.clientAuth)];
  const lang = useSelector((state) => state.common.language);
  if (hash?.length !== 24) history.push(ROUTES.HOME);

  const getInfo = () => {
    request(
      '/anon/getHotlineMeet',
      { hash },
      (req) => {
        dispatch(setHotlineMeetData(req.data));
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    if (hash?.length === 24) getInfo();
  }, []);

  return (
    <div className="hotline-meet-info-page">
      <a aria-label="open to map_zahist" href="https://zahist.in.ua/" className="logo">
        <img src="/map_big_bg.png" alt="map_zahist" />
      </a>
      <div style={{ marginTop: clientAuth ? '50px' : '0', paddingTop: clientAuth ? '15px' : '7px' }} className="title">
        <h3>{translation._companyName[lang]} -</h3>
        {translation._companyDescription[lang]}
      </div>

      {loading ? (
        <div>
          <Spin spinning={loading} tip={translation.loader1[lang]} />
        </div>
      ) : (
        <HotlineMeetInvite filii={filii} />
      )}
    </div>
  );
};

export default React.memo(HotlineMeetInfo);

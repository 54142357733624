import { INVITES_LIST_ICON, TO_INVITE, PAY_ICON } from './Icons';

const ButtonIcon = ({ iconType, onClick }) => {
  return (
    <span className="new-icon" onClick={onClick}>
      {iconType === 'inviteList' && INVITES_LIST_ICON}
      {iconType === 'toInvite' && TO_INVITE}
      {iconType === 'payPartner' && PAY_ICON}
    </span>
  );
};

export default ButtonIcon;

import { useRef, useEffect } from 'react';
import clsx from 'clsx';
import './style.scss';

const Textarea = ({ value, onChange, style, placeholder, className, ...props }) => {
  const textareaRef = useRef();

  useEffect(() => {
    if (props?.rHFormOptions) return;

    const textarea = textareaRef.current;
    textarea.style.height = '32px';
    const scrollHeight = textarea.scrollHeight;
    textarea.style.height = `${scrollHeight}px`;
  }, [value]);

  if (props?.rHFormOptions) {
    const { options = {}, id, rHFormOptions, register, isError } = props;
    return (
      <textarea
        style={style}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        className={clsx('custom-textarea', className ? 'className' : '', isError ? 'error' : '')}
        {...options}
        {...register(id, rHFormOptions)}
      ></textarea>
    );
  }

  return (
    <textarea
      style={style}
      ref={textareaRef}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onChange(e)}
      className={`custom-textarea ${className ?? ''}`}
      {...props}
    ></textarea>
  );
};

export default Textarea;

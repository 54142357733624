import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SendOutlined, PaperClipOutlined } from '@ant-design/icons';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import ResizeObserver from 'rc-resize-observer';
import { tools } from 'common_components';
import { translation } from 'common_constants/translation';
import dayjs from 'dayjs';

import { addNewMessageToContractChat } from '../../store/commonReducer';
import { Spin, Button, InputNew as Input } from '..';
import { error } from '../../tools';
import Message from './Message';
import UnverifiedPhoneOverlay from './UnverifiedPhoneOverlay';

import './style.scss';
import AddClientEmail from '../../Modals/AddClientEmail/AddClientEmail';

const nowYear = dayjs().format('YYYY');

// all props are required
const Chat = ({ onSendMessage, onSendFile, onGetMessages, onGetFile, heigthFull, sendFileDirectly, chatId }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const clientAuth = useSelector((state) => state.common.clientAuth);

  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [sendLoader, setSendLoader] = useState(false);
  const [messagesLoader, setMessagesLoader] = useState(false);
  const [showPhoneVerify, setShowPhoneVerify] = useState(false);
  const fileInputRef = useRef();

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  );

  useEffect(() => {
    if (sendFileDirectly === true) {
      fileInputRef.current.click();
    }
  }, []);

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const makeMyMessage = (text) => ({
    message: text,
    date: new Date(),
    sender: clientAuth._id,
    saw: [clientAuth._id],
  });

  const makeMyFileMessage = ({ fileId, fileName, fileSize }) => ({
    fileId,
    fileName,
    fileSize,
    date: new Date(),
    sender: clientAuth._id,
    saw: [clientAuth._id],
  });

  const handleSend = () => {
    if (inputValue === '') return;
    setSendLoader(true);
    setInputValue('');

    onSendMessage({
      message: inputValue,
      callback: () => {
        dispatch(addNewMessageToContractChat({ chatId: chatId, data: makeMyMessage(inputValue) }));
        setMessages([...messages, makeMyMessage(inputValue)]);
        setSendLoader(false);
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const handleAttachFile = (event) => {
    const files = event.target.files;

    if (!files || files.length === 0) return;
    setSendLoader(true);

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file, tools.translit(file.name));
    }

    onSendFile({
      formData: formData,
      callback: (res) => {
        fileInputRef.current.value = '';

        if (Array.isArray(res.data)) {
          const newMessages = res.data.map((fileData) => makeMyFileMessage(fileData));
          for (const fileData of newMessages) {
            dispatch(addNewMessageToContractChat({ chatId: chatId, data: makeMyFileMessage(fileData) }));
          }
          setMessages([...messages, ...newMessages]);
        } else {
          dispatch(addNewMessageToContractChat({ chatId: chatId, data: makeMyFileMessage(res.data) }));
          setMessages([...messages, makeMyFileMessage(res.data)]);
        }

        setSendLoader(false);
      },
      onError: () => {
        setSendLoader(false);
        fileInputRef.current.value = '';
        error(translation.sendingFileError[lang]);
      },
    });
  };

  const handleDownloadFile = (fileId, fileName) => {
    if (!fileId) return;
    setMessagesLoader(true);
    onGetFile({ fileId, fileName, callback: () => setMessagesLoader(false) });
  };

  useEffect(() => {
    setMessagesLoader(true);
    onGetMessages((messages) => {
      setMessages(messages);
      setMessagesLoader(false);
    });
  }, [onGetMessages]);

  const chatContainerHeight = heigthFull ? window.innerHeight - 135 + 'px' : '400px'; // header height + padding, it's needed to optimaze

  return (
    <div className="chat-container">
      {!clientAuth?.phoneVerified ? <UnverifiedPhoneOverlay setShowPhoneVerify={setShowPhoneVerify} showPhoneVerify={showPhoneVerify} /> : null}
      <Spin spinning={messagesLoader}>
        <div style={{ width: '100%', height: chatContainerHeight }}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={cache.current.rowHeight}
                deferredMeasurementCache={cache.current}
                rowCount={messages.length}
                scrollToIndex={messages.length - 1}
                overscanRowCount={0}
                rowRenderer={({ key, index, style, parent }) => {
                  const message = messages[index];
                  const lastMessageDate = index === 0 ? null : dayjs(messages?.[index - 1].date).format('DD.MM.YYYY');
                  const messageDate = dayjs(message.date).format('DD.MM.YYYY');
                  const messageDateYear = dayjs(message.date).format('YYYY');
                  const sameSender =
                    message.sender === messages?.[index + 1]?.sender && messageDate === dayjs(messages?.[index + 1]?.date).format('DD.MM.YYYY');
                  let showDataBlock = false;

                  if (messageDate !== lastMessageDate) {
                    showDataBlock = true;
                  }

                  return (
                    <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                      {({ measure }) => (
                        <div key={key} style={style}>
                          {showDataBlock ? (
                            <div className="date-block">{`${dayjs(message.date).format('DD MMMM')} ${
                              nowYear === messageDateYear ? '' : messageDateYear
                            }`}</div>
                          ) : null}
                          <ResizeObserver
                            onResize={() => {
                              if (message.fileId) {
                                measure();
                              }
                            }}
                          >
                            <Message item={message} sameSender={sameSender} onDownload={() => handleDownloadFile(message.fileId, message.fileName)} />
                          </ResizeObserver>
                        </div>
                      )}
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </AutoSizer>
        </div>
        <div className="input-container">
          <input type="file" onChange={handleAttachFile} style={{ display: 'none' }} ref={fileInputRef} multiple aria-label="download files" />
          <Button
            style={{ margin: '0px', width: 32 }}
            variant="orange straight-right"
            onClick={() => fileInputRef.current.click()}
            loading={sendLoader}
            icon={<PaperClipOutlined />}
          />
          <Input
            style={{ borderRadius: '0px' }}
            placeholder={translation.inputQuickQuestion[lang]}
            value={inputValue}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
          />
          <Button
            variant="default straight-left"
            style={{ margin: '0px', width: 32 }}
            onClick={handleSend}
            loading={sendLoader}
            icon={<SendOutlined />}
          />
        </div>
      </Spin>
      {showPhoneVerify && !clientAuth.phoneVerified ? <AddClientEmail /> : null}
    </div>
  );
};

export default Chat;

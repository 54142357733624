import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import Modal from '../../components/Modal';
import { Row } from '../../components';
import { Spin } from '../../components';

import { NOTIFICATION } from 'common_constants/modals';

import { setModal } from '../../store/commonReducer';

const ConfirmEmailAnonim = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.common.modal);
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.common.language);
  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_API + '/auth/emailVerifyThroughLink', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: data.email, verificationCode: data.verificationCode }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          dispatch(setModal({ name: NOTIFICATION, data: { email: data.email } }));
          setLoading(false);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const onClose = () => {
    dispatch(setModal());
  };
  return (
    <Modal title={translation.confirmEmailAnonimTitle[lang]} open footer={false} onCancel={onClose}>
      <Row justify="center">
        <Spin tip={translation.loader[lang]} spinning={loading}></Spin>
      </Row>
    </Modal>
  );
};

export default ConfirmEmailAnonim;

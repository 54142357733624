import './Toaster.scss';
import { ToastItem } from '.';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { removeToast } from '../../store/toastReducer';

const Toaster = ({ position = 'top-right' }) => {
  const toasts = useSelector((state) => state.toast.toasts);
  const dispatch = useDispatch();
  return (
    <div className={clsx('toaster', position)}>
      {toasts.map((toast) => (
        <ToastItem key={toast.id} toast={toast} remove={(id) => dispatch(removeToast(id))} />
      ))}
    </div>
  );
};
export default Toaster;

import { useSelector } from 'react-redux';
import { WEB_SITE_LINK } from 'common_constants/routes';
import './NewsSwiper.scss';

export const NewsSwiper = () => {
  const news = useSelector((state) => state.common.newsFromSite?.slice(0, 15));
  return (
    <div className="news-swiper">
      <swiper-container class="mySwiper" space-between="-12" slides-per-view="auto" free-mode="true" initial-slide="news" speed={250} touchRatio={1.3} touchAngle={35}>
        {news &&
          news.map((item) => (
            <swiper-slide key={item.title}>
              <a href={`${WEB_SITE_LINK}/${item.link}.html`} rel="noreferrer" target="_blank">
                <div className="news-content">
                  <img
                    src={item.image ? `${process.env.REACT_APP_API}/websiteImages/${item.image}.${item.ext}` : '/ao-zahist.jpg'}
                    alt={item.title}
                  />
                  <div className="news-title">{item.title}</div>
                </div>
              </a>
            </swiper-slide>
          ))}
      </swiper-container>
    </div>
  );
};

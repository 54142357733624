export class ConfettiCanvas {
  lastTime = 0;
  frame = null;

  constructor(canvas, timeEnd) {
    this.ctx = canvas.getContext('2d');
    this.width = canvas.width = window.innerWidth;
    this.height = canvas.height = window.innerHeight;
    this.confetti = [];
    this.timeEnd = timeEnd;

    window.addEventListener('resize', () => {
      this.width = canvas.width = window.innerWidth;
      this.height = canvas.height = window.innerHeight;
    });
  }
  addConfetto() {
    let randomOffsetX = (Math.random() - 0) * 100 * (Math.random() > 0.5 ? 1 : -1);
    let randomOffsetY = Math.random() * 100 * (Math.random() > 0.5 ? 1 : -1);

    this.confetti.push(new Confetto(Math.random() * this.width, Math.random() * this.height * 0.7 - 50, randomOffsetX, randomOffsetY));
  }
  update(time = 0) {
    const deltaTime = time - this?.lastTime;
    this.lastTime = time;
    if (!this.timeEnd || time < this.timeEnd) {
      for (let i = 0; i < 10; i++) {
        this.addConfetto();
      }
    }

    this.ctx.clearRect(0, 0, this.width, this.height);
    this.confetti.forEach((confetto) => {
      confetto.update(deltaTime);
      confetto.draw(this.ctx);
    });

    this.confetti = this.confetti.filter((confetto) => confetto.size > 0 && confetto.y < this.height && confetto.x > 0 && confetto.x < this.width);

    this.frame = window.requestAnimationFrame(this.update.bind(this));
  }

  destroy() {
    window.cancelAnimationFrame(this.frame);
  }
}

class Confetto {
  constructor(x, y, velocityX, velocityY) {
    this.x = x;
    this.y = y;
    this.size = Math.random() * 14;
    this.colors = ['#80EF56', '#5A05C8', '#F136F1', '#ECEF3F', '#B5B804', '#EF9C2C', '#A35E02', '#25C0EF', '#EF5977', '#02789C'];
    this.color = this.colors[Math.floor(Math.random() * this.colors.length)];
    this.velocityX = velocityX * 0.05;
    this.velocityY = 1 + Math.random() + velocityY * 0.005;
    this.gravity = 0.03;
    this.drag = 0.97;
    this.timeToLive = 1000;
    this.angle = Math.random() * 2 * Math.PI;
  }

  draw(ctx) {
    ctx.fillStyle = this.color;
    ctx.beginPath();

    const size = this.size;
    const halfSize = size / 2;
    const angle = this.angle;

    const topLeftX = this.x + halfSize * Math.cos(angle - Math.PI / 4);
    const topLeftY = this.y + halfSize * Math.sin(angle - Math.PI / 4);

    const topRightX = this.x + halfSize * Math.cos(angle + Math.PI / 4);
    const topRightY = this.y + halfSize * Math.sin(angle + Math.PI / 4);

    const bottomRightX = this.x + halfSize * Math.cos(angle + (3 * Math.PI) / 4);
    const bottomRightY = this.y + halfSize * Math.sin(angle + (3 * Math.PI) / 4);

    const bottomLeftX = this.x + halfSize * Math.cos(angle - (3 * Math.PI) / 4);
    const bottomLeftY = this.y + halfSize * Math.sin(angle - (3 * Math.PI) / 4);

    // Draw the square
    ctx.moveTo(topLeftX, topLeftY);
    ctx.lineTo(topRightX, topRightY);
    ctx.lineTo(bottomRightX, bottomRightY);
    ctx.lineTo(bottomLeftX, bottomLeftY);

    ctx.closePath();
    ctx.fill();
  }

  update(deltaTime) {
    this.x += this.velocityX;
    this.velocityX *= this.drag;
    this.y += this.velocityY;
    this.velocityY += this.gravity;
    this.size = Math.max(0, this.size - (this.size * deltaTime) / this.timeToLive);
  }
}

import React, { useState, cloneElement } from 'react';
import './Form.scss';

const Form = ({ onFinish, children, initialValues, style, className }) => {
  const [value, setValue] = useState(initialValues);
  const [errors, setErrors] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!errors) {
      onFinish(value);
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleErrorsChange = (errors) => {
    setErrors(Object.keys(errors).length > 0);
  };

  if (!Array.isArray(children)) {
    children = [children];
  }

  return (
    <form onSubmit={handleSubmit} onChange={handleChange} style={style} className={className}>
      {children.flat(2).map((child) =>
        cloneElement(child, {
          onErrorsChange: handleErrorsChange,
        }),
      )}
    </form>
  );
};

const useForm = () => {
  const [form, setForm] = useState({});

  const setFieldValue = (name, value) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  return [form, setFieldValue];
};

const Item = (props) => {
  const [errors, setErrors] = useState({});
  const { onErrorsChange } = props;
  const validate = (rule, value) => {
    let isValid = true;
    const newErrors = {};
    rule.forEach((r, key) => {
      if (r.required && (!value || (r.len && r.len !== value.length))) {
        isValid = false;
        newErrors.message = r.message;
        setErrors({ message: r.message });
      } else if (r.pattern && !r.pattern.test(value)) {
        isValid = false;
        newErrors.message = r.message;
        setErrors({ message: r.message });
      }
    });

    setErrors(newErrors);
    onErrorsChange?.(newErrors);

    return isValid;
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const validResult = validate(props.rules, value);
    if (validResult) setErrors({});
  };

  return (
    <div className="item-container">
      <div onChange={handleChange} className={`inner-wrapper ${props.className ?? ''}`}>
        {props.label && <label>{props.label}</label>}
        {props.children && <div className="inner-children">{props.children}</div>}
      </div>
      {errors.message && <div className="error-container">{errors.message && <div>{errors.message}</div>}</div>}
    </div>
  );
};
Form.useForm = useForm;
Form.Item = Item;

export default Form;

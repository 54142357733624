import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from 'common_components';

import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Typography from '../../components/Typography';
import Spin from '../../components/Spin';
import Space from '../../components/Space';
import TextArea from '../../components/Textarea';
import { setModal, setClientInfo, editContracts } from '../../store/commonReducer';
import SearchSelect from '../../components/SearchSelect';
import { request, warn, info, error } from '../../tools';
import {
  DECIMAL_RATING_TO_CLIENT,
  JOB_SATISFACTION_TO_CLIENT,
  LOGICAL_STANDARD_CHOISE_4_OPTIONS,
  LOGICAL_STANDARD_CHOISE_2_OPTIONS,
  LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS,
} from 'common_constants/business/index';
import { translation } from 'common_constants/translation';
import { QUESTIONNAIRE_ARCHIVE_ADVERTISING, ADD_CLIENT_EMAIL, FIRST_LOGIN_AUTO_PAY } from 'common_constants/modals';

import './styles.scss';

const QuestionnaireArchive = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.common.modal);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const missingEmail = useSelector((state) => state.common.missingEmail);
  const lang = useSelector((state) => state.common.language);

  const { Text, Title } = Typography;

  const [loading, setLoading] = useState(false);
  const [desError, setDesError] = useState('');

  const [sq, setSQ] = useState(); // * Оцінка якості \_\_Number()
  const [sw, setSW] = useState(); // * Задоволення роботою? \_\_Number()
  const [sc, setSC] = useState(); // * Задоволення комунікацією? \_\_Number()
  const [ca, setCA] = useState(); // * Звернувся б ще? \_\_Number()
  const [as, setAS] = useState(); // * Порадили комусь? \_\_Number()
  const [ts, setTS] = useState(); // * Довіряєте компанії? \_\_Number()
  const [ac, setAC] = useState(); // * Чи було надано цукерку? \_\_Number()
  const [pc, setPC] = useState(''); // * Пропозиції та скарги. \_\_String()

  const desChangeHandler = (e) => {
    setPC(e.target.value);
    const error = e.target.value.length < 20 && e.target.value;
    setDesError(error ? 'error' : '');
  };

  const chainFirstModals = () =>
    missingEmail
      ? dispatch(setModal({ name: ADD_CLIENT_EMAIL }))
      : sessionStorage.getItem('modalFirst') !== 'true'
      ? dispatch(setModal({ name: FIRST_LOGIN_AUTO_PAY }))
      : dispatch(setModal());

  const onSubmit = () => {
    const validation = () => {
      if (!clientInfo._id) {
        warn('', translation.questionnaireArchiveWarn1[lang]);
        return;
      }
      if (sq === undefined || sq === '') {
        warn('', translation.questionnaireArchiveWarn2[lang]);
        return;
      }
      if (sw === undefined || sw === '') {
        warn('', translation.questionnaireArchiveWarn3[lang]);
        return;
      }
      if (sc === undefined || sc === '') {
        warn('', translation.questionnaireArchiveWarn4[lang]);
        return;
      }
      if (ca === undefined || ca === '') {
        warn('', translation.questionnaireArchiveWarn5[lang]);
        return;
      }
      if (as === undefined || as === '') {
        warn('', translation.questionnaireArchiveWarn6[lang]);
        return;
      }
      if (ts === undefined || ts === '') {
        warn('', translation.questionnaireArchiveWarn7[lang]);
        return;
      }
      if (ac === undefined || ac === '') {
        warn('', translation.questionnaireArchiveWarn7[lang]);
        return;
      }
      if (pc === undefined || pc === '') {
        warn('', translation.questionnaireArchiveWarn8[lang]);
        return;
      }
      if (pc?.length < 20) {
        warn('', translation.questionnaireArchiveWarn9[lang]);
        return;
      }
      return true;
    };

    if (!validation()) return;

    setLoading(true);

    const isFbBonus = sq >= 7 && as === 0 && ts === 0;

    const transaction = isFbBonus
      ? {
          qa: { sq, sw, sc, ca, as, ts, ac, pc },
          C: data.C,
          _id: clientInfo._id,
          B: clientInfo.B,
          fbBonus: false,
        }
      : {
          qa: { sq, sw, sc, ca, as, ts, ac, pc },
          C: data.C,
          _id: clientInfo._id,
          B: clientInfo.B,
        };

    const updateData = {
      qa: { sq, sw, sc, ca, as, ts, ac, pc },
      _id: data.C,
    };

    request(
      '/contracts/QuestionnaireArchive',
      transaction,
      (req) => {
        info('', translation.questionnaireArchiveInfo[lang]);
        sq >= 4 || ca === 0 || as === 0 || ts <= 1
          ? dispatch(setModal({ name: QUESTIONNAIRE_ARCHIVE_ADVERTISING, transaction }))
          : chainFirstModals();
        dispatch(
          setClientInfo({
            ...clientInfo,
            B: req.B,
          }),
        );
        dispatch(editContracts(updateData));
      },
      error,
    );

    setLoading(false);
  };

  const onCancel = () => {
    info('', translation.QuestionnaireInfo[lang]);
    chainFirstModals();
  };

  return (
    <Modal
      open
      className="questionnaire-archive"
      title={
        <Title style={{ margin: 0, paddingRight: 16 }} level={4} underline>
          {translation.startQuestionnaire[lang]}
        </Title>
      }
      onCancel={onCancel}
      footer={null}
    >
      <Spin tip={translation.loader1[lang]} spinning={loading}>
        <Box mt={16}>
          <Space direction="vertical" className="space">
            <Text strong>{translation.QuestionnaireArchive1[lang]}</Text>

            <Box>
              <Text strong>{translation.QuestionnaireArchive2[lang]}</Text>
              <br />
              <Text>({translation.questionnaireArchiveText[lang]})</Text>
              <SearchSelect
                allowClear
                list={DECIMAL_RATING_TO_CLIENT.map((i) => ({ value: i, label: i }))}
                valueName={'value'}
                labelName={'label'}
                value={sq}
                placeholder={translation.questionnaireArchivePlaceholder[lang]}
                onChange={(item) => setSQ(item)}
                name="rate"
              />
            </Box>

            <Box>
              <Text strong>{translation.QuestionnaireArchive3[lang]}</Text>
              <SearchSelect
                className={'questionnaire-archive__select'}
                allowClear
                list={JOB_SATISFACTION_TO_CLIENT}
                value={sw}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setSW(item)}
                name="outcome-satisfaction"
              />
            </Box>

            <Box>
              <Text strong>{translation.QuestionnaireArchive4[lang]}</Text>
              <SearchSelect
                className={'questionnaire-archive__select'}
                allowClear
                list={LOGICAL_STANDARD_CHOISE_4_OPTIONS}
                value={sc}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setSC(item)}
                name="communicationt-satisfaction"
              />
            </Box>

            <Box>
              <Text strong>{translation.QuestionnaireArchive5[lang]}</Text>
              <SearchSelect
                className={'questionnaire-archive__select'}
                allowClear
                list={LOGICAL_STANDARD_CHOISE_2_OPTIONS}
                value={ca}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setCA(item)}
                name="customer-return"
              />
            </Box>

            <Box>
              <Text strong>{translation.QuestionnaireArchive6[lang]}</Text>
              <SearchSelect
                className={'questionnaire-archive__select'}
                allowClear
                list={LOGICAL_STANDARD_CHOISE_2_OPTIONS}
                value={as}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setAS(item)}
                name="reccommend"
              />
            </Box>

            <Box>
              <Text strong>{translation.QuestionnaireArchive7[lang]}</Text>
              <SearchSelect
                className={'questionnaire-archive__select'}
                allowClear
                list={LOGICAL_STANDARD_CHOISE_4_OPTIONS}
                value={ts}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setTS(item)}
                name="trust"
              />
            </Box>

            <Box>
              <Text strong>{translation.QuestionnaireArchive11[lang]}</Text>
              <SearchSelect
                className={'questionnaire-archive__select'}
                allowClear
                list={LOGICAL_ARCHIVE_CANDY_CHOISE_3_OPTIONS}
                value={ac}
                placeholder={translation.placeholderChooseOption[lang]}
                onChange={(item) => setAC(item)}
                name="archive-candy"
              />
            </Box>

            <Box>
              <Text strong>{translation.QuestionnaireArchive8[lang]}</Text>
              <br />
              <Text>({translation.QuestionnaireArchive9[lang]})</Text>
              <TextArea
                className={'questionnaire-archive__select'}
                placeholder={translation.QuestionnaireArchive10[lang]}
                aria-label="Write proposals and complaints"
                value={pc}
                onChange={desChangeHandler}
                status={desError}
                autoSize
              />
            </Box>
          </Space>
        </Box>
        <Box className="group-btn-questionnaire">
          <Button className="btn-questionnaire" onClick={onSubmit}>
            {translation.sendButton[lang]}
          </Button>
          <Button danger className="btn-questionnaire" variant="danger" onClick={onCancel}>
            {translation.btnCancelQuestionnaire[lang]}
          </Button>
        </Box>
      </Spin>
    </Modal>
  );
};

export default QuestionnaireArchive;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { request, error, success } from '../../tools';
import reactNativeService from '../../tools/reactNativeService';
import { PAYMENT_STATUS, CURRENCIES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { setModal } from '../../store/commonReducer';
import Modal from '../../components/Modal/Modal';
import { CheckClientInfo } from '../../tools/hooks';
import './BuyWebinarModal.scss';

const BuyWebinarModal = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const currentCurrency = clientInfo?.currency ?? CURRENCIES.UAH;
  const { data } = useSelector((state) => state.common.modal);

  const [loading, setLoading] = useState(false);

  const onCancel = () => dispatch(setModal());

  const handleBuyWebinar = () => {
    const body = { webinarId: data._id, currency: currentCurrency };

    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          CheckClientInfo(dispatch);
          success(translation.webinarPurchased[lang]);
          setLoading(false);
          onCancel();
        }, 1000);
      }
      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              success(translation.webinarPurchased[lang]);
              setLoading(false);
              onCancel();
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              error(translation.addInsuranceError[lang]);
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
      }
    };
    const onError = (_, __, axiosError) => {
      error('Помилка оплати!', axiosError.error);
      setLoading(false);
    };
    setLoading(true);
    request('/payment/mono/webinar/link', body, onSuccess, onError);
  };

  useEffect(() => {
    setLoading(false);
  }, [clientInfo?.webinars]);

  return (
    <Modal open title={translation.webinar[lang] + data.name} onCancel={onCancel} footer={false}>
      <div className="webinarPay">
        <div className="inner">
          <div className="tab" style={{ display: 'flex', justifyContent: 'space-around' }}></div>
          <div className="body">
            <div className="row image-wrapper">
              <img src={process.env.REACT_APP_API + `/webinarImages/${data.teaserImg}.${data.extension}`} alt={data.name} />
            </div>
          </div>
          <div className="total">
            <span className="title">{translation.totalPrice[lang]}</span>
            <span className="amount">
              {data.price}
              {translation.currencyUah[lang]}
            </span>
          </div>
        </div>
        <button onClick={handleBuyWebinar} className={`button${loading ? ' loading' : ''}`}>
          {translation.buyWebinar[lang]}
        </button>
      </div>
    </Modal>
  );
};

export default BuyWebinarModal;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { setModal, setContracts, setQuickChatQuestions, setQuickChatLoader } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { fetchContracts } from '../../pages/ClientContracts/ClientContractsHelpers';
import { request, error } from '../../tools';
import { setHotlineMeetData } from '../../store/commonReducer';
import { CheckClientInfo } from '../../tools/hooks';
import { translation } from 'common_constants/translation';

import './PaymentFrame.scss';
import { ADD_TIPS } from 'common_constants/modals';
import Row from '../../components/Row';

const PaymentFrame = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.common.modal);
  const lang = useSelector((state) => state.common.language);

  const closeContractPayment = () => {
    const getData = async () => {
      dispatch(setMainLoader(true));
      const contracts = await fetchContracts();

      dispatch(setContracts(contracts));

      dispatch(setMainLoader(false));
    };
    getData();
    dispatch(setModal({ name: ADD_TIPS }));
  };

  const closeHotlineMeetPayment = () => {
    request(
      '/anon/getHotlineMeet',
      { hash: data.hotlineId },
      (req) => {
        dispatch(setHotlineMeetData(req.data));
      },
      error,
    );
    dispatch(setModal({ name: ADD_TIPS }));
  };

  const closeMyHotlineMeetPayment = () => dispatch(setModal({ name: ADD_TIPS }));

  const closeClientBalancePayment = () => {
    CheckClientInfo(dispatch);
    dispatch(setModal({ name: ADD_TIPS }));
  };

  const closeQuickChatPayment = () => {
    request(
      '/quickChat/countQuestions',
      { userId: data.userId },
      ({ countQuestions }) => {
        dispatch(setQuickChatQuestions(countQuestions));
        dispatch(setQuickChatLoader(false));
      },
      error,
    );
    CheckClientInfo(dispatch);
    dispatch(setModal({ name: ADD_TIPS }));
  };

  const closeModal = () => {
    if (data.payType === 'myHotline') {
      closeMyHotlineMeetPayment();
    }

    if (data.payType === 'hotline') {
      closeHotlineMeetPayment();
    }

    if (data.payType === 'contract') {
      closeContractPayment();
    }

    if (data.payType === 'clientBalance' || data.payType === 'redButton') {
      closeClientBalancePayment();
    }

    if (data.payType === 'insurance') {
      dispatch(setModal({ name: ADD_TIPS }));
      data.getInsuranceTransactions();
    }

    if (data.payType === 'clubCard') {
      dispatch(setModal({ name: ADD_TIPS }));
      CheckClientInfo(dispatch);
    }
    if (data.payType === 'quickChat') {
      closeQuickChatPayment();
    }

    if (data.payType === 'thanksLawyer') {
      CheckClientInfo(dispatch);
      dispatch(setModal());
    }

    if (data.payType === 'tips') {
      dispatch(setModal());
      CheckClientInfo(dispatch);
    }

    if (data.payType === 'webinar') {
      dispatch(setModal());
      CheckClientInfo(dispatch);
    }
  };

  const paymentType = {
    myHotline: translation.paymentFrameType1[lang],
    hotline: translation.paymentFrameType2[lang],
    clientBalance: translation.paymentFrameType3[lang],
    contract: translation.paymentFrameType4[lang],
    redButton: translation.paymentFrameType5[lang],
    quickChat: translation.paymentFrameType6[lang],
    thanksLawyer: translation.paymentFrameType7[lang],
  }[data.payType];

  return (
    <Modal
      className="payment-modal"
      title={paymentType}
      open={true}
      onCancel={closeModal}
      style={{
        maxWidth: 800
      }}
      footer={
        <Row justify={'center'}>
          <Button variant="light" style={{ width: 'auto', padding: '0 10px' }} onClick={closeModal}>
            {translation.closeBtn[lang]}
          </Button>
        </Row>
      }
    >
      <iframe
        allow="overflow"
        className="payment-modal__window"
        title={translation.CC2PaymentsIframeTitle[lang]}
        src={data.paymentUrl}
        frameBorder="0"
        style={{ width: '100%', height: '530px' }}
      />
    </Modal>
  );
};

export default PaymentFrame;

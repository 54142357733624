import { useState } from 'react';
import { useSelector } from 'react-redux';
import ModalWindow from '../ModalWindow';
import { request, error, success, warn } from '../../tools';
import BalanceNewButton from '../../components/Balance/BalanceNewButton';
import { translation } from 'common_constants/translation';
import './LeaveSuggestionModal.scss';

const LeaveSuggestionModal = () => {
  const [open, setOpen] = useState(true);
  const [suggestion, setSuggestion] = useState('');
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.common.language);
  const handleClick = () => {
    if (suggestion.length < 20) {
      warn('', translation.leaveSuggestionModalWarn[lang]);
      return;
    }
    setLoading(true);
    const onSuccess = () => {
      success(translation.leaveSuggestionModalSuccess[lang]);
      setLoading(false);
      setOpen(false);
    };
    const onError = () => {
      error(translation.leaveSuggestionModalError[lang]);
      setLoading(false);
    };
    request('/suggestions/add', { suggestion }, onSuccess, onError);
  };

  return (
    <ModalWindow modal={open} setModal={setOpen} title={translation.leaveSuggestionModalTitle[lang]}>
      <div className={`suggestions-modal `}>
        <p className="suggestions-text">
          {translation.leaveSuggestionModalText1[lang]}
          <b> {translation.leaveSuggestionModalText2[lang]} </b>
        </p>
        <textarea disabled={loading} className="input-new suggestions-input" rows={4} onChange={(e) => setSuggestion(e.target.value)} />
        <div className="buttons">
          <button type="button" className="button-not" onClick={() => setOpen(false)}>
           {translation.notNowBtn[lang]}
          </button>
          <BalanceNewButton disabled={loading} onClick={handleClick} buttonText={translation.sendButton[lang]} arrow={false} />
        </div>
      </div>
    </ModalWindow>
  );
};

export default LeaveSuggestionModal;

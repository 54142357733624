import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SectionName from '../../components/SectionName';
import { translation } from 'common_constants/translation';
import { REGISTER_PRICE } from 'common_constants/business';
import { setMainLoader } from '../../store/uiReducer';
import { error, request } from '../../tools';
import { renderList } from '../../tools/registerHepler';
import { ClientBalancePayModal } from '../../components';
import { setClientBalance } from '../../store/commonReducer';

import './RegisterSelfSearch.scss';

const RegisterSelfSearch = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const { clientBalance } = clientInfo;
  const [filter, setFilter] = useState({
    alimony: false,
    corruptioner: false,
  });
  const [showResults, setShowResults] = useState(false);
  const [showStatus, setShowStatus] = useState({
    alimony: false,
    corruptioner: false,
  });
  const [alimonyList, setAlimonyList] = useState([]);
  const [corruptionerList, setCorruptionerList] = useState([]);
  const [showPayModal, setShowPayModal] = useState(false);
  const [price, setPrice] = useState();

  const handleClickSearch = (event) => {
    event.preventDefault();
    dispatch(setMainLoader(true));
    const hasAnyRegister = Object.values(filter).some((value) => value === true);
    if (!hasAnyRegister) {
      error(translation.error[lang], translation.registerError.selectRegisters[lang]);
      dispatch(setMainLoader(false));
      return;
    }
    if (clientBalance < price) {
      error(translation.registerError.notEnoughFunds[lang], translation.registerError.topUpYourBalance[lang]);
      setShowPayModal(true);
      dispatch(setMainLoader(false));
      return;
    }
    request(
      `/register/selfSearch`,
      {
        filter,
      },
      (res) => {
        setAlimonyList(res.results?.alimony?.data || []);
        setCorruptionerList(res.results?.corruptioners?.corruptioners || []);
        dispatch(setMainLoader(false));
        setShowResults(true);
        setShowStatus({
          alimony: filter.alimony,
          corruptioner: filter.corruptioner,
        });
        dispatch(setClientBalance(clientBalance - price));
      },
      (_, __, axiosError) => {
        error(translation.error[lang], axiosError.error);
        dispatch(setMainLoader(false));
      },
    );
  };

  const handleClickCkeckbox = (event) => {
    const register = event.target.dataset.type;
    setFilter((prev) => ({
      ...prev,
      [register]: !prev[register],
    }));
  };

  useEffect(() => {
    const count = Object.values(filter);
    const price = count.reduce((acc, current) => (current ? acc + REGISTER_PRICE : acc), 0);
    setPrice(price);
  }, [filter]);

  return (
    <div className="registerSelfSearch">
      <SectionName text={clientInfo.n} />
      <section className="findMe">
        <h3 className="title">{translation.registerTitles.findMeInReg[lang]}</h3>
        <ul className="list">
          <li className="registerRow">
            <span className="label">{translation.registerTitles.alimonyDebtors[lang]}</span>
            <div data-type="alimony" onClick={handleClickCkeckbox} className={`checkbox ${filter.alimony ? 'checked' : null} `}>
              <span data-type="alimony"></span>
              <span data-type="alimony"></span>
            </div>
          </li>
          <li className="registerRow">
            <span className="label">{translation.registerTitles.registerOfCorruptioners[lang]}</span>
            <div data-type="corruptioner" onClick={handleClickCkeckbox} className={`checkbox ${filter.corruptioner ? 'checked' : null} `}>
              <span data-type="corruptioner"></span>
              <span data-type="corruptioner"></span>
            </div>
          </li>
        </ul>
        <div className="info">
          <span>
            {translation.balance[lang]}: {clientBalance} {translation.currencyUah[lang]}
          </span>
          <span>
            {translation.registerTitles.priceForOneRegister[lang]}: {REGISTER_PRICE} {translation.currencyUah[lang]}
          </span>
        </div>
        <div className="total">
          <span>{translation.registerTitles.totalAmount[lang]}:</span>
          <span>
            {price} {translation.currencyUah[lang]}
          </span>
        </div>
        <input onClick={handleClickSearch} className="button" type="button" value={translation.registerTitles.searchBtn[lang]} />
        {showResults ? (
          <div className="results">
            {showStatus.alimony && <h3 className="registerName">{translation.registerTitles.alimonyDebtors[lang]}</h3>}
            {showStatus.alimony && <ul className="list">{renderList(alimonyList, translation.registerOpts['alimony'], lang)}</ul>}
            {showStatus.corruptioner && <h3 className="registerName">{translation.registerTitles.registerOfCorruptioners[lang]}</h3>}
            {showStatus.corruptioner && <ul className="list">{renderList(corruptionerList, translation.registerOpts['corruptioner'], lang)}</ul>}
          </div>
        ) : null}
      </section>
      <ClientBalancePayModal
        arrayPayment={[250, 500, 1000, 1500, 2500, 5000]}
        open={showPayModal}
        setOpen={setShowPayModal}
        title={translation.replenishmentBalance[lang]}
        placeholder={translation.clientBalancePayModalPlaceholder[lang]}
        buttonText={translation.clientBalancePayModalButtonText[lang]}
      />
    </div>
  );
};

export default RegisterSelfSearch;

import React from 'react';
import { translation } from 'common_constants/translation';

const SuccessMessage = ({ clientHotlineInfo, lang }) => {
  const { type, fil, startT, endT, date } = clientHotlineInfo;
  return (
    <div>
      <div>
        <strong>{translation.clientHotlineSuccessMessage1[lang]} </strong>
        <span>{type}</span>
      </div>
      <div>
        <strong>{translation.clientHotlineSuccessMessage2[lang]} </strong>
        <span>{fil}</span>
      </div>
      <div>
        <strong>{translation.clientHotlineSuccessMessage3[lang]} </strong>
        <span>{date.format('DD.MM.YYYY')}</span>
      </div>
      <div>
        <strong>{translation.clientHotlineSuccessMessage4[lang]} </strong>
        <span>{startT}</span>
      </div>
      <div>
        <strong>{translation.clientHotlineSuccessMessage5[lang]} </strong>
        <span>{endT}</span>
      </div>

      <p>{translation.clientHotlineSuccessMessage6[lang]}</p>
    </div>
  );
};

export default SuccessMessage;

import clsx from 'clsx';

const Row = ({ children, className, style, justify, align }) => (
  <div
    className={clsx('row', className)}
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: justify,
      alignItems: align,
      ...style,
    }}
  >
    {children}
  </div>
);

export default Row;

import { request, error } from '../tools';

const apiService = {
  async getContracts() {
    return await request(
      '/contracts/getForClient',
      {},
      (res) => {
        return res.contracts;
      },
      error,
    );
  },
  async getHotlines() {
    return await request(
      '/hotline/getForClient',
      {},
      (req) => {
        return {
          hotlines: [...req.clientHotlines[0], ...req.historyHotlines[0]],
          _clientHotlines: req.clientHotlines,
          _certHotline: req.certHotline,
          _historyHotlines: req.historyHotlines?.[0] ?? null,
        };
      },
      error,
    );
  },
  async getCourts() {
    return await request(
      '/court/getForClient',
      {},
      (res) => {
        return res.courts;
      },
      error,
    );
  },
  async getPlaces() {
    return await request(
      '/place/getForClient',
      {},
      (res) => {
        return res.places;
      },
      error,
    );
  },
  async setClientContractName(id, cn) {
    return await request(
      '/contracts/setClientContractName',
      { id, cn },
      () => true,
      () => false,
    );
  },
  async removeNotifications(nameNotif, _id, i, phone) {
    return await request(
      '/notifications/removeNotificationsMultiInEAdvokat',
      { nameNotif, _id, i, phone },
      (req) => {
        return req.data;
      },
      error,
    );
  },
  async removePushes(ids) {
    return await request(
      '/pushNotification/setWatched',
      { ids },
      (req) => {
        return req.data;
      },
      error,
    );
  },
};

export default apiService;

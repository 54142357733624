import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../components/Modal';

import { setModal, addMessageAccountantChat, setThreadIdAccountantChat, setAccountantChats } from '../../store/commonReducer';
import { request, error } from '../../tools';
import { translation } from 'common_constants/translation';
import { useEffect, useRef, useState } from 'react';
import { Button, InputNew } from '../../components';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { SendOutlined } from '@ant-design/icons';
import { ReactComponent as Pen } from '../../images/pen.svg';
import Message from '../../components/Chat/Message';

import './styles.scss';
import { FEMALE_ASSISTANTS, MALE_ASSISTANTS } from 'common_constants/business';

const ChatForContract = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.common.modal);
  const clientAuth = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const accountantChats = useSelector((state) => state.common.accountantChats);
  const [sendLoader, setSendLoader] = useState(false);
  const [messages, setMessages] = useState([]);
  const [avatar, setAvatar] = useState();

  const [inputValue, setInputValue] = useState('');

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  );

  const findCurrentChat = accountantChats?.find((item) => item._id === data._id) || null;
  const threadId = findCurrentChat?.threadId;
  const processWithAI = findCurrentChat?.processWithAI;
  const allAssistants = [...MALE_ASSISTANTS, ...FEMALE_ASSISTANTS];

  useEffect(() => {
    setMessages(findCurrentChat.chat ?? []);
    const index = findCurrentChat.assistantIndex;
    const avatarName = allAssistants.find((asst) => asst.index === index)?.avatar;
    import('../../images/' + avatarName).then((avatar) => setAvatar(avatar?.default));
  }, [data._id, accountantChats]);

  useEffect(() => {
    if (threadId || !processWithAI) return;
    createThread();
  }, [threadId]);

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !sendLoader) {
      handleSend();
    }
  };

  const makeMyMessage = (text) => ({
    message: text,
    fromUser: true,
    date: new Date(),
  });

  const createThread = () => {
    let errorOccurred;
    do {
      errorOccurred = false;
      try {
        request('/accountantChat/createThread', { chatId: data._id }, (res) => {
          dispatch(setThreadIdAccountantChat({ threadId: res.threadId, _id: data._id }));
        });
      } catch (error) {
        errorOccurred = true;
      }
    } while (errorOccurred);
  };

  const runsAssistant = async () => {
    try {
      let response;
      await request('/accountantChat/runAssistant', { threadId: threadId, assistantIndex: findCurrentChat.assistantIndex }, (res) => {
        response = res.runId;
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const checkAssistantRuns = async (runId) => {
    try {
      let response;
      await request('/accountantChat/checkAssistantRun', { threadId: threadId, runId: runId }, (res) => {
        response = res.contour;
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getLastMessage = async (runId) => {
    try {
      let response;
      await request('/accountantChat/getLastMessage', { threadId: threadId, chatId: findCurrentChat._id, runId: runId }, (res) => {
        response = res.lastMessage;
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const sendMessage = async (message) => {
    await request('/accountantChat/sendMessage', { threadId, message: message, chatId: findCurrentChat._id, processWithAI }, () => {}, error);
  };

  const handleSend = async () => {
    if (inputValue === '') return;

    setInputValue('');

    setSendLoader(true);

    dispatch(addMessageAccountantChat({ _id: data._id, message: makeMyMessage(inputValue) }));

    if (!processWithAI) {
      await sendMessage(inputValue);
      setSendLoader(false);
      return;
    } else if (!threadId) {
      return;
    }

    await sendMessage(inputValue);
    let errorOccurred;
    let lastMessage;
    do {
      errorOccurred = false;
      try {
        const run = await runsAssistant();

        let status = await checkAssistantRuns(run);

        while (status === 'in_progress' || status === 'queued') {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          status = await checkAssistantRuns(run);
        }

        lastMessage = await getLastMessage(run);
      } catch (error) {
        errorOccurred = true;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } while (errorOccurred);
    cache.current.clearAll();
    setSendLoader(false);
    dispatch(addMessageAccountantChat({ _id: data._id, message: lastMessage }));
  };

  const chatContainerHeight = '400px'; // header height + padding, it's needed to optimaze
  const title = allAssistants.find((elem) => elem.index === findCurrentChat.assistantIndex)?.name;

  return (
    <Modal open title={title} onCancel={() => dispatch(setModal())} footer={false} className="chat-container">
      <div style={{ width: '100%', height: chatContainerHeight }}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowHeight={cache.current.rowHeight}
              deferredMeasurementCache={cache.current}
              rowCount={messages.length}
              scrollToIndex={messages.length - 1}
              overscanRowCount={0}
              rowRenderer={({ key, index, style, parent }) => {
                const message = { ...messages[index] };
                message.isSentByCurrentUser = message.fromUser;

                return (
                  <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                    <div style={style}>
                      <Message item={message} avatarUrl={!message.fromUser && avatar} />
                    </div>
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
      {sendLoader && processWithAI && (
        <div className="quick-chat-page-chat-container-list-response-status">
          <span>{translation.answerQuickQuestion[lang]}</span>
          <Pen className="quick-chat-page-chat-container-list-response-status-pen-icon" />
        </div>
      )}
      <div className="input-container no-left-btn">
        <InputNew
          placeholder={translation.inputQuickQuestion[lang]}
          value={inputValue}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          disabled={sendLoader}
        />
        <Button
          variant="default straight-left"
          style={{ margin: '0px', width: 32 }}
          onClick={handleSend}
          loading={sendLoader}
          icon={<SendOutlined />}
          disabled={sendLoader || (!threadId && processWithAI)}
        />
      </div>
    </Modal>
  );
};

export default ChatForContract;

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { PHONE_OFFICE_VIEW_CRM } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { ROUTES } from 'common_constants/routes';
import { warn } from '../../tools';

import './style.scss';

import UniversalItemButton from '../../components/UniversalItemButton';
import IconLetter from '../../images/icon_letter.svg';
import IconBall from '../../images/icon_ball.svg';
import IconText from '../../images/icon_text.svg';
import { useSelector } from 'react-redux';

const FirstLoginOfTheClient = () => {
  const history = useHistory();
  const lang = useSelector((state) => state.common.language);
  const contracts = useSelector((state) => state.common.contracts);
  const [open, setOpen] = useState(true);

  const redButton = !!contracts?.find((obj) => obj.rb === true && !obj.ad);

  const dynamicOnClick = (key) => {
    const actions = {
      PhoneClick: () => (window.location.href = PHONE_OFFICE_VIEW_CRM),
      CalendarClick: () => history.push(ROUTES.CLIENT_HOTLINE),
      ChatClick: () => history.push(ROUTES.CLIENT_QUICK_CHAT),
      TendersClick: () => history.push(ROUTES.CREATE_TENDER),
      RedBtnClick: () => history.push(ROUTES.RED_BUTTON),
    };

    const onStart = actions[key] || warn;

    setOpen(false);
    onStart();
  };

  useEffect(() => (open ? sessionStorage.setItem('modalFirst', 'true') : undefined), [open]);

  const modalRef = useRef(null);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const closeModal = (e) => {
    setOpen(false);
  };

  return (
    <div className={`overlay ${open ? 'animate' : 'close'}`} onClick={closeModal}>
      <div className={`first-login ${open ? 'animate' : 'first-login-close'}`} ref={modalRef} onClick={handleModalClick}>
        <div className="back"></div>
        <div className="positioning">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="-90 -5 130 50"
            // preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" y="0" x="0" fill="rgba(167,177,200,0.7" />
              <use xlinkHref="#gentle-wave" y="0" x="0" fill="rgba(167,177,200,0.8)" />
              <use xlinkHref="#gentle-wave" y="0" x="0" fill="rgba(167,177,200,0.9)" />
              <use xlinkHref="#gentle-wave" y="0" x="7" fill="rgb(188, 198, 221)" />
            </g>
          </svg>
        </div>
        <div className="first-login-title">{translation.startModal[lang]}</div>
        <div className="icon">
          <img src={IconLetter} alt="letter" className="letter" />
          <img src={IconText} alt="text" className="text" />
          <img src={IconBall} alt="ball" className="ball" />
          <img src="/logoold.svg" alt="logo" className="logo" />
        </div>
        <div className="first-login-content">
          <UniversalItemButton title={translation.startModal1[lang]} onClick={() => dynamicOnClick('PhoneClick')} />
          <UniversalItemButton title={translation.startModal2[lang]} onClick={() => dynamicOnClick('CalendarClick')} />
          <UniversalItemButton title={translation.startModal3[lang]} onClick={() => dynamicOnClick('ChatClick')} />
          <UniversalItemButton title={translation.startModal4[lang]} onClick={() => dynamicOnClick('TendersClick')} />
          {!redButton && <UniversalItemButton title={translation.buyAlarmButton[lang]} onClick={() => dynamicOnClick('RedBtnClick')} />}
        </div>
      </div>
    </div>
  );
};

export default FirstLoginOfTheClient;

import React, { useState } from 'react';
import QRCodeScanButton from './QRCodeScanButton';
import QRCodeScanModal from './QRCodeScanModal';

const QRCodeScannerContainer = ({ modalTitle, isScannerVisible, handleCloseScanner }) => {
  const [scannedResult, setScannedResult] = useState('');

  const handleScan = (data) => setScannedResult(data);

  return (
    <div>
      <QRCodeScanModal
        label={modalTitle ?? ''}
        visible={isScannerVisible}
        onClose={handleCloseScanner}
        onScan={handleScan}
        scannedResult={scannedResult}
      />
    </div>
  );
};

export default QRCodeScannerContainer;

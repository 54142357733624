import React from 'react';

import icon_web from 'common_constants/images/icon_web.svg';
import icon_universal from 'common_constants/images/icon_universal.svg';
import icon_doc from 'common_constants/images/icon_doc.svg';
import icon_docx from 'common_constants/images/icon_docx.svg';
import icon_pdf from 'common_constants/images/icon_pdf.svg';
import icon_rtf from 'common_constants/images/icon_rtf.svg';
import icon_odt from 'common_constants/images/icon_odt.svg';
import icon_txt from 'common_constants/images/icon_txt.svg';
import icon_xls from 'common_constants/images/icon_xls.svg';
import icon_xlsx from 'common_constants/images/icon_xlsx.svg';

const AvatarFile = ({ item }) => {
  const dynamic_avatar =
    {
      doc: icon_doc,
      docx: icon_docx,
      pdf: icon_pdf,
      rtf: icon_rtf,
      odt: icon_odt,
      web: icon_web,
      txt: icon_txt,
      xls: icon_xls,
      xlsx: icon_xlsx,
    }[item] || icon_universal;

  return (
    <img
      src={dynamic_avatar}
      alt="File icon"
      style={{
        width: '80px',
        height: '80px',
        display: 'block',
      }}
    />
  );
};

export default AvatarFile;

import React, { useState, useRef, useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from '../Button';
import './Popconfirm.scss';

const Popconfirm = (props) => {
  const [isPopconfirmVisible, setPopconfirmVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setPopconfirmVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleConfirm = () => {
    props.onConfirm();
  };

  const handleCancel = () => {
    setPopconfirmVisible(false);
  };

  return (
    <div className="common_popconfirm_container" ref={dropdownRef}>
      <Button style={{ fontSize: '22px', fontWeight: '700', padding: '0px 40px' }} variant={props.variant ?? 'default'} onClick={() => setPopconfirmVisible(true)}>
        {props.children ?? '+'}
      </Button>
      {isPopconfirmVisible && (
        <div className="popconfirm">
          <div className="icon-container">
            <InfoCircleOutlined style={{ color: 'orange', fontSize: '120%' }} />
          </div>
          <div className="items-container">
            <div className="text-container">
              <p style={{ fontWeight: 'bold' }}>{props.title}</p>
              <div className="description-container">{props.description}</div>
            </div>
            <div className="button-row">
              <Button style={{ padding: '0px 5px', margin: '0 4px' }} variant="light" onClick={handleCancel}>
                {props.cancelText}
              </Button>
              <Button style={{ padding: '0px 5px', margin: '0 4px' }} onClick={handleConfirm}>
                {props.okText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popconfirm;

import { request, success, error } from '../../tools';
import { CheckClientInfo } from '../../tools/hooks';
import { translation } from 'common_constants/translation';

const PayRedButtonDebt = (dispatch, setOpen, setOpenRedButtonModal, setRedButtonDisabled, lang) => {
  setRedButtonDisabled(true);
  const onSuccess = () => {
    success(translation.payRedButtonDebtSuccess[lang]);
    CheckClientInfo(dispatch);
    setOpenRedButtonModal(true);
    setRedButtonDisabled(false);
  };
  const onError = () => {
    error('', translation.payRedButtonDebtError[lang]);
    setOpen(true);
    setRedButtonDisabled(false);
  };
  request('/payment/payOffRedButtonDebt', {}, onSuccess, onError);
};

export default PayRedButtonDebt;

import { translation } from 'common_constants/translation';
import ModalWindow from '../../../Modals/ModalWindow';
import ChangeClientEmail from '../../../Modals/ChangeClientEmail';

const ChangeEmailModal = ({ lang, setIsLoading, setChangeEmailModal, changeEmailModal }) => {
  return (
    <ModalWindow title={translation.changingEmail[lang]} setModal={setChangeEmailModal} modal={changeEmailModal}>
      <ChangeClientEmail setLoading={setIsLoading} setModal={setChangeEmailModal} />
    </ModalWindow>
  );
};
export default ChangeEmailModal;

import { useSelector } from 'react-redux';
import { ROUTES } from 'common_constants/routes';

import ClientButton from '../../components/ClientButton';
import SectionName from '../../components/SectionName';
import { translation } from 'common_constants/translation';
import { MoneyCollectOutlined, AlertOutlined, BankOutlined } from '@ant-design/icons';

import './RegisterList.scss';

const RegisterList = () => {
  const lang = useSelector((state) => state.common.language);

  return (
    <div className="registerList">
      <SectionName text={translation.registerTitles.selectRegistry[lang]} />
      <ul className="links">
        <li>
          <ClientButton
            text={translation.registerTitles.alimonyDebtors[lang]}
            link={`${ROUTES.REGISTER_SEARCH}?type=alimony`}
            antIcon={<MoneyCollectOutlined style={{ fontSize: '48px', color: '#334768' }} />}
          />
        </li>
        <li>
          <ClientButton
            text={translation.registerTitles.registerOfCorruptioners[lang]}
            link={`${ROUTES.REGISTER_SEARCH}?type=corruptioner`}
            antIcon={<BankOutlined style={{ fontSize: '48px', color: '#334768' }} />}
          />
        </li>
        <li>
          <ClientButton
            text={translation.registerTitles.wantedPersons[lang]}
            link={`${ROUTES.REGISTER_SEARCH}?type=wanted`}
            antIcon={<AlertOutlined style={{ fontSize: '48px', color: '#334768' }} />}
          />
        </li>
      </ul>
    </div>
  );
};

export default RegisterList;

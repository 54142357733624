import { StatisticNumber } from './';
import './Statistic.scss';

const Statistic = ({ title, value, valueStyle, suffix, prefix, ...props }) => {
  return (
    <div className="statistic">
      <div className="statistic-title">{title}</div>
      <div style={valueStyle} className="statistic-content">
        {prefix && <span className={`statistic-content-prefix`}>{prefix}</span>}
        <StatisticNumber value={value} {...props} />
        {suffix && <span className={`statistic-content-suffix`}>{suffix}</span>}
      </div>
    </div>
  );
};
export default Statistic;

// Alert component, looks like antd Alert component
// diferent types render icons for them
import { useState } from 'react';
import clsx from 'clsx';
import { InfoCircleFilled, CheckCircleFilled, ExclamationCircleFilled, CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import './Alert.scss';

const Alert = ({ type, message, description }) => {
  const [closed, setClosed] = useState(false);
  // self closing method
  const onClose = () => {
    setClosed(true);
  };

  return (
    <div className={clsx('alert-component', { closed: closed }, type)}>
      <div className="alert-icon">
        {type === 'info' && <InfoCircleFilled />}
        {type === 'success' && <CheckCircleFilled />}
        {type === 'warning' && <ExclamationCircleFilled />}
        {type === 'error' && <CloseCircleFilled />}
      </div>
      <div className="alert-content">
        <div className="alert-message">{message}</div>
        <div className="alert-description">{description}</div>
      </div>
      <div className="alert-close" onClick={onClose}>
        <CloseOutlined />
      </div>
    </div>
  );
};

export default Alert;

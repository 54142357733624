import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import RHForm from '../../components/RHForm';
import { error, getMaxPhoneLength, getPhonePattern } from '../../tools';
import { MailOutlined } from '@ant-design/icons';
import { ZahistCred } from 'common_components';
import { CLIENT_TOKEN } from 'common_constants/business';
import { GET_IP_API } from 'common_constants/routes';
import { ADD_CLIENT_EMAIL } from 'common_constants/modals';
import { setClientAuth, setModal, setMissingEmail } from '../../store/commonReducer';
import { ROUTES } from 'common_constants/routes';
import Contacts from '../../components/Contacts/Contacts';
import { translation } from 'common_constants/translation';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';

import './ClientLogin.scss';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import Spin from './../../components/Spin';

const getTokenData = (token) => (token ? JSON.parse(window.atob(token.split('.')[1])) : null);

const ClientLogin = () => {
  const dispatch = useDispatch();
  const [activeClass, setActiveClass] = useState(false);
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.common.language);
  const [countryPhCode, setCountryPhCode] = useState(COUNTRY_PHONE_CODES.UA);

  const makeAppointment = localStorage.getItem('makeAppointment');
  const onFinish = (data) => {
    loginRequest({
      ph: data.login,
      countryPhCode: data.countryPhCode,
      p: data.password,
    });
  };

  const loginRequest = (data) => {
    setLoading(true);
    fetch(process.env.REACT_APP_API + '/auth/clientLogin', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(loginReqCallback);
  };

  const loginReqCallback = (res) => {
    if (!res.status) {
      res.noUser && error('', translation.clientLoginError[lang]);
      setLoading(false);
      return;
    }

    if (!res.accessToken) {
      setLoading(false);
      return;
    }
    const { email, emailVerified, phoneVerified, verificationSmsSendTime, policyConfirmed } = res;

    window.localStorage.setItem(CLIENT_TOKEN, res.accessToken);
    const { ph, _id, fil } = getTokenData(res.accessToken);
    dispatch(setClientAuth({ ph, _id, fil, email, emailVerified, token: res.accessToken, phoneVerified, verificationSmsSendTime, policyConfirmed }));

    if (!res.emailVerified && res.phoneVerified && res.policyConfirmed) {
      dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
      dispatch(setMissingEmail(true));
    }
    setLoading(false);

    fetch(GET_IP_API)
      .then((res) => res.json())
      .then(({ ip }) =>
        fetch(process.env.REACT_APP_API + '/clients/clientUpdateIp', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + res.accessToken },
          body: JSON.stringify({ ip }),
        }),
      );
  };

  return (
    <div className="clientLogin_page">
      <div className="auth_page default_page">
        <Spin tip={translation.loader[lang]} spinning={loading}>
          <div style={{ marginTop: 6 }}>
            <LanguageSwitcher />
          </div>
          {makeAppointment && <div className="makeAppointment">{translation.clientLoginRule[lang]}</div>}
          <div className="menu">
            <div className="menu-wrapper">
              <MailOutlined />
              <p className="title"> {translation.clientLoginTitle[lang]}</p>
            </div>
          </div>
          <RHForm onFinish={onFinish} countryPhCode={countryPhCode} setCountryPhCode={setCountryPhCode}>
            {[
              {
                name: 'phone',
                type: 'phone',
                rHFormOptions: {
                  required: translation.requiredField[lang],
                  maxLength: {
                    value: getMaxPhoneLength(countryPhCode),
                    message: translation.clientLoginPhoneMes[lang],
                  },
                  pattern: {
                    value: getPhonePattern(countryPhCode),
                    message: translation.clientLoginPhoneMes[lang],
                  },
                },
                options: { label: translation.phone[lang], type: 'number' },
              },
              {
                name: 'password',
                rHFormOptions: { required: translation.requiredField[lang] },
                options: { label: translation.password[lang], type: 'password' },
              },
              {
                name: 'submit',
                options: { value: translation.enter[lang] },
              },
            ]}
          </RHForm>
          <div className="questions-wrapper">
            <div className="question">
              {translation.clientLoginQuestion1[lang]} <Link to={ROUTES.CLIENT_REGISTRATION}>{translation.register[lang]}</Link>
            </div>
            <div className="question">
              {translation.clientLoginQuestion2[lang]} <Link to={ROUTES.CLIENT_CHANGE_PASSWORD}>{translation.clientLoginButton2[lang]}</Link>
            </div>
          </div>

          <ZahistCred />
        </Spin>
      </div>
      <Contacts setActiveClass={setActiveClass} activeClass={activeClass} />
    </div>
  );
};

export default ClientLogin;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import AlertNotificationCustom from '../AlertNotificationCustom';
import { CloseOutlined } from '@ant-design/icons';
import { MAC_OC_APP_SUGGESTION_LINK, WINDOWS_OC_APP_SUGGESTION_LINK } from 'common_constants/business';
import { translation } from 'common_constants/translation';

const platform = (navigator.userAgentData?.platform || navigator.platform)?.toLowerCase();

const AppSuggestionNotification = () => {
  const [closeAlertNotification, setCloseAlertNotification] = useState(false);
  const lang = useSelector((state) => state.common.language);

  const alertNotificationButtonLabel = translation.alertNotificationButtonLabel[lang];

  const alertNotificationTitle = translation.alertNotificationTitle[lang];

  const alertNotificationText = translation.alertNotificationText[lang];

  const alertNotificationIconClose = <CloseOutlined />;

  const alertNotificationImgAlt = 'logo';

  const isMac = platform.includes('ipod') || platform.includes('ipad') || platform.includes('iphone');

  const deviceToken = localStorage.getItem('deviceToken');

  const closeNotificationFunc = () => {
    localStorage.setItem('deviceToken', 'true');
    setCloseAlertNotification(true);
  };

  return (
    <>
      {!deviceToken && !window?.ReactNativeWebView && (
        <AlertNotificationCustom
          link={isMac ? MAC_OC_APP_SUGGESTION_LINK : WINDOWS_OC_APP_SUGGESTION_LINK}
          buttonLabel={alertNotificationButtonLabel}
          title={alertNotificationTitle}
          text={alertNotificationText}
          icon={alertNotificationIconClose}
          imgPath="/logo.svg"
          imgBg="#001529"
          imgAlt={alertNotificationImgAlt}
          closeNotificationFunc={closeNotificationFunc}
          closeNotification={closeAlertNotification}
        />
      )}
    </>
  );
};

export default AppSuggestionNotification;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import ModalWindow from '../ModalWindow';
import { request, error, success, warn } from '../../tools';
import BalanceNewButton from '../../components/Balance/BalanceNewButton';
import { translation } from 'common_constants/translation';
import './style.scss';

const FunctionsNotUsed = () => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.common.language);

  const [checkboxState, setCheckboxState] = useState({
    consultation: false,
    myConsultations: false,
    chats: false,
    quickQuestion: false,
    myCases: false,
    tenders: false,
    insuranceCard: false,
    balance: false,
    partnerCard: false,
    clubCard: false,
    reviews: false,
    topUpBalance: false,
    scanQRCode: false,
    cashback: false,
  });

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [checkboxName]: !prevCheckboxState[checkboxName],
    }));
  };
  const handleClick = () => {
    setLoading(true);
    const onSuccess = () => {
      success(translation.functionsNotUsedModalSuccess[lang]);
      setLoading(false);
      setOpen(false);
    };
    const onError = () => {
      error(translation.leaveSuggestionModalError[lang]);
      setLoading(false);
    };
    request('/functionsNotUsed/add', checkboxState, onSuccess, onError);
  };

  return (
    <ModalWindow modal={open} setModal={setOpen} title={translation.functionsNotUsedModalTitle[lang]}>
      <div className="functions-not-used-modal">
        <p className="text">
          {translation.functionsNotUsedModalText[lang]}
          <b> {translation.leaveSuggestionModalText2[lang]} </b>
        </p>
        <div className="list-checkbox">
          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.consultation} onChange={() => handleCheckboxChange('consultation')} />
            Запис на консультацію
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.myConsultations} onChange={() => handleCheckboxChange('myConsultations')} />
            Мої консультації
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.chats} onChange={() => handleCheckboxChange('chats')} />
            Чати
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.quickQuestion} onChange={() => handleCheckboxChange('quickQuestion')} />
            Швидке питання
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.myCases} onChange={() => handleCheckboxChange('myCases')} />
            Мої справи
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.tenders} onChange={() => handleCheckboxChange('tenders')} />
            Тендери
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.insuranceCard} onChange={() => handleCheckboxChange('insuranceCard')} />
            Страхова карта
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.balance} onChange={() => handleCheckboxChange('balance')} />
            Баланс
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.cashback} onChange={() => handleCheckboxChange('cashback')} />
            5% кешбек
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.partnerCard} onChange={() => handleCheckboxChange('partnerCard')} />
            Партнерська карта
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.clubCard} onChange={() => handleCheckboxChange('clubCard')} />
            Клубна карта
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.reviews} onChange={() => handleCheckboxChange('reviews')} />
            Відгуки
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.topUpBalance} onChange={() => handleCheckboxChange('topUpBalance')} />
            Поповнити баланс
          </label>

          <label className="checkbox-label">
            <input type="checkbox" checked={checkboxState.scanQRCode} onChange={() => handleCheckboxChange('scanQRCode')} />
            Сканувати QR-код
          </label>
        </div>
        <div className="buttons">
          <BalanceNewButton disabled={loading} onClick={handleClick} buttonText={translation.sendButton[lang]} arrow={false} />
        </div>
      </div>
    </ModalWindow>
  );
};

export default FunctionsNotUsed;

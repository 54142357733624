import * as React from 'react';
import MainLoader from '../MainLoader';
import Spin from '../Spin';

const StatisticNumber = (props) => {
  const { value, precision, loading, decimalSeparator = '.' } = props;

  let valueNode;

  // Internal formatter
  const val = String(value);
  const cells = val.match(/^(-?)(\d*)(\.(\d+))?$/);

  // Process if illegal number
  if (!cells || val === '-') {
    valueNode = val;
  } else {
    const negative = cells[1];
    let int = cells[2] || '0';
    let decimal = cells[4] || '';

    int = int.replace(/\B(?=(\d{3})+(?!\d))/g, '');

    if (typeof precision === 'number') {
      decimal = decimal.padEnd(precision, '0').slice(0, precision > 0 ? precision : 0);
    }

    if (decimal) {
      decimal = `${decimalSeparator}${decimal}`;
    }

    valueNode = [
      <span key="int" className={`statistic-content-value-int`}>
        {negative}
        {int}
      </span>,
      decimal && (
        <span key="decimal" className={`statistic-content-value-decimal`}>
          {decimal}
        </span>
      ),
    ];
  }

  return <span className={`statistic-content-value`}>{loading ? <Spin /> : valueNode}</span>;
};

export default StatisticNumber;

import clsx from 'clsx';
import { CURRENCIES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import dayjs from 'dayjs';

const PaymentManagmentInfo = ({ contract, totalPayByClient, currentCurrency, lang, isPaidStatus }) => {
  return (
    <div className="payment-management-info">
      <p>
        {translation.fullFee[lang]}: {contract?.pa ?? 0}
        {CURRENCIES[currentCurrency].symbol}
      </p>
      <p>
        {translation.firstPayment[lang]}:{' '}
        <span className={clsx(isPaidStatus.firstPayment ? 'paid' : 'not-paid')}>
          {contract?.fp ?? 0}
          {CURRENCIES[currentCurrency].symbol}
        </span>
      </p>
      <p>
        {translation.firstPaymentDate[lang]}: {contract?.md ? dayjs(contract.md)?.utc(true)?.format('DD.MM.YYYY') ?? '' : ''}
      </p>
      <p>
        {translation.exitsOfLawyer[lang]}: {contract?.ea ?? 0}
        {CURRENCIES[currentCurrency].symbol}
      </p>
      <p>
        {translation.totalPaid[lang]}: {totalPayByClient ?? 0}
        {CURRENCIES[currentCurrency].symbol}
      </p>
    </div>
  );
};

export default PaymentManagmentInfo;

import { useRef, useState } from 'react';
import SimpleRating from '../../SimpleRating/SimpleRating';
import './FeedbacksForm.scss';
import { request, error } from '../../../tools';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';


const FeedbacksForm = () => {
  const clientInfo = useSelector((state) => state.common.clientInfo);

  const [feedbackInfo, setFeedbackInfo] = useState({ 'simple-rating': '', email: '', message: '', firstname: '', clientUpa: clientInfo.upa });
  const [validationFailed, setValidationFailed] = useState({ 'simple-rating': '', email: '', message: '', firstname: '' });
 const lang = useSelector((state) => state.common.language);
  const form = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFeedbackInfo((prevData) => {
      return { ...prevData, [name]: value };
    });

    setValidationFailed((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newFormErrors = { ...validationFailed };

    if (feedbackInfo.firstname.trim().length === 0) {
      newFormErrors.firstname = translation.feedbacksFormInputName[lang];
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(feedbackInfo.email)) {
      newFormErrors.email = translation.feedbacksFormEmail[lang];
      isValid = false;
    }

    if (feedbackInfo['simple-rating'].length === 0) {
      newFormErrors['simple-rating'] = translation.feedbacksFormRating[lang];
      isValid = false;
    }

    if (feedbackInfo.message.trim().length === 0) {
      newFormErrors.message = translation.feedbacksFormFeedback[lang];
      isValid = false;
    }

    setValidationFailed(newFormErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const getInitialData = async () => {
        request('/clientFeedback/add', feedbackInfo, () => {}, error);
      };

      getInitialData();
      if (form.current) {
        form.current.reset();

        setFeedbackInfo({ 'simple-rating': '', email: '', message: '', firstname: '' });
        setValidationFailed({ 'simple-rating': '', email: '', message: '', firstname: '' });
      }
    }
  };

  return (
    <div className="">
      <div className="feedbacks-form">
        <h2 className="feedbacks-form-title">{translation.feedbacksFormTitle[lang]}</h2>
        <p className="feedbacks-form-text">
          {translation.feedbacksFormText[lang]}
        </p>
        <div className="form1" data-form-type="formoid">
          <form ref={form} className="" data-form-title="Mobirise Form" onSubmit={handleSubmit} noValidate>
            <div className="feedbacks-form-content">
              <div className="feedbacks-form-mark">{translation.feedbacksFormYourRating[lang]}</div>
              <SimpleRating setRating={handleInputChange} />
            </div>

            {validationFailed['simple-rating'] && <span className="feedbacks-form-validation-fail">{validationFailed['simple-rating']}</span>}
            <div className="feedbacks-form-item" data-for="phone">
              <label htmlFor="firstname" className="feedbacks-form-label">
                 {translation.feedbacksFormName[lang]}
              </label>
              <input
                id="firstname"
                type="text"
                className="feedbacks-form-input"
                name="firstname"
                data-form-field="First Name"
                value={feedbackInfo.firstname}
                onChange={handleInputChange}
              />

              {validationFailed.firstname && <span className="feedbacks-form-validation-fail">{validationFailed.firstname}</span>}
            </div>

            <div className="feedbacks-form-item" data-for="email">
              <label className="feedbacks-form-label" htmlFor="email">
                E-Mail
              </label>
              <input
                id="email"
                type="email"
                className="feedbacks-form-input"
                name="email"
                data-form-field="Email"
                value={feedbackInfo.email}
                onChange={handleInputChange}
              />

              {validationFailed.email && <span className="feedbacks-form-validation-fail">{validationFailed.email}</span>}
            </div>

            <div className="feedbacks-form-item" data-for="message">
              <label htmlFor="message" className="feedbacks-form-label">
                 {translation.feedbacksFormFeedback[lang]}
              </label>
              <textarea
                id="message"
                type="text"
                className="feedbacks-form-input"
                name="message"
                rows="2"
                data-form-field="Message"
                value={feedbackInfo.message}
                onChange={handleInputChange}
              ></textarea>

              {validationFailed.message && <span className="feedbacks-form-validation-fail">{validationFailed.message}</span>}
            </div>

            <span className="input-group-btn">
              <button type="submit" className="feedbacks-form-submit-btn">
                 {translation.sendButton[lang]}
              </button>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedbacksForm;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'rc-switch';

import { CONTRACT_AUTOPAY_CASHBACK } from 'common_constants/business';
import { setContracts } from '../../store/commonReducer';
import { ROUTES } from 'common_constants/routes';
import Empty from '../../images/empty-hotlines.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ContractItem from './ContractItem';
import { translation } from 'common_constants/translation';
import { request, error, success } from '../../tools';
import { Popconfirm, Spin } from '../../components';
import reactNativeService from '../../tools/reactNativeService';
import './ClientAutoPayment.scss';

const ClientAutoPayment = () => {
  const dispatch = useDispatch();
  const [lang, contracts, hotlines, clientInfo] = [
    useSelector((state) => state.common.language),
    useSelector((state) => state.common.contracts) ?? [],
    useSelector((state) => state.common.hotlines) ?? [],
    useSelector((state) => state.common.clientInfo) ?? [],
  ];
  const [autoPay, setAutoPay] = useState(false);
  const [loading, setLoading] = useState(false);

  const contractWithAutoPay = contracts.filter((contract) => !contract?.ad && (contract?.mg || contract?.monthlyFee));
  const contractshAutoPayOff = contractWithAutoPay.filter((contract) => !contract?.autoPay);
  const savedInMonth = contractWithAutoPay.reduce((total, contract) => total + parseInt(contract.monthlyFee ? contract.monthlyFee : contract.mg), 0);

  const additionalSavings = contractshAutoPayOff.reduce(
    (total, contract) => total + parseInt(contract.monthlyFee ? contract.monthlyFee : contract.mg),
    0,
  );
  const checkItemNotificationsInContracts = (_id, isThisTheLastContract) => {
    const contract = contracts.find((i) => i._id === _id);

    const [combinedNotifCorrespondence, combinedNotifTasks, combinedNotifEventsPartHotline, combinendNotifEventPartContract, combinedUnreadComments] =
      [
        (contract.correspondence || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true))?.length ?? 0,
        (contract.tasks || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true))?.length ?? 0,
        (hotlines?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0) +
          (hotlines?.filter((item) => item.s)?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0),
        (contract.assignments || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true))?.length ?? 0,
        contract?.tasks?.reduce((sum, item) => sum + (item?.comments?.reduce((acc, curr) => (curr.userNotSaw ? acc + 1 : acc), 0) ?? 0), 0),
      ];
    return (
      combinedNotifCorrespondence +
      combinedNotifTasks +
      (isThisTheLastContract && combinedNotifEventsPartHotline) +
      combinendNotifEventPartContract +
      combinedUnreadComments
    );
  };
  const handleSwitchAutoPay = async (autoPay) => {
    if (contractshAutoPayOff.length <= 0) return;
    setLoading(true);

    const onSuccess = async (id) => {
      success(translation.autoPayModalSuccess[lang]);
    };
    const onError = () => {
      error(translation.error[lang]);
      setLoading(false);
    };
    await contractshAutoPayOff.forEach((contract) => {
      request('/contracts/setAutoPay', { _id: contract._id, autoPay }, () => onSuccess(contract._id), onError);
    });
    dispatch(
      setContracts(contracts.map((item) => (contractshAutoPayOff.find((contract) => contract._id === item._id) ? { ...item, autoPay } : item))),
    );
    setLoading(false);
  };
  const handleOpenPayWindow = () => {
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location.href = res.checkoutUrl;
      }
    };
    const onError = (_, __, axiosError) => error('', 'axiosError.error');
    request('/payment/mono/bindCard/link', { redirectUrl: `/contractInfo/autopay` }, onSuccess, onError);
  };

  return (
    <section className="client-contract">
      <Spin spinning={loading}>
        <div className="client-contract__body">
          {contracts.length === 0 && (
            <div className="client-hotlines-empty">
              <h3>{translation.noConsultation[lang]}</h3>
              <img src={Empty} alt="Hotlines empty logo}" />
              <Link to={ROUTES.HOTLINE} className="client-hotlines-empty__btn">
                {translation.consultationButton[lang]}
                <div className="menu-item-text-arrow"></div>
              </Link>
            </div>
          )}
          {contractshAutoPayOff.length > 0 ? (
            clientInfo.paymentCard ? (
              <div className="autoPay-all-wrapper">
                <div className="autoPay-all">
                  <h2>{translation.autoPayModalText10_1[lang]}</h2>
                  <Switch className="Switch" checked={autoPay} onChange={handleSwitchAutoPay} style={{ marginLeft: 10 }} />
                </div>
                <p>{`${translation.autoPayModalText10_2[lang]} ${parseInt(additionalSavings * CONTRACT_AUTOPAY_CASHBACK)} ${
                  translation.currency[lang]
                }  ${translation.autoPayModalText10_3[lang]}`}</p>
              </div>
            ) : (
              <div className="all">
                <p className="autoPayModalText5">{translation.autoPayModalText5[lang]}</p>
                <Popconfirm
                  title={translation.popconfirmTitle[lang]}
                  description={
                    <div className="description">
                      <div>{translation.autoPayModalText6[lang]}</div>
                      <div>{translation.autoPayModalText7[lang]}</div>
                    </div>
                  }
                  onConfirm={handleOpenPayWindow}
                  okText={translation.yes[lang]}
                  cancelText={translation.no[lang]}
                >
                  <div className="cardForPayments_button">+</div>
                </Popconfirm>
                <br />
              </div>
            )
          ) : (
            <div className="everything-on-wrapper">
              {' '}
              <p className="everything-on-title"> {translation.autoPayModalText10_4[lang]} </p>
              <p className="everything-on-text">
                {' '}
                {`${translation.autoPayModalText10_5[lang]} ${parseInt(savedInMonth * CONTRACT_AUTOPAY_CASHBACK)} ${translation.currency[lang]} ${
                  translation.autoPayModalText10_3[lang]
                }`}
              </p>
            </div>
          )}
          {contractWithAutoPay.map((item, step, arr) => (
            <ContractItem
              key={item._id}
              data={item}
              contracts={contracts}
              badge={checkItemNotificationsInContracts(item._id, step === arr.length - 1)}
              clientInfo={clientInfo}
            />
          ))}
        </div>
      </Spin>
    </section>
  );
};

export default ClientAutoPayment;

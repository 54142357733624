import clsx from 'clsx';
import './Tag.scss';

const Tag = ({ children, color, className, ...rest }) => {
  return (
    <span className={clsx('tag', color, className)} {...rest}>
      {children}
    </span>
  );
};
export default Tag;

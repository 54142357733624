import React, { useState } from 'react';
import clsx from 'clsx';
import './Collapse.scss';
import { icon_arrow_down, icon_arrow_right } from '../../images';

const Collapse = ({ defaultActiveKey = 0, children, ...rest }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  const handlePanelClick = (panelKey) => {
    setActiveKey(panelKey === activeKey ? null : panelKey);
  };

  return (
    <div className="collapse-component" {...rest}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          const panelKey = child.props.panelKey || index;
          const isActive = panelKey === activeKey;
          return React.cloneElement(child, {
            isActive,
            onClick: () => handlePanelClick(panelKey),
            arrowIcon: isActive ? icon_arrow_down : icon_arrow_right,
          });
        }
        return null;
      })}
    </div>
  );
};

const Panel = ({ header, isActive, onClick, arrowIcon, children }) => {
  return (
    <div className={clsx('panel', { active: isActive })} onClick={onClick}>
      <div className="panel-header">
        <img src={arrowIcon} alt="arrow" className="arrow-icon" />
        {header}
      </div>
      {isActive && <div className="panel-content">{children}</div>}
    </div>
  );
};

Collapse.Panel = Panel;

export default Collapse;

import { useSelector } from 'react-redux';
import { ROUTES } from 'common_constants/routes';
import { Link } from 'react-router-dom';
import ClientTenderItem from '../ClientTenderItem/ClientTenderItem';
import Empty from '../../../images/empty-hotlines.svg';
import { translation } from 'common_constants/translation';

const ClientTendersList = () => {
  const tendersList = useSelector((state) => state.common.tenders) || [];
    const lang = useSelector((state) => state.common.language);

  return (
    <>
      {!tendersList.length ? (
        <div className="client-hotlines-empty">
          <h3>{translation.clientTendersListTotle[lang]}</h3>
          <img src={Empty} alt="Hotlines empty logo" />

          <Link to={ROUTES.CREATE_TENDER} className="client-hotlines-empty__btn" style={{ textAlign: 'center' }}>
            {translation.createTender[lang]}
            <div className="menu-item-text-arrow"></div>
          </Link>
        </div>
      ) : (
        <div className="tenders-page">
          <div>
            <Link
              to={ROUTES.CREATE_TENDER}
              className="client-hotlines-empty__btn"
              style={{ textAlign: 'center', display: 'block', margin: '20px auto' }}
            >
               {translation.createTender[lang]}
              <div className="menu-item-text-arrow"></div>
            </Link>
          </div>
          {tendersList.map((item) => (
            <ClientTenderItem key={item._id} data={item} />
          ))}
        </div>
      )}
    </>
  );
};

export default ClientTendersList;

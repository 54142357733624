import { translation } from 'common_constants/translation';
import ModalWindow from '../../../Modals/ModalWindow';
import ConfirmChangePhone from '../../ConfirmChangePhone';

const ChangePhoneModal = ({ lang, setIsLoading, setChangePhoneModal, changePhoneModal }) => {
  return (
    <ModalWindow title={translation.changingPhone[lang]} setModal={setChangePhoneModal} modal={changePhoneModal}>
      <ConfirmChangePhone setLoading={setIsLoading} setModal={setChangePhoneModal} />
    </ModalWindow>
  );
};
export default ChangePhoneModal;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import BalanceNew from '../../components/Balance/BalanceNew';
import TransactionsButton from '../ClientHome/TransactionsButton/TransactionsButton';
import Transactions from '../ClientHome/Transactions/Transactions';
import ModalWindow from '../../Modals/ModalWindow/ModalWindow';

import './ClientHome.scss';

const AnonimBonusHome = () => {
  const lang = useSelector((state) => state.common.language);
  const [openBonusesTransactions, setOpenBonusesTransactions] = useState(false);
  const [openTransactions, setOpenTransactions] = useState(false);

  return (
    <div className="clientHome_page">
      <div className="client-bonuses-content">
        <BalanceNew balance={0} balanceName={`${translation.clientHomeBalanceNew[lang]} ₴${Math.abs(1000)}`} />
        <br />
        <br />

        <TransactionsButton bonusesTransactions={[]} buttonName={translation.clientHomeTransactionsTitle1[lang]} modal={setOpenBonusesTransactions} />
        <ModalWindow title={translation.clientHomeTransactionsTitle1[lang]} modal={openBonusesTransactions} setModal={setOpenBonusesTransactions}>
          <Transactions bonusesTransactions={[]} />
        </ModalWindow>

        <TransactionsButton transactions={[]} buttonName={translation.clientHomeTransactionsTitle2[lang]} modal={setOpenTransactions} />
        <ModalWindow title={translation.clientHomeTransactionsTitle2[lang]} modal={openTransactions} setModal={setOpenTransactions}>
          <Transactions transactions={[]} sugarTransactions={[]} />
        </ModalWindow>
      </div>
    </div>
  );
};

export default AnonimBonusHome;

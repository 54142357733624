const { AUTOPAY_INFO_LIST } = require('../modals');

const CLIENT_DOMAIN = 'client.zahist.ua';
const CLIENT_LINK = `https://${CLIENT_DOMAIN}`;

const APP_DOMAIN = 'app.zahist.ua';
const APP_LINK = `https://${APP_DOMAIN}`;

const WEB_SITE_DOMAIN = 'zahist.ua';
const WEB_SITE_LINK = `https://${WEB_SITE_DOMAIN}`;

const OLD_WEB_SITE_DOMAIN = 'zahist.in.ua';
const OLD_WEB_SITE_LINK = `https://${OLD_WEB_SITE_DOMAIN}`;

const APP_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.zahist.client';
const APP_LINK_IOS = 'https://apps.apple.com/us/app/єАдвокат/id6463437697';

const GET_IP_API = 'https://api64.ipify.org/?format=json';
const GEO_API = 'http://www.geoplugin.net/json.gp';

const ROUTES = {
  HOME: '/',
  AUTH: '/auth',
  HOME_DASHBOARD: '/homeDashboard',
  PROFILE: '/profile',
  HOTLINE: '/hotline',
  PSYCHOLOGIST_HOTLINE: '/PsychologistHotline',
  LIST_PSYCHOLOGIST_HOTLINE: '/ListPsychologistHotline',
  HOTLINE_SAVED: '/hotlineSaved',
  HOTLINE_ID: '/hotline/:_id',
  HOTLINE_REACH: '/hotlineReach',
  FAILURE_TO_PAY: '/failureToPay',
  LIST: '/list',
  HACKWORKS: '/hackworks',
  // GDUN: '/gdun',
  // GDUN_STAT: '/gdunStat',
  SEARCH: '/search',
  PERSONNEL_DIRECTORY: '/personnelDirectory',
  CLUB_CARDS: '/clubСards',
  CLIENTS: '/clients',
  PARTNERS: '/partners',
  CLIENT_INFO: '/clientInfo/:id',
  CLIENT_INFO_: '/clientInfo/',
  CONTRACTS: '/contracts',
  AUTOPAY_PAGE: '/autopay',
  CONTRACTS_INSURANCE: '/contractsInsurance',
  HELPER: '/helper',
  HELPER_CALC: '/helperCalc',
  JOURNAL: '/journal',
  HOTLINES_BY_CLIENTS: '/hotlinesByClients',
  HOTLINE_UPDATE: '/hotlineUpdate',
  HARVEST: '/harvest',
  ASSIGNMENT: '/assignment',
  ACTIVITY: '/activity',
  LIBRARY: '/library',
  CORRESPONDENCE_PAGE: '/correspondencePage',
  BLOCKED: '/blocked',
  RATING: '/rating',
  PRAVO_RATING: '/pravoRating',
  FILII: '/filii',
  FEE: '/fee',
  CHAT: '/chat',
  CLIENTS_FEEDBACK: '/clientsFeedback',
  COMPLAINTS: '/complaints',
  FUNCTION_SURVEY: '/functionSurvey',
  EXPENSE: '/expenses',
  TASKS: '/tasks',
  SUGAR: '/sugar',
  PUSH_NOTIFICATION: '/pushNotification',
  TENDERS: '/tenders',
  INSURANCES: '/insurances',
  ACCESS_LIST: '/accessList',
  SUGGESTIONS: '/suggestions',
  CONFLICTS: '/conflicts',
  CONFLICT_CHAT: '/conflicts/:id',
  TWITTER: '/twitter',
  TWITTER_ID: '/twitter/:id',
  RED_BUTTON_CHAT: '/redButtonChat',
  DOCUMENTS: '/documents',
  WEBINARS: '/webinars',
  WEBINAR_ID: '/webinar/:id',
  CLIENTS_QUICK_CHATS: '/clientsQuickChats',
  CLIENTS_DOZHIMS_AI_CHATS: '/clientsDozhimsAIChats',
  REGISTER: '/register',
  REGISTER_LIST: '/registerList',
  REGISTER_SEARCH: '/registerSearch',
  REGISTER_SELF_SEARCH: '/registerSelfSearch',
  DOZHIMS_ANK: '/dozhimsAnk',

  //admin part
  USERS: '/users',
  USER_ADD: '/userAdd',
  USER_EDIT_ID: '/userEdit/:_id',
  CABINETS: '/cabinets',
  SENDER: '/sender',
  BANK_CARDS: '/bankCards',
  STATISTIC: '/statistic',
  STATISTIC_MEET_CANCELATION_REASONS: '/statistic/meetCancelationReasons',
  STATISTIC_CLIENTS_DEMOGRAPHY: '/statistic/clientsDemography',
  STATISTIC_FIL_ACTIVE_CLIENTS_WITH_APP: '/statistic/filActiveClientsWithApp',
  STATISTIC_FIL_ACTIVE_CONTRACTS: '/statistic/filActiveContracts',
  STATISTIC_FIL_ARCHIVE_CANDY: '/statistic/filArchiveCandy',
  WEBSITE_NEWS: '/websiteNews',
  PUBLISH_WEBSITE: '/publishWebsite',
  DEVELOPMENT: '/development',
  MFO: '/mfo',
  LATENESS: '/lateness',
  WALLET: '/wallet',
  WALLET_MONTH: '/walletMonth',
  WALLET_MONTH_FILIALS: '/walletMonthFilials',
  PAYMENT_MANAGEMENT_FILL_CHART: '/statistic/paymentManagementFillChart',
  FONDY_HOTLINES: '/fondyHotlines',
  INSURANCE_TYPE: '/insuranceType',
  IT_SERVICES: '/itServices',
  DUBLICATES: '/dublicates',
  HOTLINE_CALLS: '/hotlineCalls',

  //public client part
  CLIENT_LOGIN: '/clientLogin',
  CLIENT_REGISTRATION: '/clientReg',
  CLIENT_CHANGE_PASSWORD: '/clientChangePassword',
  CLIENT_CONFIRM_NEW_PASSWORD: '/clientConfirmNewPassword/:code/:email',
  CLIENT_VERIFICATION_PHONE: '/clientVerificationPhone',
  CLIENT_POLICY_CONFIRM: '/PolicyConfirm',
  ANONIM_BONUS_HOME: '/bonus3000',
  ANONIM_CERTIFICATE_OLD_: '/certificate/',
  ANONIM_CERTIFICATE_: '/cert/',
  HOTLINE_MEET_INFO: '/:hash',
  PAYMENT_SUCCESS: '/paymentSuccess',
  PAYMENT_FOR_HOTLINE: '/pfh/:hotlineIndex',
  LAWYER_CV: '/cv/:id',
  LAWYER_CV_HEADER_TEXT: '/cv/',
  FILII_STATE: '/filiiState/:state',
  CLUB_CARD_PAGE: '/clubCardPage',

  //protected client part

  CLIENT_HOME: '/homepage',
  CLUB_CARD: '/clubCard',
  CLIENT_MENU: '/menu',
  CLIENT_INSURANCE: '/insuranceAccount',
  CLIENT_HOTLINE: '/hotline',
  CLIENT_MY_HOTLINES: '/myHotlines',
  PARTNER_ACCOUNT: '/partnerAccount',
  CLIENT_CONTRACTS: '/contracts',
  CLIENT_CONTRACTS_NEW: '/contracts2',
  CLIENT_CONTRACT_INFO: '/contractInfo/:id',
  CLIENT_CONTRACT_INFO_HEADER_TEXT: '/contractInfo/',
  CLIENT_PROFILE: '/profile',
  CLIENT_TENDERS: '/clientTenders',
  CREATE_TENDER: '/createTender',
  CLIENT_TENDER_INFO: '/clientTenderInfo/:id',
  CLIENT_TENDER_INFO_HEADER_TEXT: '/clientTenderInfo/',
  CLIENT_QUICK_CHAT: '/clientQuickChat',
  CLIENT_CONTACT_US: '/clientContactUs',
  CLIENT_BONUS: '/bonusAccount',
  RED_BUTTON: '/redButton',
  CLIENT_CHATS: '/clientChats',
};

const OPEN_ROUTES = [
  ROUTES.AUTH,
  ROUTES.HOTLINE_MEET_INFO,
  ROUTES.ANONIM_BONUS_HOME,
  ROUTES.CLIENT_LOGIN,
  ROUTES.ANONIM_CERTIFICATE_,
  ROUTES.ANONIM_CERTIFICATE_OLD_,
  ROUTES.CLIENT_REGISTRATION,
  ROUTES.CLIENT_CHANGE_PASSWORD,
  ROUTES.CLIENT_CONFIRM_NEW_PASSWORD,
  ROUTES.PAYMENT_SUCCESS,
  ROUTES.PAYMENT_FOR_HOTLINE,
];

const ADMIN_ROUTES = [ROUTES.FILII, ROUTES.COMPLAINTS];

module.exports = {
  APP_DOMAIN,
  APP_LINK,
  CLIENT_DOMAIN,
  CLIENT_LINK,
  WEB_SITE_DOMAIN,
  WEB_SITE_LINK,
  OLD_WEB_SITE_DOMAIN,
  OLD_WEB_SITE_LINK,
  ROUTES,
  OPEN_ROUTES,
  ADMIN_ROUTES,
  APP_LINK_ANDROID,
  APP_LINK_IOS,
  GET_IP_API,
  GEO_API,
};

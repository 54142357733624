// import curph from '../curator.png'
import redButtonimg from '../../../images/redbutton.png';

const CuratorImage = ({ user, onClick, redButton }) => {
  const imageName = user?.upa;

  const unknownURL = 'https://cdn4.iconfinder.com/data/icons/political-elections/50/48-512.png';

  const userAvatarURL = imageName ? process.env.REACT_APP_API + `/avatars/${imageName}.jpeg` : null;

  const src = redButton ? redButtonimg : user?.upa ? userAvatarURL : unknownURL;

  return (
    <div className="curator__photo" onClick={onClick} style={onClick ? { cursor: 'pointer' } : {}}>
      <img src={src} style={src === redButtonimg ? {marginLeft: "-50px"}: {}} alt="" />
    </div>
  );
};

export default CuratorImage;

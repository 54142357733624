import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ModalWindow from '../ModalWindow/';
import { translation } from 'common_constants/translation';
import { Spin } from '../../components';

import SendEmail from './SendEmail';
import ConfirmEmail from './ConfirmEmail';
import ConfirmChangePhone from '../../components/ConfirmChangePhone';
import PolicyConfirm from '../PolicyConfirm';

const AddClientEmail = () => {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);
  const { _id, token, email, emailVerified, phoneVerified, policyConfirmed } = clientAuth;
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const step = !emailVerified ? 1 : !phoneVerified ? 2 : 3;
  if (pathname.match(/^\/.{24}$/)) return; // * блок модалок в направлені

  return (
    <ModalWindow title={`${translation.regStep1[lang]} ${step}/3`} setModal={setOpen} modal={open}>
      <Spin tip={translation.loader[lang]} spinning={loading} renderWrapped>
        {emailVerified ? (
          !phoneVerified && <ConfirmChangePhone setLoading={setLoading} setModal={setOpen} />
        ) : email && !emailVerified ? (
          <ConfirmEmail token={token} email={email} clientId={_id} setLoading={setLoading} />
        ) : (
          <SendEmail token={token} setLoading={setLoading} />
        )}
        {!policyConfirmed && emailVerified && phoneVerified ? <PolicyConfirm setModal={setOpen} /> : null}
      </Spin>
    </ModalWindow>
  );
};

export default AddClientEmail;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QUESTIONNAIRE_ARCHIVE } from 'common_constants/modals';
import { translation } from 'common_constants/translation';

import { Button, EditableContractName, Badge, UserAvatar } from '../../../components';
import { setModal, setContract } from '../../../store/commonReducer';

const { Ribbon } = Badge;

const ContractItem = ({ data, badge }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const users = useSelector((state) => state.common.usersData);
  const [isEditing, setIsEditing] = useState(false);

  const curator = users?.find((user) => user._id === data?.us) || null;

  const onClickLinkHandle = (e) => {
    if (isEditing) {
      e.preventDefault();
    }
  };

  return (
    <Link to={!isEditing ? `/contractInfo/${data._id}` : undefined} onClick={onClickLinkHandle}>
      <Ribbon color="#334768" text="Архівний" style={{ display: !data.ad && 'none' }}>
        <Badge className="menu-item-badge" count={badge} offset={!data.ad ? [0, 0] : [-70, -10]}>
          <div className="col">
            <div className="client-contract__item">
              <div className="contract-curator-photo">
                <UserAvatar
                  style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  user={curator}
                />
              </div>
              <div className="contract-info">
                <EditableContractName contract={data} onClick={(e) => e.preventDefault()} setIsEditing={setIsEditing} />
                <div className="contract-info__curator">
                  {translation.CuratorTitle[lang]}
                  <span className="curator-purple">{curator?.name}</span>
                </div>
              </div>
              <div className="menu-item-text-arrow"></div>
            </div>

            {data.sa === true && data.qa === undefined && (
              <div className="btn">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setModal({ name: QUESTIONNAIRE_ARCHIVE, data: { C: data._id } }));
                    dispatch(setContract(data));
                  }}
                >
                  {translation.qualityArchiveBtn[lang]}
                </Button>
              </div>
            )}
          </div>
        </Badge>
      </Ribbon>
    </Link>
  );
};

export default ContractItem;

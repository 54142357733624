import { useLocation } from 'react-router-dom';

const PaymentForHotline = () => {
  const currentLocation = useLocation();
  const args = currentLocation.pathname.split('/');
  const hotlineIndex = args[2];

  const link = `https://app.zahist.ua:3005/payment/payForHotline/${hotlineIndex}`;

  window.location.href = link;

  return null;
};

export default PaymentForHotline;

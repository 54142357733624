import React from 'react';
import Alert from '../../../../components/Alert/Alert';
import { translation } from 'common_constants/translation';

export default function FilInfo({ fil, filii, lang = 'ua' }) {
  const showData = filii.find((item) => item.i === +fil);

  return (
    <Alert
      message={translation.branchInfo[lang]}
      description={
        <>
          <b>{translation.address[lang]}</b> {showData?.address ?? '-'} <br />
          <b>{translation.zone[lang]}</b> {showData?.zone ?? '-'} <br />
          <b>{translation.landmark[lang]}</b> {showData?.address_description ?? '-'}
        </>
      }
      type="info"
    />
  );
}

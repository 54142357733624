import React, { useRef } from 'react';
import './style.scss';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

const ModalWindow = ({ modal, setModal, children, title, className }) => {
  const modalRef = useRef(null);
  const lang = useSelector((state) => state.common.language);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const closeModal = (e) => {
    setModal(false);
  };

  return (
    <div className={`overlay ${modal ? 'animate' : 'close'} ${className ?? ''}`} onClick={closeModal}>
      <div className={`modal ${modal ? 'animate' : 'modal-close'}`} ref={modalRef} onClick={handleModalClick}>
        {title && <div className="modal-title">{title}</div>}
        {children ? <div className="modal-content">{children}</div> : <div className="modal-content-nodata">{translation.modalWindow[lang]}</div>}
      </div>
    </div>
  );
};

export default ModalWindow;

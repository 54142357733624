import './ClientProfile.scss';
import CardForPayments from '../../components/CardForPayments/CardForPayments';

import { EditClientProfileForm } from '../../components/Forms/EditClientProfileForm';

const ClientProfile = () => {
  return (
    <div className="clientProfile_page">
      <EditClientProfileForm />

      <CardForPayments />
    </div>
  );
};

export default ClientProfile;

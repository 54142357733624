import { useSelector } from 'react-redux';
import ClientTendersList from './ClientTendersList';
import { translation } from 'common_constants/translation';

import './ClientTenders.scss';

const ClientTenders = () => {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);
  const { emailVerified, phoneVerified } = clientAuth;
  
  return (
    <div className="tenders-page">
      {emailVerified && phoneVerified ? (
        <ClientTendersList />
      ) : (
        <h1 style={{ textAlign: 'center' }}>{translation.clientTendersTitle[lang]}</h1>
      )}
    </div>
  );
};

export default ClientTenders;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { useDispatch } from 'react-redux';
import { editClientInfo } from '../../store/commonReducer';
import BalanceNew from '../../components/Balance/BalanceNew';
import ClientButton from '../../components/ClientButton/ClientButton';
import TransactionsButton from './TransactionsButton/TransactionsButton';
import RulesBonuses from './RulesBonuses';
import RulesBalance from './RulesBalance';
import Transactions from './Transactions/Transactions';
import ModalWindow from '../../Modals/ModalWindow/ModalWindow';
import ClientBalancePayModal from '../../components/ClientMenu/ClientBalancePayModal';
import DetailsBalance from './DetailsBalance/DetailsBalance';
import ClubCard from '../../components/ClubCard';

import CARDBACK from '../../images/club-card-back.jpg';
import CARDFRONT from '../../images/club-card-front.jpg';
import bonusesImg from './images/bonusesImg.svg';
import rulesImg from './images/rulesImg.svg';
import CardForPayments from '../../components/CardForPayments/CardForPayments';
import { CURRENCIES } from 'common_constants/business';

import './ClientHome.scss';

const ClientHome = () => {
  const dispatch = useDispatch();

  const clientAuth = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);
  const contracts = useSelector((state) => state.common.contracts);
  const { _id } = clientAuth;
  const [rulesBonusesState, setRulesBonusesState] = useState(false);
  const [rulesBalanceState, setRulesBalanceState] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [open, setOpen] = useState(false);
  const [openBonusesTransactions, setOpenBonusesTransactions] = useState(false);
  const [openTransactions, setOpenTransactions] = useState(false);

  const clientInfo = useSelector((state) => state.common.clientInfo);
  const { clientBalance } = clientInfo || 0;
  const BalanceTransactions = [...(clientInfo?.transactions || [])].reverse();

  const currentCurrency = clientInfo?.currency ?? CURRENCIES.UAH;

  const getClientTransactions = () => {
    if (!clientAuth) return;

    fetch(process.env.REACT_APP_API + '/clientsTransactions/getAllForClient', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + clientAuth.token },
      body: JSON.stringify({ C: _id }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) return;

        setTransactions(
          res.data.reverse().map((i) => ({
            ...i,
            balanceStr: i.b.toFixed(2),
          })),
        );
      });
  };

  const markAsCashbackReceived = (transactionI) => {
    dispatch(
      editClientInfo({
        ...clientInfo,
        transactions: clientInfo.transactions.map((transaction) => {
          if (transaction.i === transactionI) {
            return { ...transaction, isCashbackReceived: true };
          }
          return transaction;
        }),
      }),
    );
  };

  useEffect(() => {
    getClientTransactions();
  }, []);

  return (
    <div className="clientHome_page">
      {/* <div className="homepage-club-card">
        <ClubCard front={CARDFRONT} back={CARDBACK} />
      </div> */}
      <div className="client-bonuses-content">
        <BalanceNew
          balance={clientBalance}
          modal={() => setOpen(true)}
          balanceName={`${translation.clientHomeBalanceNew[lang]} ${
            clientInfo.B < 0 ? `-${CURRENCIES[currentCurrency].symbol}` : CURRENCIES[currentCurrency].symbol
          }${Math.abs(clientInfo.B)}`}
        />
        {/* <CardForPayments paymentModal={() => setOpen(true)} /> */}
        {/* <div className="client-menu">
          <ClientButton text={'Правила бонусів'} icon={bonusesImg} modal={setRulesBonusesState} />
          <ClientButton text={'Правила балансу'} icon={rulesImg} modal={setRulesBalanceState} />
        </div> */}
        <br />
        <br />
        <RulesBonuses open={rulesBonusesState} setRulesBonusesModal={setRulesBonusesState} />
        <RulesBalance open={rulesBalanceState} setRulesBalanceState={setRulesBalanceState} />
        <ModalWindow title={translation.clientHomeTransactionsTitle1[lang]} modal={openBonusesTransactions} setModal={setOpenBonusesTransactions}>
          <Transactions bonusesTransactions={transactions.filter((transaction) => transaction.b !== 0)} />
        </ModalWindow>
        <ModalWindow title={translation.clientHomeTransactionsTitle2[lang]} modal={openTransactions} setModal={setOpenTransactions}>
          <Transactions
            markAsCashbackReceived={markAsCashbackReceived}
            refetch={getClientTransactions}
            transactions={BalanceTransactions.filter((transaction) => transaction.amount !== 0)}
          />
        </ModalWindow>
        <TransactionsButton
          transactions={BalanceTransactions.filter((transaction) => transaction.amount > 0)}
          // buttonName={translation.clientHomeTransactionsTitle2[lang]}
          modal={setOpenTransactions}
        />
        <TransactionsButton
          bonusesTransactions={transactions.filter((transaction) => transaction?.b)}
          // buttonName={translation.clientHomeTransactionsTitle1[lang]}
          modal={setOpenBonusesTransactions}
        />
        <ClientBalancePayModal
          arrayPayment={[250, 500, 1000, 1500, 2500, 5000]}
          open={open}
          setOpen={setOpen}
          title={translation.replenishmentBalance[lang]}
          placeholder={translation.clientBalancePayModalPlaceholder[lang]}
          buttonText={translation.clientBalancePayModalButtonText[lang]}
        />
        {contracts.length > 0 && (
          <div className="DetailsBalance">
            {contracts.map((contract) => (
              <DetailsBalance contract={contract} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientHome;

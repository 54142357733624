import { useDispatch, useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import Modal from '../../components/Modal';
import Row from '../../components/Row';
import { Button } from '../../components';
import { setModal } from '../../store/commonReducer';

const Notification = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.common.modal);
  const lang = useSelector((state) => state.common.language);

  const onClose = () => {
    dispatch(setModal());
  };

  return (
    <Modal title={translation.notificationTitle[lang]} open footer={false} onCancel={onClose}>
      <Row>{translation.notificationText[lang]}</Row>
      <Row justify={'center'} style={{ margin: '20px 0' }}>
        <strong>{data?.email}</strong>
      </Row>
      <Row justify={'center'}>
        <Button style={{width: 'auto', padding: '0 10px'}} onClick={onClose}>
          {translation.closeBtn[lang]}
        </Button>
      </Row>
    </Modal>
  );
};

export default Notification;

import { useState, useEffect } from 'react';
import SelectType from './Partials/SelectType';
import SelectState from './Partials/SelectState';
import SelectFil from './Partials/SelectFil';
import { checkBlokedTimes, getBookedTimeForClient, onTimeClickForClient } from 'common_components/src/HotlineStep1/Step1/timeUtils';
import Alert from '../../components/Alert/Alert';
import { translation } from 'common_constants/translation';
import FilInfo from './Partials/Alerts/FilInfo';
import { HotlineDatePicker, HotlineTimePicker, ClientHotlineSubmitBtn } from 'common_components';
import dayjs from 'dayjs';
import { Column, Row } from '../../components';
import { WORK_TIME } from 'common_constants/business';
import AppointmentForToday from './Partials/Alerts/AppointmentForToday';
import { request } from '../../tools';

export default function Step1Client({
  data,
  filii,
  clientInfo,
  activeFilii,
  token,
  host,
  setLoading,
  setClientHotlineInfo,
  setData,
  setIsHotlineAdded,
  lang,
  topFilials,
}) {
  const [filteredState, setFilteredState] = useState([]);
  const [filteredDistrict, setFilteredDistrict] = useState([]);
  const [filteredPartOfDistrict, setFilteredPartOfDistrict] = useState([]);
  const [restArr, setRestArr] = useState([]);
  const [blockedArr, setBlockedArr] = useState([]);
  const [appointmentForToday, setAppointmentForToday] = useState(false);
  const [clientHotlinesPerWeek, setClientHotlinesPerWeek] = useState([]);

  const startT = data.startT;
  const endT = data.endT;

  const freeTime = checkBlokedTimes(blockedArr, startT, endT);

  const showNext = data.fil && data.type && startT && freeTime && (endT ? WORK_TIME.indexOf(startT) <= WORK_TIME.indexOf(endT) : true);

  const setStartT = (d) => setData((prev) => ({ ...prev, startT: d }));
  const setEndT = (d) => setData((prev) => ({ ...prev, endT: d }));

  const currentTopFilialsIndexes = topFilials?.find((filial) => filial?.pravo == (data.pravo ?? 0))?.filials;

  const currentTopFilials = currentTopFilialsIndexes
    ?.map((filialIndex) => {
      const findedFilial = activeFilii.find((filial) => String(filial.value) === filialIndex);
      return findedFilial;
    })
    .filter((elem) => !!elem);

  const activeFilialsWithoutTops = activeFilii?.filter((activeFil) => {
    const res = currentTopFilials?.includes(String(activeFil.value));
    return !res;
  });
  console.log(data, currentTopFilials);

  const getCurrentFilForAlert = () => filii.find((fil) => String(fil.i) === String(appointmentForToday.fil)).address || 'Не знайдена';

  const handleSortDataByState = (value) => {
    const selectedState = parseInt(value);
    const filteredByState = (filii || []).filter((filii) => {
      return filii.states.includes(selectedState) && filii.isDistrict !== true && filii.isDisabled !== true;
    });

    const filteredByDistrict = (filii || []).filter((filii) => {
      return filii.states.includes(selectedState) && filii.isDistrict === true && filii.isDisabled !== true;
    });

    const district = [...new Set(filteredByState.map((item) => item.district))];

    if (filteredByDistrict.length > 0) {
      filteredByDistrict.forEach((item) => {
        district.push(item.district);
      });
    }

    const filteredPartOfDistrict = (filii || []).filter((filii) => {
      return district.includes(filii.district) && filii.isDisabled !== true;
    });

    const filteredPartOfDistrictWithoutFilteredByState = filteredPartOfDistrict.filter((filii) => {
      return !filteredByState.some((item) => item._id === filii._id) && !filteredByDistrict.some((item) => item._id === filii._id);
    });

    const restArray = (filii || []).filter((filii) => {
      return (
        !filteredByState.some((item) => item._id === filii._id) &&
        !filteredByDistrict.some((item) => item._id === filii._id) &&
        !filteredPartOfDistrictWithoutFilteredByState.some((item) => item._id === filii._id) &&
        filii.isDisabled !== true
      );
    });

    setData((prev) => ({ ...prev, state: selectedState }));
    setFilteredState(filteredByState);
    setFilteredDistrict(filteredByDistrict);
    setFilteredPartOfDistrict(filteredPartOfDistrictWithoutFilteredByState);
    setRestArr(restArray);
  };

  useEffect(() => {
    if (data.fil || data.type) {
      getBookedTimeForClient(data.fil, data.type, data.date ?? dayjs(new Date()), setLoading, setBlockedArr, token, host);
    }
  }, [data.fil, data.type, data.date]);

  useEffect(() => {
    request(
      '/hotline/getClientHotlinesPerWeek',
      { targetDate: data.date ?? dayjs(new Date()), fil: data.fil },
      ({ status, clientHotlinesPerWeek, appointmentForToday }) => {
        if (status) {
          setClientHotlinesPerWeek(clientHotlinesPerWeek);
          setAppointmentForToday(appointmentForToday);
        }
      },
    );
  }, [data.date, data.fil]);

  const handleTimeClick = (e) => {
    onTimeClickForClient(setEndT, setStartT, e.target, data.type);
  };

  useEffect(() => {
    setData({ ...data, startT, endT });
  }, [startT, endT]);

  const handleClickClearTime = () => {
    setStartT('');
    setEndT('');
  };

  const onDateSelect = (value) => {
    setData({ ...data, date: value });
    handleClickClearTime();
  };

  return (
    <div className="step1">
      <SelectType type={data.type} onChange={(v) => setData((prev) => ({ ...prev, type: v }))} />
      <Row>
        <SelectState state={data.state} onChange={handleSortDataByState} lang={lang} />
        <SelectFil
          date={data.date ?? dayjs(new Date())}
          token={token}
          host={host}
          filii={filii}
          fil={data.fil}
          onChangeFil={(v) => setData((prev) => ({ ...prev, fil: v }))}
          activeFilii={data.type === '0' ? activeFilialsWithoutTops : activeFilii}
          state={data.state}
          currentTopFilials={currentTopFilials}
          filteredState={filteredState}
          filteredDistrict={filteredDistrict}
          filteredPartOfDistrict={filteredPartOfDistrict}
          restArr={restArr}
          lang={lang}
          type={data.type}
        />
      </Row>
      <Column style={{ marginTop: 12 }}>
        {!data.fil && <Alert message={translation.consultaionSelectBranch[lang]} type="info" showIcon />}
        {!!data.fil && <FilInfo fil={data.fil} filii={filii} lang={lang} />}
        <HotlineDatePicker onDateSelect={onDateSelect} fil={data.fil} isForCRM={false} date={data?.date} />
        <AppointmentForToday appointmentForToday={appointmentForToday} data={data} getCurrentFilForAlert={getCurrentFilForAlert} lang={lang} />
      </Column>
      {clientHotlinesPerWeek >= 3 && <Alert message={translation.clientHotline.hotlineLimitExceed[lang]} type="warning" showIcon />}
      {!!data.fil && !!data.type && (
        <HotlineTimePicker
          startT={startT}
          endT={endT}
          blockedArr={blockedArr}
          onTimeClick={handleTimeClick}
          onTimeClear={handleClickClearTime}
          isForCRM={false}
          appointmentForToday={appointmentForToday}
          clientHotlinesPerWeek={clientHotlinesPerWeek}
          date={data.date ?? dayjs(new Date())}
          lang={lang}
        />
      )}
      {data.fil && !appointmentForToday && clientHotlinesPerWeek < 3 && (
        <ClientHotlineSubmitBtn
          showNext={showNext}
          host={host}
          token={token}
          fil={data.fil}
          filii={filii}
          type={1}
          date={data.date ?? dayjs(new Date())}
          startT={startT}
          endT={endT}
          name={clientInfo.n}
          countryPhCode={clientInfo.countryPhCode}
          phone={clientInfo.ph}
          setLoading={setLoading}
          setIsHotlineAdded={setIsHotlineAdded}
          setClientHotlineInfo={setClientHotlineInfo}
          lang={lang}
        />
      )}
    </div>
  );
}

import ModalWindow from '../ModalWindow';
import { useDispatch, useSelector } from 'react-redux';
import { setContracts, setModal } from '../../store/commonReducer';
import { PAYMENT_FRAME } from 'common_constants/modals';
import { Popconfirm } from '../../components';
import { CONTRACT_AUTOPAY_CASHBACK } from 'common_constants/business';

import { translation } from 'common_constants/translation';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';
import reactNativeService from '../../tools/reactNativeService';
import { request, error, success } from '../../tools';
import './AutoPayModal.scss';

const AutoPayModal = ({ contracts, contract, autoPay, setAutoPay, paymentCard }) => {
  const lang = useSelector((state) => state.common.language);
  const dispatch = useDispatch();
  const handleSwitchAutoPay = (autoPay) => {
    const onSuccess = () => {
      success(translation.autoPayModalSuccess[lang]);
      dispatch(setContracts(contracts.map((item) => (item._id === contract._id ? { ...item, autoPay } : item))));
    };
    const onError = () => {
      error(translation.error[lang]);
    };
    request('/contracts/setAutoPay', { _id: contract._id, autoPay }, onSuccess, onError);
  };

  const handleOpenPayWindow = () => {
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location.href = res.checkoutUrl;
      }
    };
    const onError = (_, __, axiosError) => error('', 'axiosError.error');
    request('/payment/mono/bindCard/link', { redirectUrl: `/contractInfo/${contract._id}` }, onSuccess, onError);
  };

  return (
    <ModalWindow title={translation.autoPayModalTitle[lang]} modal={autoPay} setModal={setAutoPay} className="autoPay_Modal">
      <div>
        <div style={{ marginBottom: 10 }}>
          {contract?.mg || contract?.monthlyFee ? (
            <div>
              {translation.autoPayModalText1[lang]} <b>{contract?.monthlyFee ? contract?.monthlyFee : contract?.mg}₴</b>
            </div>
          ) : (
            <div>{translation.autoPayModalText2[lang]}</div>
          )}
          <br />
          <i style={{ fontSize: 12 }}>{translation.autoPayModalText3[lang]}</i>
        </div>
        {paymentCard ? (
          (contract?.mg || contract?.monthlyFee) && (
            <div style={{ marginBottom: 10 }}>
              {translation.autoPayModalText4[lang]}
              <Switch checked={contract?.autoPay} onChange={handleSwitchAutoPay} style={{ marginLeft: 10 }} />
            </div>
          )
        ) : (
          <div>
            {translation.autoPayModalText5[lang]}
            <br />
            <br />
            <Popconfirm
              title={translation.popconfirmTitle[lang]}
              description={
                <div>
                  <div>{translation.autoPayModalText6[lang]}</div>
                  <div>{translation.autoPayModalText7[lang]}</div>
                </div>
              }
              onConfirm={handleOpenPayWindow}
              okText={translation.yes[lang]}
              cancelText={translation.no[lang]}
            >
              <div className="cardForPayments_button">+</div>
            </Popconfirm>
            <br />
          </div>
        )}
        <div>
          {translation.autoPayModalText8[lang]}
          <b>
            {translation.autoPayModalText8_1[lang]} {CONTRACT_AUTOPAY_CASHBACK * 100}%
          </b>
          {translation.autoPayModalText8_2[lang]}
          <br />
          <br />
        </div>
        <i style={{ fontSize: 12 }}>{translation.autoPayModalText9[lang]}</i>
      </div>
    </ModalWindow>
  );
};

export default AutoPayModal;

import { PRAVO, TENDER_TERMS } from 'common_constants/business';
import { Link } from 'react-router-dom';
import './ClientTenderItem.scss';
import dayjs from 'dayjs';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';
// import 'dayjs/locale/uk';
// dayjs.locale('uk');

const ClientTenderItem = ({ data }) => {
  const { createdAt, problem, sum, _id } = data;
  const lang = useSelector((state) => state.common.language);

  return (
    <Link to={`/clientTenderInfo/${_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <div className="tender-item">
        <div>{problem}</div>
        <div className="tender-item__info">
          <div>
            <b>{translation.date[lang]}</b> {dayjs(createdAt).format('DD.MM.YYYY')}
          </div>
          <div>
            <b>{translation.sum[lang]}</b> {sum}₴
          </div>
        </div>
        <div className="menu-item-text-arrow"></div>
      </div>
    </Link>
  );
};

export default ClientTenderItem;

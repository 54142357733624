import { translation } from 'common_constants/translation';

export function renderList(list, titles, lang = 'ua') {
  const listToRender = list?.map((item) => (
    <li key={`${item._id}${String(Math.random())}`} className="item">
      {generateRows(item, titles, lang)}
    </li>
  ));
  if (!listToRender?.length) {
    return <div style={{ textAlign: 'center' }}>{translation.registerTitles.nothingFound[lang]}</div>;
  }
  return listToRender;
}

function generateRows(item, titles, lang) {
  const titlesArr = Object.keys(titles);
  const rows = titlesArr.map((title) => {
    const titleName = titles[title][lang];
    const value = item[title];
    return (
      <div key={`${item._id}${String(Math.random())}`} className="row">
        <span>{titleName}</span>
        <span>{value || '-'}</span>
      </div>
    );
  });
  return rows;
}

import React from 'react';
import QRCode from 'qrcode.react';
import './QRCode.scss';

const CustomQRCode = ({ value, size, icon, iconSize }) => {
  return (
    <div style={{ position: 'relative', width: size, height: size }}>
      <QRCode value={value} size={size} />

      {icon && (
        <img
          src={icon}
          alt="Icon"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            borderRadius: '8px',
            width: iconSize,
            height: iconSize,
          }}
        />
      )}
    </div>
  );
};

export default CustomQRCode;

import clsx from 'clsx';

const Col = ({ children, className, style, justify }) => (
  <div
    className={clsx('col', className)}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: justify,
      ...style,
    }}
  >
    {children}
  </div>
);

export default Col;

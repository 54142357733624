import React from 'react';
import clsx from 'clsx';
import './Card.scss';

const Card = ({ title, children, className }) => {
  return (
    <div className={clsx('card', className)}>
      {title && <div className="card-title">{title}</div>}
      <div className="card-content">{children}</div>
    </div>
  );
};

export default Card;

import React from 'react';
import './Radio.scss';

const Radio = ({ id, name, value, onChange, style, isActive, children }) => {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <label htmlFor={id} className={`radiobutton-label ${isActive ? 'active' : ''}`} style={style}>
      <input className="radiobutton-input" type="radio" name={name} id={id} value={value} onChange={handleChange} />
      <span className="custom-radiobutton"></span>
      {children}
    </label>
  );
};

export default Radio;

import React from 'react';
import { useSelector } from 'react-redux';

import { ROUTES } from 'common_constants/routes';
import Empty from '../../images/empty-hotlines.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ContractItem from './ContractItem';
import { translation } from 'common_constants/translation';
import './ClientContracts.scss';

const ClientContracts = () => {
  const [lang, contracts, hotlines] = [
    useSelector((state) => state.common.language),
    useSelector((state) => state.common.contracts) ?? [],
    useSelector((state) => state.common.hotlines) ?? [],
  ];

  const checkItemNotificationsInContracts = (_id, isThisTheLastContract) => {
    const contract = contracts.find((i) => i._id === _id);

    const [combinedNotifCorrespondence, combinedNotifTasks, combinedNotifEventsPartHotline, combinendNotifEventPartContract, combinedUnreadComments] =
      [
        (contract.correspondence || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true))?.length ?? 0,
        (contract.tasks || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true))?.length ?? 0,
        (hotlines?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0) +
          (hotlines?.filter((item) => item.s)?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0),
        (contract.assignments || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true))?.length ?? 0,
        contract?.tasks?.reduce((sum, item) => sum + (item?.comments?.reduce((acc, curr) => (curr.userNotSaw ? acc + 1 : acc), 0) ?? 0), 0),
      ];
    return (
      combinedNotifCorrespondence +
      combinedNotifTasks +
      (isThisTheLastContract && combinedNotifEventsPartHotline) +
      combinendNotifEventPartContract +
      combinedUnreadComments
    );
  };

  return (
    <section className="client-contract">
      <div className="client-contract__body">
        {contracts.length === 0 && (
          <div className="client-hotlines-empty">
            <h3>{translation.noConsultation[lang]}</h3>
            <img src={Empty} alt="Hotlines empty logo}" />
            <Link to={ROUTES.HOTLINE} className="client-hotlines-empty__btn">
              {translation.consultationButton[lang]}
              <div className="menu-item-text-arrow"></div>
            </Link>
          </div>
        )}
        {contracts.map((item, step, arr) => (
          <ContractItem key={item._id} data={item} badge={checkItemNotificationsInContracts(item._id, step === arr.length - 1)} />
        ))}
      </div>
    </section>
  );
};

export default ClientContracts;

import React from 'react';
import ModalWindow from '../../Modals/ModalWindow/ModalWindow';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

const RulesBalance = ({ open, setRulesBalanceState }) => {
  const lang = useSelector((state) => state.common.language);
  return (
    <ModalWindow title={translation.rulesBalanceTitle[lang]} modal={open} setModal={setRulesBalanceState}>
      <ol>
        <li>
          <strong>{translation.rulesBalanceText1[lang]}</strong>
          <ul>
            <li>
              <strong>{translation.rulesBalanceText2[lang]}</strong> {translation.rulesBalanceText3[lang]}
            </li>
            <li>
              <strong>{translation.rulesBalanceText4[lang]}</strong> {translation.rulesBalanceText5[lang]}
            </li>
            <li>
              <strong>{translation.rulesBalanceText6[lang]}</strong> {translation.rulesBalanceText7[lang]}
            </li>
            <li>
              <strong>{translation.rulesBalanceText8[lang]}</strong> {translation.rulesBalanceText9[lang]}
            </li>
          </ul>
        </li>
        <li>
          <strong>{translation.rulesBalanceText10[lang]}</strong>
          <ul>
            <li>В{translation.rulesBalanceText11[lang]}</li>
            <li>{translation.rulesBalanceText12[lang]}</li>
          </ul>
        </li>
      </ol>
    </ModalWindow>
  );
};

export default RulesBalance;

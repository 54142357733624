import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useHistory } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { WEBINAR_STATUSES, WEBINAR_OWN_STATUSES } from 'common_constants/business';
import { BUY_WEBINAR } from 'common_constants/modals';
import { translation } from 'common_constants/translation';
import { setModal } from '../../../../store/commonReducer';
import Tag from '../../../../components/Tag/Tag';
import Button from '../../../../components/Button/Button';

import './WebinarItem.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Kiev');

export const WebinarItem = ({ item }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const history = useHistory();

  const currency = translation.currencyUah[lang];

  const handleOpenBuyWebinarModal = () => {
    dispatch(
      setModal({
        name: BUY_WEBINAR,
        data: { ...item },
      }),
    );
  };

  return (
    <div className="webinar-item">
      <img src="/webinars-zahist.jpg" alt="" />

      {item && (
        <>
          <div className="webinar-item-wrapper">
            <div className="title">{item.name}</div>
            <div className="price">
              {translation.webinarPrice[lang]} {item.price} {currency}
            </div>
            <div className="status">
              {item.status === WEBINAR_STATUSES.STARTED && (
                <Tag key={item?._id} color="green">
                  {translation.webinarStatuses[WEBINAR_STATUSES.STARTED][lang]}
                </Tag>
              )}

              {item?.status === WEBINAR_STATUSES.PLANNED && (
                <div>
                  <span>
                    {translation.webinarDate[lang]}{' '}
                    {dayjs(item.startDateWithTime).tz().format('DD.MM') + ' - ' + dayjs(item.startDateWithTime).tz().format('HH:mm')}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="actions">
            {item?.ownStatus === WEBINAR_OWN_STATUSES.NOT_PAYED && (
              <div>
                <Button className="webinar-btn" type="primary" onClick={handleOpenBuyWebinarModal}>
                  {translation.buyWebinar[lang]}
                </Button>
              </div>
            )}

            {!(item.ownStatus === WEBINAR_OWN_STATUSES.PAYED && item.status === WEBINAR_STATUSES.STARTED) && (
              <Button className="webinar-btn" type="primary" onClick={() => history.push(`/webinar/${item._id}`)}>
                {translation.webinarTeaser[lang]}
                <ArrowRightOutlined />
              </Button>
            )}

            {item.ownStatus === WEBINAR_OWN_STATUSES.PAYED && item.status === WEBINAR_STATUSES.STARTED && (
              <Button className="webinar-btn" type="primary" onClick={() => history.push(`/webinar/${item._id}`)}>
                {translation.openStream[lang]}
                <ArrowRightOutlined />
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { SelectNew } from '../../../components';
import { UKRAINE_STATES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

export default function SelectState({ state, onChange }) {
  const { control } = useForm();
  const options = Object.keys(UKRAINE_STATES).map((key) => ({ value: String(key), label: UKRAINE_STATES[key] }));
  const lang = useSelector((state) => state.common.language);

  return (
    <SelectNew
      placeholder={translation.clientHotline.selectHotlineState[lang]}
      requried
      name="state"
      options={options}
      value={state}
      onChange={onChange}
      control={control}
      className="ant-select state"
      isSearchable={true}
    />
  );
}

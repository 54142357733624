import React, { useEffect, useState } from 'react';
import { request } from '../../tools';
import Spin from '../../components/Spin/Spin';
import NewInsurancePresentation from './NewInsurancePresentation';
import InsuranceList from './InsuranceList';

import './styles.scss';

const Insurances = () => {
  const [loading, setLoading] = useState(true);
  const [insurances, setInsurances] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([]);

  const getInsurances = () => {
    request('/insurance/getInsurancesForClient', {}, ({ insurances }) => {
      setInsurances(insurances);
    });
  };

  const getAllInsurances = () => {
    request.get('/insuranceType/getAllInsuranceTypesClient', ({ data }) => {
      setInsuranceTypes(data);
    });
  };

  // todo: replace with lookup
  useEffect(() => {
    setLoading(true);
    Promise.all([getInsurances(), getAllInsurances()]).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="insurances_page">
      <Spin spinning={loading} fill={true}>
        {insurances.length === 0 ? (
          <NewInsurancePresentation getInsurances={getInsurances} insuranceTypes={insuranceTypes} />
        ) : (
          <InsuranceList insurances={insurances} getInsurances={getInsurances} insuranceTypes={insuranceTypes} />
        )}
      </Spin>
    </div>
  );
};

export default Insurances;

import React from 'react';
import clsx from 'clsx';
import './CuratorButton.scss';

const CuratorButton = ({ text, icon, modal, bg, phoneNumber, type, disabled }) => {
  const handlePhoneClick = () => (window.location.href = `tel:${phoneNumber}`);
  const dynamicBackground = { danger: '#683353' }[bg] || '#334768';

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={modal || handlePhoneClick}
      className={clsx('contract-btn', { disabled: disabled })}
      style={{ backgroundColor: dynamicBackground }}
    >
      {icon && (
        <div className="contract-icon-wrapper">
          <img src={icon} alt="logo btn" />
        </div>
      )}
      <div>{text}</div>
    </button>
  );
};

export default CuratorButton;

import { createSlice } from '@reduxjs/toolkit';
import { CLIENT_TOKEN } from 'common_constants/business';
import { getTokenData } from '../tools';

const clientToken = window.localStorage.getItem(CLIENT_TOKEN);
const clientAuth = getTokenData(clientToken);

const initialState = {
  modal: { name: '', data: {} },
  clientInfo: {},
  clientAuth: clientAuth && { ...clientAuth, token: clientToken },
  appPrepared: false,
  missingEmail: null,
  courts: null,
  places: null,
  contracts: null,
  newsFromSite: null,
  hotlines: null,
  hotlineMeetData: null,
  historyHotlines: null,
  tenders: null,
  contract: null,
  quickChatQuestions: -1,
  quickChatLoader: true,
  language: 'ua',
  filii: null,
  FILII: null,
  activeFilii: null,
  pravoRating: null,
  usersData: [],
  socket: null,
  contractChats: [],
  biometricEnabled: null,
  webinars: [],
  accountantChats: [],
};

const [groupHotlinesMain, groupHotlineMeetData, groupHistoryHotlines] = [
  {
    setHotlines: (state, action) => {
      state.hotlines = action.payload;
    },
    editHotline: (state, action) => {
      if (state.hotlines !== null) {
        const hotlineIndex = state.hotlines.findIndex((i) => i._id === action.payload._id);
        state.hotlines[hotlineIndex] = { ...state.hotlines[hotlineIndex], ...action.payload };
      }
    },
  },
  {
    setHotlineMeetData: (state, action) => {
      state.hotlineMeetData = action.payload;
    },
  },
  {
    setHistoryHotlines: (state, action) => {
      state.historyHotlines = action.payload;
    },
    editHistoryHotline: (state, action) => {
      if (state.historyHotlines !== null) {
        const hotlineIndex = state.historyHotlines.findIndex((i) => i._id === action.payload._id);
        state.historyHotlines[hotlineIndex] = { ...state.historyHotlines[hotlineIndex], ...action.payload };
      }
    },
  },
],
  groupHotlinesAll = { ...groupHotlinesMain, ...groupHotlineMeetData, ...groupHistoryHotlines };

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    ...groupHotlinesAll,
    setClientAuth: (state, action) => {
      state.clientAuth = action.payload;
    },
    setAppPrepared: (state, action) => {
      state.appPrepared = action.payload;
    },
    setFiliiList: (state, action) => {
      state.filii = action.payload;
      state.FILII = action.payload.reduce((acc, item) => {
        acc[item.i] = item.name;
        return acc;
      }, []);
      state.activeFilii = action.payload
        .filter((item) => !item.isDisabled)
        .map((item) => ({ _id: item._id, label: item.name, value: item.i }))
        .sort((a, b) => a.value - b.value);
    },
    setClientEmailVerified: (state, action) => {
      state.clientAuth.email = action.payload.email;
      state.clientAuth.emailVerified = action.payload.emailVerified;
    },
    setClientPhoneVerified: (state, action) => {
      return {
        ...state,
        clientAuth: {
          ...state.clientAuth,
          ph: action.payload.ph ? action.payload.ph : state.clientAuth.ph,
          verificationSmsSendTime: action.payload.verificationSmsSendTime,
          phoneVerified: action.payload.phoneVerified,
        },
      };
    },
    setClientPolicyConfirmed: (state, action) => {
      state.clientAuth.policyConfirmed = action.payload.policyConfirmed;
    },
    setClientInfo: (state, action) => {
      const {accountantChats, ...rest} = action.payload
      state.clientInfo = rest;
      state.accountantChats = accountantChats;
      state.contracts = rest.contracts;
      state.hotlines = rest.hotlines;
      state.tenders = rest.tenders;
    },
    setClientBalance: (state, action) => {
      state.clientInfo.clientBalance = action.payload;
    },
    editClientInfo: (state, action) => {
      state.clientInfo = { ...state.clientInfo, ...action.payload };
    },
    setModal: (state, action) => {
      state.modal = {
        ...action.payload,
        prev: action.payload?.prev || (state.modal.name ? state.modal : undefined),
      };
    },
    setMissingEmail: (state, action) => {
      state.missingEmail = action.payload;
    },
    setCourts: (state, action) => {
      state.courts = action.payload;
    },
    setPlaces: (state, action) => {
      state.places = action.payload;
    },
    setContracts: (state, action) => {
      state.contracts = action.payload;
    },
    setContract: (state, action) => {
      state.contract = action.payload;
    },
    editContracts: (state, action) => {
      const contractIndex = state.contracts.findIndex((i) => i._id === action.payload._id);
      state.contracts[contractIndex] = { ...state.contracts[contractIndex], ...action.payload };
    },
    setTopFilials: (state, action) => {
      state.topFilials = action.payload;
    },
    setNewsFromSite: (state, action) => {
      state.newsFromSite = action.payload;
    },
    setWebinars: (state, action) => {
      state.webinars = action.payload;
    },
    setTenders: (state, action) => {
      state.tenders = action.payload;
    },
    setQuickChatQuestions: (state, action) => {
      state.quickChatQuestions = action.payload;
    },
    setQuickChatLoader: (state, action) => {
      state.quickChatLoader = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setPravoRating: (state, action) => {
      state.pravoRating = action.payload;
    },
    setUsersData: (state, action) => {
      state.usersData = action.payload;
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setContractChat: (state, action) => {
      const { chatId, data } = action.payload;
      const chatIndex = state.contractChats?.findIndex((item) => item.chatId === chatId);
      const newChatItem = {
        chatId: chatId,
        messages: data,
      };

      if (chatIndex < 0) {
        state.contractChats = [...state.contractChats, newChatItem];
        return;
      }

      state.contractChats = state.contractChats.map((item) => (item.chatId === chatId ? newChatItem : item));
    },
    addNewMessageToContractChat: (state, action) => {
      const { chatId, data } = action.payload;
      state.contractChats = state.contractChats?.map((item) => (item.chatId === chatId ? { ...item, messages: [...item.messages, data] } : item));
    },
    deleteContractChatMessage: (state, action) => {
      const { chatId, messageId } = action.payload;
      state.contractChats = state.contractChats?.map((item) =>
        item.chatId === chatId ? { ...item, messages: item?.messages?.filter((message) => message._id !== messageId) } : item,
      );
    },
    editContractMessage: (state, action) => {
      const { chatId, messageId, newMessageText } = action.payload;
      state.contractChats = state.contractChats?.map((item) =>
        item.chatId === chatId
          ? { ...item, messages: item?.messages?.map((message) => (message._id === messageId ? { ...message, message: newMessageText } : message)) }
          : item,
      );
    },
    setBiometricEnabled: (state, action) => {
      state.biometricEnabled = action.payload;
    },
    setAccountantChats: (state, action) => {
      state.accountantChats = action.payload;
    },
    addMessageAccountantChat: (state, { payload }) => {
      const index = state.accountantChats.findIndex((elem) => elem._id === payload._id);
      if (index !== -1) {
        state.accountantChats[index].chat?.push(payload.message);
      }
    },
    setThreadIdAccountantChat: (state, { payload }) => {
      const index = state.accountantChats.findIndex((elem) => elem._id === payload._id);
      if (index !== -1) {
        state.accountantChats[index].threadId = payload.threadId;
      }
    },
  },
});

export const {
  setClientAuth,
  setAppPrepared,
  setFiliiList,
  setClientEmailVerified,
  setClientInfo,
  editClientInfo,
  setModal,
  setMissingEmail,
  setCourts,
  setPlaces,
  setContracts,
  setContract,
  editContracts,
  setHotlines,
  editHotline,
  setHotlineMeetData,
  setHistoryHotlines,
  editHistoryHotline,
  setTopFilials,
  setNewsFromSite,
  setWebinars,
  setClientPhoneVerified,
  setTenders,
  setClientPolicyConfirmed,
  setQuickChatQuestions,
  setQuickChatLoader,
  setLanguage,
  setPravoRating,
  setUsersData,
  setSocket,
  setContractChat,
  addNewMessageToContractChat,
  deleteContractChatMessage,
  editContractMessage,
  setBiometricEnabled,
  setAccountantChats,
  addMessageAccountantChat,
  setThreadIdAccountantChat,
  setClientBalance,
} = commonSlice.actions;

export default commonSlice.reducer;

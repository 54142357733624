import React, { useState, useEffect, useRef } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './Dropdown.scss';

const Dropdown = (props) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const menu = props.menu;
  const [notif, setNotif] = useState(menu.items);
  const dropdownRef = useRef(null);
  const unwatchedNotif = notif ? notif.filter((item) => item.label.props.className !== 'watched') : [];
  const [lastFiveNotifElements, setLastFiveNotifElements] = useState(getFirstFiveElements(notif));

  useEffect(() => {
    setNotif(menu.items);
    setLastFiveNotifElements(getFirstFiveElements(menu.items));
  }, [menu.items]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  function getFirstFiveElements(arr) {
    return arr.slice(0, 5);
  }

  function updateNotif(itemKey) {
    setLastFiveNotifElements((prevElements) =>
      prevElements.map((item) => {
        if (item.key === itemKey) {
          return {
            ...item,
            label: React.cloneElement(item.label, {
              className: 'watched',
            }),
          };
        }
        return item;
      }),
    );
    const updatedUnwatchedNotif = unwatchedNotif.filter((item) => item.key !== itemKey);
    props.menu.items = updatedUnwatchedNotif;
    setNotif(menu.items);
  }

  return (
    <div className="notif notification-drop" ref={dropdownRef}>
      <div className="item" onClick={() => setOpenDropdown(!openDropdown)}>
        <span className="notification-bell" role="img" aria-label="Bell" style={{}}>
          <BellOutlined />
        </span>
        {unwatchedNotif.length > 0 && (
          <div className="btn__badge pulse-button ">
            <span>{unwatchedNotif.length}</span>
          </div>
        )}
        {openDropdown && lastFiveNotifElements.length > 0 && (
          <div className="notif">
            {lastFiveNotifElements.map((item) => (
              <div
                onClick={() => {
                  updateNotif(item.key);
                  props.removeNotificationClick();
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;

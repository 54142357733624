import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMainLoader } from '../../store/uiReducer';

import SectionName from '../../components/SectionName';
import { translation } from 'common_constants/translation';
import { REGISTER_PRICE } from 'common_constants/business';
import { request, error } from '../../tools';
import { useLocation } from 'react-router-dom';

import { renderList } from '../../tools/registerHepler';
import { setClientBalance } from '../../store/commonReducer';
import { ClientBalancePayModal } from '../../components';

import './RegisterSearch.scss';

const RegisterSearch = () => {
  const lang = useSelector((state) => state.common.language);
  const dispatch = useDispatch();
  const { clientBalance } = useSelector((state) => state.common.clientInfo);
  const [showPayModal, setShowPayModal] = useState(false);

  const [searchField, setSearchField] = useState('');
  const [list, setList] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  const handleClickSearch = (event) => {
    event.preventDefault();
    if (!searchField) {
      error(translation.error[lang], translation.registerError.enterValue[lang]);
      return;
    }
    if (clientBalance < REGISTER_PRICE) {
      error(translation.registerError.notEnoughFunds[lang], translation.registerError.topUpYourBalance[lang]);
      setShowPayModal(true);
      return;
    }
    dispatch(setMainLoader(true));
    request(
      `/register/${type}/search`,
      {
        searchQuery: searchField,
      },
      (res) => {
        setList(res.data.data);
        dispatch(setMainLoader(false));
        setShowResults(true);
        dispatch(setClientBalance(clientBalance - REGISTER_PRICE));
      },
      (_, __, axiosError) => {
        error(translation.error[lang], axiosError?.error);
        dispatch(setMainLoader(false));
      },
    );
  };

  return (
    <div className="registerSearch">
      <SectionName text={translation.registerTitles.subTitle[type][lang]} />
      <div className="titleInner">
        <span className="title">{translation.registerTitles.search[type][lang]}</span>
      </div>
      <div className="inputInner">
        <input onChange={(event) => setSearchField(event.target.value)} className="input" type="text" />
      </div>
      <div className="info">
        <span>
          {translation.balance[lang]}: {clientBalance} {translation.currencyUah[lang]}
        </span>
        <span>
          {translation.registerTitles.priceForTheService[lang]}: {REGISTER_PRICE} {translation.currencyUah[lang]}
        </span>
      </div>
      <div className="buttonInner">
        <input onClick={handleClickSearch} className="button" type="button" value={translation.registerTitles.searchBtn[lang]} />
      </div>
      {showResults ? (
        <div className="results">
          <h3 className="subtitle">{translation.registerTitles.searchResults[lang]}</h3>
          <ul className="list">{renderList(list, translation.registerOpts[type], lang)}</ul>
        </div>
      ) : null}
      <ClientBalancePayModal
        arrayPayment={[250, 500, 1000, 1500, 2500, 5000]}
        open={showPayModal}
        setOpen={setShowPayModal}
        title={translation.replenishmentBalance[lang]}
        placeholder={translation.clientBalancePayModalPlaceholder[lang]}
        buttonText={translation.clientBalancePayModalButtonText[lang]}
      />
    </div>
  );
};

export default RegisterSearch;

import { useState } from 'react';
import { ConfirmEmail, SendEmail } from '../AddClientEmail';

const ChangeClientEmail = ({ setModal, setLoading }) => {
  const [newEmail, setNewEmail] = useState('');

  return (
    <>
      {newEmail ? (
        <ConfirmEmail email={newEmail} changeEmail={true} setModal={setModal} setLoading={setLoading} setNewEmail={setNewEmail} />
      ) : (
        <SendEmail setNewEmail={setNewEmail} setModal={setModal} setLoading={setLoading} />
      )}
    </>
  );
};

export default ChangeClientEmail;

import { createSlice } from '@reduxjs/toolkit';
import { MOB_RESOLUTION } from 'common_constants/ui';

const mobView = window.innerWidth < MOB_RESOLUTION;

const initialState = {
  mainLoader: false,
  mobSidebar: !mobView,
  mob: mobView,
  confetti: {
    visible: false,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setMainLoader: (state, action) => {
      state.mainLoader = action.payload;
    },
    setMobSideBar: (state, action) => {
      state.mobSidebar = action.payload;
    },
    setMob: (state, action) => {
      state.mob = action.payload;
    },
    setConfetti: (state, action) => {
      state.confetti = action.payload;
    },
  },
});

export const { setMobSideBar, setMainLoader, setMob, setConfetti } = uiSlice.actions;

export default uiSlice.reducer;

import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from 'common_constants/routes';
import { useParams, useHistory } from 'react-router-dom';
import { request, success, error } from '../../../tools';
import { setTenders } from '../../../store/commonReducer';
import RespondItem from './RespondItem';
import BalanceNewButton from '../../../components/Balance/BalanceNewButton';
import Empty from '../../../images/empty-hotlines.svg';
import { translation } from 'common_constants/translation';

const ClientTenderInfo = () => {
  const history = useHistory();
  const params = useParams(); // take id from link
  const tenders = useSelector((state) => state.common.tenders) || [];
  const lang = useSelector((state) => state.common.language);
  const tender = tenders.find((i) => i._id === params.id) || [];
  const dispatch = useDispatch();

  if (!tender) {
    history.push(ROUTES.CLIENT_TENDERS);
    return;
  }

  const handleDeleteTender = () => {
    const body = {
      tenderId: tender._id,
    };
    const onSuccess = (res) => {
      success('Тендер видалено');
      dispatch(setTenders(tenders.filter((i) => i._id !== tender._id)));
      history.push(ROUTES.CLIENT_TENDERS_LIST);
    };
    const onError = (res) => {
      error(res.error);
    };
    request(`/tenders/deleteTender`, body, onSuccess, onError);
  };

  return (
    <div className="tenders-page">
      {!tender?.respondList?.length && (
        <div className="client-hotlines-empty">
          <h3>{translation.clientTTenderInfoTitle1[lang]}</h3>
          <img src={Empty} alt="Hotlines empty logo" />
        </div>
      )}
      <div style={{ marginLeft: '-40px' }}>
        {tender?.accepted ? <h2>{translation.clientTTenderInfoTitle2[lang]}</h2> : <h2>{translation.clientTTenderInfoTitle3[lang]}</h2>}
      </div>
      {tender?.respondList && tender?.respondList.map((item) => <RespondItem key={item._id} data={item} tender={tender} />)}
      {!tender?.accepted && <BalanceNewButton onClick={handleDeleteTender} buttonText="Видалити тендер" arrow={false} />}
    </div>
  );
};

export default ClientTenderInfo;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import ClubCard from '../../components/ClubCard';
import SectionName from '../../components/SectionName';
import BuyClubCardModal from './BuyClubCardModal';
import { CheckClientInfo } from '../../tools/hooks';

import { CLUB_CARDS_SERVICES, CLUB_CARDS_TYPES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { getCurrentMounth } from 'common_components/src/dateUtils';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import './ClubCardPage.scss';
import '../../_mixins.scss';
import { GiftHotline } from '../../Modals/GiftHotline';
import { ServiceInfoModal } from '../../Modals/ServiceInfo';
import SmallRoundedArrow from '../../components/SmallRoundedArrow/SmallRoundedArrow';

const ClubCardPage = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);

  const [openBuyModal, setOpenBuyModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const [openServiceInfoModal, setOpenServiceInfoModal] = useState(false);
  const [currentService, setCurrentService] = useState(null);

  const { clubCard } = useSelector((state) => state.common.clientInfo);
  const correntMonth = getCurrentMounth(clubCard.createdAt, clubCard.expiredAt);
  const isSilverCard = clubCard?.cardType === CLUB_CARDS_TYPES.SILVER || !correntMonth;
  const yesLabel = <CheckOutlined className="yes-label" />;

  const currentTypeCard = isSilverCard ? 'silver' : clubCard?.cardType === CLUB_CARDS_TYPES.GOLD ? 'gold' : 'platinum';

  const handleOpenServiceInfoModal = (service) => {
    setCurrentService(service);
    setOpenServiceInfoModal(true);
  };

  const servicesItems = Object.keys(CLUB_CARDS_SERVICES).map((item) => {
    const totalServices = isSilverCard ? CLUB_CARDS_SERVICES[item][CLUB_CARDS_TYPES.SILVER] : CLUB_CARDS_SERVICES[item][clubCard?.cardType];
    const serviceReminder = isSilverCard ? clubCard?.services[item] : clubCard?.services[correntMonth][item];
    const infinityServices = serviceReminder === 'Infinity';
    let percent = 0;
    if (infinityServices) {
      percent = 100;
    }
    if (totalServices === 0) {
      percent = 0;
    }
    if (totalServices !== 0 && !infinityServices) {
      percent = (serviceReminder / totalServices) * 100;
    }
    return (
      <div key={item} className="row">
        <div className="info">
          <div className="info-btn" onClick={() => handleOpenServiceInfoModal(item)}>
            {translation.clubCardServices[item][lang]}
            <SmallRoundedArrow style={{ display: 'inline-block' }} />
          </div>
          <div className="">
            {correntMonth && clubCard?.services[correntMonth][item] === 'Infinity' ? (
              <span>&infin;</span>
            ) : (
              <div className="counter">
                <span>{isSilverCard ? clubCard?.services[item] : clubCard?.services[correntMonth][item]}</span>
                <span>/</span>
                <span>{totalServices}</span>
              </div>
            )}
          </div>
        </div>
        <div className="bar">
          <span style={{ width: `${percent}%` }}></span>
        </div>
      </div>
    );
  });

  const handleOpenBuyModal = () => setOpenBuyModal(true);
  const handleOpenInfoModal = () => setOpenInfoModal(true);

  useEffect(() => {
    CheckClientInfo(dispatch);
  }, []);

  return (
    <div className="clubCard_page">
      <SectionName text={translation.clubCard[lang]} />
      <ClubCard />
      {!isSilverCard && (
        <div className="block">
          <div className="typeCardPurchased-wrapper">
            <h3 className="typeCardPurchased">
              {translation.cardTypeTitle[lang]} - <span>{translation.clubCardType[currentTypeCard][lang]}</span>
            </h3>
            <p>
              {translation.expiredAt[lang]} <span>{dayjs(clubCard?.expiredAt).format('DD.MM.YYYY')}</span>
            </p>
          </div>
        </div>
      )}

      <div className="block">
        <div className="list">
          <div className="title">
            <h3>{translation.servicesBalance[lang]}</h3>
          </div>

          <div className="row">
            <div className="info">
              <div className="col left">{translation.clubCardServicesStatic.accessToApp[lang]}</div>
              <div className="col right">{yesLabel}</div>
            </div>
          </div>

          {/* <div className="row">
            <div className="info">
              <div className="col left">{translation.clubCardServicesStatic.accessToRegisters[lang]}</div>
              <div className="col right">{isSilverCard ? noLabel : yesLabel}</div>
            </div>
          </div> */}
          {servicesItems}

          {isSilverCard ? (
            <button onClick={handleOpenBuyModal}>{translation.buyClubCard[lang]}</button>
          ) : (
            <button onClick={handleOpenBuyModal}> {translation.upgradeClubCard[lang]}</button>
          )}
        </div>
      </div>
      <GiftHotline open={openGiftModal} setOpen={setOpenGiftModal} />
      <BuyClubCardModal open={openBuyModal} setOpen={setOpenBuyModal} clubCard={clubCard} currentTypeCard={currentTypeCard} />
      {currentService !== null && (
        <ServiceInfoModal
          open={openServiceInfoModal}
          setOpen={setOpenServiceInfoModal}
          service={currentService}
          setOpenGiftModal={setOpenGiftModal}
        />
      )}
    </div>
  );
};

export default ClubCardPage;

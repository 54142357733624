import React, { useEffect, useState } from 'react';
import './Modal.scss';

const Modal = ({ className, title, open, onCancel, footer, footerStyle, children, closable = true, style }) => {
  const [modalOpen, setModalOpen] = useState(open);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const handleClose = () => {
    closable && setModalOpen(false);
    onCancel && onCancel();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  if (!modalOpen) return null;
  //custom prefix prevent bad style from another scss components
  return (
    <div className="custom-modal-overlay" onClick={handleOverlayClick}>
      <div className={`custom-modal ${className}`} style={style}>
        <div className="custom-modal-header">
          <div className="custom-modal-title">{title}</div>
          <button className="custom-close-button" onClick={handleClose}>
            <span className="close-icon"></span>
          </button>
        </div>
        <div className="custom-modal-content">{children}</div>
        {footer && (
          <div className="custom-modal-footer" style={footerStyle}>
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;

import Spin from '../Spin';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

const MainLoader = ({ show }) => {
  const lang = useSelector((state) => state.common.language);

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        position: 'fixed',
        display: show ? 'flex' : 'none',
        alignItems: 'center',
        top: 0,
        left: 0,
        zIndex: 22,
      }}
    >
      <div
        style={{
          margin: 'auto',
          width: '65px',
        }}
      >
        <Spin spinning={true} tip={translation.loader[lang]} size="large" />
      </div>
    </div>
  );
};

export default MainLoader;

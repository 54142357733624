import React from 'react';
import { QrcodeOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

const QRCodeScanButton = ({ onClick, className }) => {
  const lang = useSelector((state) => state.common.language);
  return (
    <div className={`${className}`} onClick={onClick} style={{ cursor: 'pointer' }}>
      <QrcodeOutlined />
      {translation.scanQRcode[lang]}
    </div>
  );
};

export default QRCodeScanButton;

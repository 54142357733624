import { useSelector } from 'react-redux';
import Modal from '../../components/Modal';

import { DAILY_PARTNER_INVITE_LIMIT } from 'common_constants/business';
import { translation } from 'common_constants/translation';

import { info, warn } from '../../tools';

import './styles.scss';
import RHForm from '../../components/RHForm';

const Invite = ({ open, setInviteModal, invitedClients, setInvitedClients, remainingInvitationsToday, setRemainingInvitationsToday }) => {
  const { _id, token } = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);

  const onFinish = ({ phone }) => {
    fetch(process.env.REACT_APP_API + '/partners/inviteClient', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify({ partnerId: _id, phone }),
    })
      .then((res) => res.json())
      .then(({ status, message, exceededDailyLimit, invitedClient, todayInvites, existingInvitation }) => {
        if (status && !exceededDailyLimit) {
          info(message);

          const updatedInvitedClients = [...invitedClients, invitedClient];
          setInvitedClients(updatedInvitedClients);
          setRemainingInvitationsToday(DAILY_PARTNER_INVITE_LIMIT - todayInvites);
          return;
        }

        if (status && exceededDailyLimit) {
          warn(message, ' ');
          return;
        }

        if (!status && existingInvitation) {
          warn(message, ' ');
          return;
        }
      });
  };

  return (
    <Modal className="use_bonuses" title={translation.inviteTitle[lang]} open={open} onCancel={() => setInviteModal(false)} footer={null}>
      <RHForm onFinish={onFinish} className='invite-form'>
        {[
          {
            name: 'phone',
            rHFormOptions: {
              className: 'invite-form-item',
              required: translation.phoneRule1[lang],
              pattern: { value: /^[0-9]{10}$/, message: translation.phoneRule1[lang] },
            },
            options: { label: translation.clientBtn3[lang] },
          },
          {
            name: 'submit',
            options: { className: 'invite-submit-btn', value:  translation.inviteBtn[lang]},
          },
        ]}
      </RHForm>
    </Modal>
  );
};

export default Invite;

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ChatsItem from './ChatsItem';
import UserAvatar from '../../components/UserAvatar';
import { translation } from 'common_constants/translation';
import { setAccountantChats, setModal } from '../../store/commonReducer';
import { CHAT_FOR_DOZHIMS_AI, CHAT_WITH_ACCOUNTANT } from 'common_constants/modals';
import { request, error } from '../../tools';

import './style.scss';
import { FEMALE_ASSISTANTS, MALE_ASSISTANTS } from 'common_constants/business';
import { Badge } from '../../components';

const Chats = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const contracts = useSelector((state) => state.common.contracts) ?? [];
  const accountantChats = useSelector((state) => state.common.accountantChats) ?? [];
  const [avatars, setAvatars] = useState({});

  const [displayContractAI, setDisplayContractAI] = useState(false);
  const [displayConsultationAI, setDisplayConsultationAI] = useState(false);

  const [loading, setLoading] = useState(false);
  const [lastMessage, setLastMessage] = useState('');
  const allAssistants = [...MALE_ASSISTANTS, ...FEMALE_ASSISTANTS];

  const openChat = () => {
    dispatch(
      setModal({
        name: CHAT_FOR_DOZHIMS_AI,
      }),
    );
  };

  const openChatWithAccountant = (_id) => {
    dispatch(
      setModal({
        name: CHAT_WITH_ACCOUNTANT,
        data: {
          _id,
        },
      }),
    );
  };

  const getDisplay = async () => {
    await request('/dozhimsAI/getDates', { userId: clientAuth._id }, ({ date, dateConsultation }) => {
      if (date) {
        setDisplayContractAI(true);
      }
      if (dateConsultation) {
        setDisplayConsultationAI(true);
      }
    });
  };

  const getLastMessage = () => {
    setLoading(true);

    request(
      '/dozhimsAI/getLastMessageChat',
      { userId: clientAuth._id },
      ({ lastMessage }) => {
        if (lastMessage) {
          setLastMessage(lastMessage);
        } else {
          setLastMessage(translation.notMessages[lang]);
        }
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    if (accountantChats) {
      new Set(accountantChats.map((elem) => elem.assistantIndex)).forEach((elem) => {
        if (!avatars[elem]) {
          const avatarName = allAssistants.find((asst) => asst.index === elem)?.avatar;
          import('../../images/' + avatarName).then((avatar) => setAvatars((prev) => ({ ...prev, [elem]: avatar?.default })));
        }
      });
    }
  }, [accountantChats]);

  const getAccountantChats = () => {
    setLoading(true);

    request('/accountantChat/getAllForClient', { userId: clientAuth._id }, (data) => {
      const dataWithThreads = data.data.map((elem) => ({ ...elem, threadId: accountantChats.find((chat) => chat._id === elem._id)?.threadId }));
      dispatch(setAccountantChats(dataWithThreads));
      setLoading(false);
    });
  };

  useEffect(() => {
    getDisplay();
    getLastMessage();
    getAccountantChats();
  }, []);

  return (
    <div className="chats-page">
      <div className="content">
        {contracts.map((item) => (
          <ChatsItem key={item._id} data={item} />
        ))}
        {(displayContractAI || displayConsultationAI) && (
          <div className="chats" onClick={openChat}>
            <UserAvatar size={50} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }} />
            <div className="info">
              <span className="name">{translation.chatForDozhimsAITitle[lang]}</span>
              {loading ? <div className="spinner-opacity"></div> : <span className="message">{lastMessage}</span>}
            </div>
          </div>
        )}
        {accountantChats?.map((elem, index) => {
          const found = allAssistants.find((ast) => ast.index === elem.assistantIndex);
          return (
            <div className="chats" key={index} onClick={() => openChatWithAccountant(elem._id)}>
              <UserAvatar
                customUrl={avatars[found.index]}
                size={50}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }}
              />
              <div className="info">
                <span className="name">{found?.name}</span>
                {loading ? (
                  <div className="spinner-opacity"></div>
                ) : (
                  <span className="message">{elem?.chat?.[elem?.chat.length - 1]?.message ?? translation.notMessages[lang]}</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Chats;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { PRAVO_FOR_CLIENT } from 'common_constants/business';
import { setPravoRating } from '../../store/commonReducer';
import './style.scss';
import { error, request } from '../../tools';

const Ticker = () => {
  const dispatch = useDispatch();

  const [lang, pravoRating, clientAuth] = [
    useSelector((state) => state.common.language),
    useSelector((state) => state.common.pravoRating),
    useSelector((state) => state.common.clientAuth),
  ];

  const [tickerData, setTickerData] = useState(null);

  const getTickerData = () => {
    const manualRatingItems = pravoRating?.filter((item) => item.manualRating);
    const noWeightRatingItems = pravoRating?.filter((item) => !item.weight);
    const onlyFree = noWeightRatingItems?.slice(0, 5).filter((item) => !item.manualRating);

    manualRatingItems?.forEach((item) => {
      if (item.manualRating === 40) {
        onlyFree.push(item);
      }
    });

    const discountsData = onlyFree?.map((item) => PRAVO_FOR_CLIENT[item.pravo]) ?? [];
    discountsData.unshift(translation.freeConsultations[lang]);
    const dataString = discountsData.join(' * ');
    setTickerData(dataString);
  };

  const getPravoRatting = () => {
    request.get(
      '/pravoRating/getForClient',
      (res) => {
        dispatch(setPravoRating(res.pravoRating));
      },
      error,
    );
  };

  useEffect(() => {
    if (!clientAuth) return;

    getPravoRatting();
  }, []);

  useEffect(() => {
    if (!pravoRating?.length) return;

    getTickerData();
  }, [pravoRating]);

  return (
    <div className="ticker-wrapper">
      <div className="promo-day">{translation.tickerPromoDay[lang]}</div>
      <div className="text">
        {tickerData && (
          <div className="marquee-content">
            <span className="marquee-item">{tickerData}</span>
            <span className="marquee-item charity">{translation.tickerCharity[lang]}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ticker;

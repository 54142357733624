import React, { useEffect, useState, useRef } from 'react';
import './Typography.scss';
import TextArea from '../Textarea';
import { EnterOutlined, EditOutlined } from '@ant-design/icons';
import Button from '../Button';

const Text = ({ className, style, children, strong, ...props }) => {
  const classList = ['typogrpahy__text'];
  if (strong) {
    classList.push('typogrpahy__text-strong');
  }

  return (
    <span className={`${classList.join(' ')} ${className || ''}`} style={style} {...props}>
      {children}
    </span>
  );
};

const Strong = ({ className, style, children, ...props }) => {
  return (
    <strong className={`custom-strong ${className || ''}`} style={style} {...props}>
      {children}
    </strong>
  );
};

const Title = ({ level = 1, children, type, className, underline, lineThrough, ...props }) => {
  const Component = `h${level}`;

  const classNames = ['typogrpahy__title', String(className)];
  if (underline) {
    classNames.push(`typogrpahy__title-underline`);
  }
  if (lineThrough) {
    classNames.push(`typogrpahy__title-line-through`);
  }
  classNames.push(`typogrpahy__title-h${level}`);

  return (
    <Component className={classNames.join(' ')} {...props}>
      {children}
    </Component>
  );
};

const Paragraph = ({ children, editable, className, style, onClick, ...props }) => {
  const getInitValue = () => {
    if (typeof children === 'string') return children;
    if (typeof children.props.children === 'string') return children.props.children;
    return '';
  };

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(getInitValue);
  const saveRef = useRef();

  const handleStartEditing = () => {
    if (editable && editable.onStart) {
      editable.onStart();
      setIsEditing(true);
    }
  };

  const handleCancelEditing = () => {
    if (editable) {
      editable.onCancel?.();
      setValue(getInitValue());
    }
    setIsEditing(false);
  };

  const handleSave = () => {
    if (editable) {
      editable.onSave?.(value);
      editable.onEnd?.();
    }

    setIsEditing(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (isEditing) {
      const cb = (e) => {
        if (e.key === 'Enter') {
          saveRef.current?.();
        } else if (e.key === 'Escape') {
          handleCancelEditing();
        }
      };
      window.addEventListener('keydown', cb);
      return () => {
        window.removeEventListener('keydown', cb);
      };
    }
  }, [isEditing, editable.onCancel]);

  useEffect(() => {
    saveRef.current = handleSave;
  }, [editable.onSave, value]);

  return (
    <div {...props} className={className} style={style}>
      {isEditing ? (
        <div className="typogrpahy__editable">
          <TextArea value={value} onChange={handleChange} onBlur={handleCancelEditing} autoFocus />
          <EnterOutlined className="typogrpahy__editable__enter" />
        </div>
      ) : (
        <p onDoubleClick={handleStartEditing}>
          {value ?? children}
          {editable && (
            <Button
              variant={'light'}
              onClick={handleStartEditing}
              style={{ marginLeft: 8 }}
              icon={<EditOutlined />}
              className={'typogrpahy__edit__btn'}
            />
          )}
        </p>
      )}
    </div>
  );
};

const Typography = {
  Text,
  Strong,
  Title,
  Paragraph,
};

export default Typography;

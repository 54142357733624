import { useDispatch, useSelector } from 'react-redux';
import { setClientPolicyConfirmed } from '../../store/commonReducer';
import { request, warn, success } from '../../tools';
import { WEB_SITE_LINK } from 'common_constants/routes';
import { translation } from 'common_constants/translation';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'common_constants/routes';
import { setMainLoader } from '../../store/uiReducer';
import MainLoader from '../../components/MainLoader';

const PolicyConfirm = () => {
  const history = useHistory();
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const lang = useSelector((state) => state.common.language);
  const { ph } = clientAuth;
  const dispatch = useDispatch();

  const successMessage = translation.PrivacyPolicySuccess[lang];
  const failureMessage = translation.PrivacyPolicyFailure[lang];

  const handleConfirmPolicy = () => {
    dispatch(setMainLoader(true));
    const onSuccess = () => {
      success(successMessage);
      dispatch(setClientPolicyConfirmed({ policyConfirmed: true }));
      history.push(ROUTES.HOME);
      dispatch(setMainLoader(false));
    };
    const onError = () => {
      warn(failureMessage);
      dispatch(setMainLoader(false));
    };
    const data = { ph };
    request('/auth/clientConfirmPrivacyPolicy', data, onSuccess, onError);
  };
  return (
    <div className="ConfirmChangePhone-wrapper">
      <MainLoader />
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        {translation.regStep3Confirm1[lang]}{' '}
        <a href={`${WEB_SITE_LINK}/PolitykaKonfidentsiinosti.html`} rel="noreferrer" target="_blank">
          {translation.regStep3Confirm2[lang]}
        </a>{' '}
      </div>
      <div onClick={handleConfirmPolicy} className="invite-button">
        {translation.confirmButton[lang]}
      </div>
    </div>
  );
};

export default PolicyConfirm;

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import certificate from '../../images/certificate.jpg';
import { Image, Spin } from '../../components';

import './AnonimCertificateHome.scss';

const AnonimCertificateHome = () => {
  const { pathname } = useLocation();
  const [isCertLoading, setIsCertLoading] = useState(true);
  const clientPhone = pathname.split('/')[2];

  const onCertLoad = () => {
    setIsCertLoading(false);
  };

  return (
    <div className="anonim-certificate-home-page">
      <Spin spinning={isCertLoading}>
        <div className="certificate">
          {!isCertLoading ? <p className="number-client">{clientPhone}</p> : null}

          <Image className="certificate-image" src={certificate} alt="certificate" onLoad={onCertLoad} preview={false} />
        </div>
      </Spin>
    </div>
  );
};

export default AnonimCertificateHome;

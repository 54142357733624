import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../store/commonReducer';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Avatar from '../../components/Avatar';
import ButtonIcon from '../../components/Partner/PartnerButtons/ButtonIcon';
import { PHONE_OFFICE_VIEW_CLIENT } from 'common_constants/business';
import { ADD_CLIENT_EMAIL } from 'common_constants/modals';
import { translation } from 'common_constants/translation';

const Rules = ({ open, setRulesModal }) => {
  const dispatch = useDispatch();
  const rulesReed = localStorage.getItem('rulesReed');
  const missingEmail = useSelector((state) => state.common.missingEmail);
  const lang = useSelector((state) => state.common.language);

  const onAgree = () => {
    localStorage.setItem('rulesReed', true);
    setRulesModal(false);
    if (missingEmail) dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
  };

  const onCancel = () => {
    rulesReed && setRulesModal(false);
    if (missingEmail) dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
  };

  return (
    <Modal title="" open={open} onCancel={onCancel} footer={null} closable={rulesReed}>
      <p style={{ textAlign: 'center' }}>
        <span
          style={{
            fontSize: '24pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#333399',
              }}
            >
              <strong>{translation.partnerHomeRulesTitle[lang]}</strong>
            </span>
          </span>
        </span>
      </p>
      <div className="video_container">
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/-6uU4WrKsIk?autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText1[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;- {translation.partnerHomeRulesText2[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText3[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText4[lang]}&nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText5[lang]}</strong>
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText6[lang]}&nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText7[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText8[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>&nbsp;</p>

      <hr />
      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '18pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#333399',
              }}
            >
              <strong>{translation.partnerHomeRulesText9[lang]}</strong>
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText10[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;- {translation.partnerHomeRulesText11[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText12[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;&ndash; {translation.partnerHomeRulesText13[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>&nbsp;</p>

      <hr />
      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '18pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#333399',
              }}
            >
              <strong>{translation.partnerHomeRulesText14[lang]}</strong>
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '13pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times,serif',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '13pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Apple Color Emoji',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              🔘
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText15[lang]}&nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText16[lang]}</strong>&nbsp;{translation.partnerHomeRulesText17[lang]}
            </span>
          </span>
        </span>
        <ButtonIcon iconType={'toInvite'} />
      </p>

      <p>
        <span
          style={{
            fontSize: '13pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Apple Color Emoji',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              🔘
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText18[lang]}&nbsp;
            </span>
          </span>
        </span>
        <ButtonIcon iconType={'inviteList'} />
      </p>

      <p>
        <span
          style={{
            fontSize: '13pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Apple Color Emoji',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              🔘
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText19[lang]}&nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText20[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText21[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText22[lang]}&nbsp;
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '13pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Apple Color Emoji',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              🔘
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText23[lang]}&nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText24[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText25[lang]}&nbsp;
            </span>
          </span>
        </span>
        <ButtonIcon iconType={'payPartner'} />
      </p>

      <p>&nbsp;</p>

      <hr />
      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '18pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#333399',
              }}
            >
              <strong>{translation.partnerHomeRulesText26[lang]}</strong>
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText27[lang]}&nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText28[lang]}</strong>
            </span>
          </span>
        </span>
      </p>

      <ul>
        <li style={{ listStyleType: 'disc' }}>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                {translation.partnerHomeRulesText29[lang]}&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                <strong>{translation.partnerHomeRulesText30[lang]}</strong>
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                {translation.partnerHomeRulesText31[lang]}
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: 'disc' }}>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                {translation.partnerHomeRulesText32[lang]}&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                <strong>{translation.partnerHomeRulesText33[lang]}</strong>
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                ;
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: 'disc' }}>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                {translation.partnerHomeRulesText34[lang]}&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                <strong>{translation.partnerHomeRulesText35[lang]}</strong>
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                ;
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: 'disc' }}>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                {translation.partnerHomeRulesText36[lang]}&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                <strong>{translation.partnerHomeRulesText37[lang]}&nbsp;</strong>
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                {translation.partnerHomeRulesText38[lang]}
              </span>
            </span>
          </span>
        </li>
        <li style={{ listStyleType: 'disc' }}>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                {translation.partnerHomeRulesText39[lang]}&nbsp;
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                <strong>{translation.partnerHomeRulesText40[lang]}</strong>
              </span>
            </span>
          </span>
          <span
            style={{
              fontSize: '12pt',
            }}
          >
            <span
              style={{
                fontFamily: 'Times New Roman',
              }}
            >
              <span
                style={{
                  color: '#000000',
                }}
              >
                .
              </span>
            </span>
          </span>
        </li>
      </ul>

      <p>
        <span
          style={{
            fontSize: '13.5pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText41[lang]}</strong>
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              1.&nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText42[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText43[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText44[lang]}&nbsp;
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText45[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              .
            </span>
          </span>
        </span>
      </p>

      <p>&nbsp;</p>

      <hr />
      <p>
        <span
          style={{
            fontSize: '18pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#333399',
              }}
            >
              <strong>{translation.partnerHomeRulesText46[lang]}</strong>
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText47[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText48[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText49[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText50[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText51[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText52[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText53[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText54[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              <strong>{translation.partnerHomeRulesText55[lang]}</strong>
            </span>
          </span>
        </span>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              &nbsp;{translation.partnerHomeRulesText56[lang]}
            </span>
          </span>
        </span>
      </p>

      <p>
        <span
          style={{
            fontSize: '12pt',
          }}
        >
          <span
            style={{
              fontFamily: 'Times New Roman',
            }}
          >
            <span
              style={{
                color: '#000000',
              }}
            >
              {translation.partnerHomeRulesText57[lang]} {PHONE_OFFICE_VIEW_CLIENT}
            </span>
          </span>
        </span>
      </p>

      <p>&nbsp;</p>
      <Button
        style={{
          width: '100%',
          height: '50px',
          background: '#ceeab0',
        }}
        onClick={onAgree}
      >
        {translation.agreeRulesBtn[lang]}
      </Button>
    </Modal>
  );
};

export default Rules;

import React, { useState } from 'react';
import { PhoneOutlined, SendOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { PHONE_OFFICE_VIEW_CRM, VIBER_CHAT_OFFICE_VIEW_CRM, TELEGRAM_CHAT_OFFICE_VIEW_CRM } from 'common_constants/business';

import './Contacts.scss';

const Contacts = ({ setActiveClass, activeClass, homepage }) => {
  const [menuState, setMenuState] = useState(false);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [wasDragged, setWasDragged] = useState(false);

  const style = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    cursor: isDragging ? 'grabbing' : 'grab',
  };

  const onClickMenu = () => {
    if (wasDragged) {
      setWasDragged(false);
    } else {
      setActiveClass(!activeClass);
      setMenuState(!menuState);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);

    let startX = e.clientX;
    let startY = e.clientY;

    const handleMouseMove = (e) => {
      setWasDragged(true);
      let deltaX = e.clientX - startX;
      let deltaY = e.clientY - startY;

      setPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));

      startX = e.clientX;
      startY = e.clientY;
    };

    const handleMouseUp = () => {
      setIsDragging(false);

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setWasDragged(false);

    let startX = e.touches[0].clientX;
    let startY = e.touches[0].clientY;

    const handleTouchMove = (e) => {
      setWasDragged(true);
      let deltaX = e.touches[0].clientX - startX;
      let deltaY = e.touches[0].clientY - startY;

      setPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));

      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
    };

    const handleTouchEnd = () => {
      setIsDragging(false);

      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  return (
    <div
      className={`contacts${menuState ? ' _opened' : ''} ${!homepage ? ' not-homepage' : ''}`}
      onClick={onClickMenu}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      style={style}
    >
      <div className="contacts-dropdown">
        <div className="contacts-dropdown__item">
          <a rel="noopener noreferrer" target="_blank" href={TELEGRAM_CHAT_OFFICE_VIEW_CRM}>
            <SendOutlined />
          </a>
        </div>
        <div className="contacts-dropdown__item">
          <a rel="noopener noreferrer" target="_blank" href={VIBER_CHAT_OFFICE_VIEW_CRM}>
            <WhatsAppOutlined />
          </a>
        </div>
        <div className="contacts-dropdown__item">
          <a href={PHONE_OFFICE_VIEW_CRM}>
            <PhoneOutlined />
          </a>
        </div>
      </div>
      <div className="contacts-button">
        <span className="contacts-button__item"></span>
        <span className="contacts-button__item"></span>
        {homepage ? (
          <img className="contacts-button__logo" src="/contacts-btn.svg" alt="" />
        ) : (
          <div className="contacts-button-gif-inner">
            <img src="/zahist_help.gif" alt="cover" style={{ opacity: menuState ? '0' : '1', transition: '0.35s' }} width="60" height="60" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Contacts;

import apiService from '../../../api';

export const fetchContracts = async () => await apiService.getContracts();

export const fetchHotlines = async () => await apiService.getHotlines();

export const fetchCourts = async () => await apiService.getCourts();

export const fetchPlaces = async () => await apiService.getPlaces();

export const setClientContractName = async (id, cn) => await apiService.setClientContractName(id, cn);

export const fetchRemoveNotifications = async (nameNotif, _id, i, phone) => await apiService.removeNotifications(nameNotif, _id, i, phone);

export const fetchRemovePushes = async (ids) => await apiService.removePushes(ids);

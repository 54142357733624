import React, { useState, useEffect } from 'react';
import './Message.scss';

const Message = ({ type, content, zIndex }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={`common_message ${type} ${isVisible ? 'visible' : 'hidden'}`} style={{ zIndex }}>
      {content}
    </div>
  );
};

export default Message;

import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

const isPastDate = (date) => {
  return dayjs(date).startOf('day').isBefore(dayjs());
};

const PaymentManagementBox = ({ amount, date, isPaid, onClick, isSelected }) => {
  return (
    <div
      className={clsx('payment-management-box', isPaid ? 'paid' : isPastDate(date) ? 'not-paid' : '', isSelected && 'selected')}
      onClick={isPaid ? () => {} : onClick}
    >
      <p className="amount">{amount ?? 0}₴</p>
      <p className="date">{dayjs(date)?.utc(true)?.format('DD.MM.YYYY')}</p>
    </div>
  );
};

export default PaymentManagementBox;

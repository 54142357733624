import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { editContracts, setContract } from '../../../../store/commonReducer';

import { translation } from 'common_constants/translation';
import { NOTIFICATION } from 'common_constants/business';

import { fetchRemoveNotifications } from '../../../ClientContracts/ClientContractsHelpers';
import ModalWindow from '../../../../Modals/ModalWindow';

import { ClientButton } from '../../../../components';
import { icon_correspondence } from '../../images';

import '../../ClientContractInfo.scss';

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import MultyItemCorAndTab from '../MultyItemCorAndTab';

const CorrespondenceBtnAndModal = () => {
  dayjs.extend(isSameOrAfter);
  dayjs.locale('uk');

  const dispatch = useDispatch();

  const params = useParams();

  const [lang, contracts, clientInfo] = [
    useSelector((state) => state.common.language),
    useSelector((state) => state.common.contracts) ?? [],
    useSelector((state) => state.common.clientInfo) ?? [],
  ];

  const coreMegaState = {
      loading: false,
      eventsModal: false,
      tasksModal: false,
      correspondenceModal: false,
    },
    [megaState, setMegaState] = useState(coreMegaState);

  const [contract, correspondence] = [
    contracts.find((i) => i._id === params.id),
    Array.isArray(contracts)
      ? JSON.parse(JSON.stringify(contracts.find((i) => i._id === params.id)?.correspondence ?? []))?.sort((a, b) => new Date(b.t) - new Date(a.t)) ??
        []
      : [],
  ];

  const bargeNotificationCorrespondence = correspondence?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0;

  const removeNotifCorrespondenceClick = async () => {
    if (bargeNotificationCorrespondence && contract?.i >= 0) {
      const notif = await fetchRemoveNotifications(NOTIFICATION.CORRESPONDENCE, null, contract.i, null);

      if (notif) {
        const _contract = {
          ...contract,
          correspondence:
            contract.correspondence?.map(
              (еi) => (еi = { ...еi, notif: Array.isArray(еi.notif) ? [...еi.notif, { watched: true }] : [{ watched: true }] }),
            ) ?? [],
        };

        dispatch(editContracts(_contract));
      }
    }
  };

  const correspondenceList = [
    ...(correspondence?.filter((item) => item.df?.length > 0)?.map((item) => ({ ...item, whereIsTheFileFrom: 'correspondence', date: item.t })) ??
      []),
  ]
    ?.sort((a, b) => dayjs(b.t).diff(dayjs(a.t)))
    ?.map((item) => <MultyItemCorAndTab key={item.fileId ?? item.t ?? dayjs().valueOf()} item={item} clientInfo={clientInfo} isModalCor={true} />);

  const correspondenceOpenModal = (value) => setMegaState((prev) => ({ ...prev, correspondenceModal: value }));

  const correspondenceCloseModal = (value) => {
    removeNotifCorrespondenceClick();
    setMegaState((prev) => ({ ...prev, correspondenceModal: value }));
  };

  useEffect(() => {
    dispatch(setContract(contract));
  }, []);

  // todo: add 404 page
  if (!contract) return null;

  return (
    <Fragment>
      <ClientButton
        setModal={correspondenceOpenModal}
        text={translation.clientCInfCuratorButton6[lang]}
        icon={icon_correspondence}
        badge={bargeNotificationCorrespondence}
      />

      <ModalWindow modal={megaState.correspondenceModal} setModal={correspondenceCloseModal} children={correspondenceList} />
    </Fragment>
  );
};

export default CorrespondenceBtnAndModal;

import React from 'react';
import ModalWindow from '../../Modals/ModalWindow/ModalWindow';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

const RulesBonuses = ({ open, setRulesBonusesModal }) => {
  const lang = useSelector((state) => state.common.language);
  return (
    <ModalWindow title={translation.rulesBonusesTitle[lang]} modal={open} setModal={setRulesBonusesModal}>
      {translation.rulesBonusesText1[lang]}
      <br />
      <br />
      <b>{translation.rulesBonusesText2[lang]}</b>
      <br />
      <br />
      {translation.rulesBonusesText3[lang]}
      <br />
      <br />
      <b>{translation.rulesBonusesText4[lang]}</b>
      <br />
      <br />
      {translation.rulesBonusesText5[lang]}
      <br />
      <br />
      {translation.rulesBonusesText6[lang]}
      <br />
      <br />
      <b>{translation.rulesBonusesText7[lang]}</b>
      <br />
      <br />
      {translation.rulesBonusesText8[lang]}
      <br />
      {translation.rulesBonusesText9[lang]}
      <br />
      {translation.rulesBonusesText10[lang]}
      <br />
      {translation.rulesBonusesText11[lang]}
      <br />
      {translation.rulesBonusesText12[lang]}
      <br />
    </ModalWindow>
  );
};

export default RulesBonuses;

import React from 'react';
import './Avatar.scss';
import clsx from 'clsx';

const Avatar = ({ src, alt, size, style, icon, fontSize,className }) => {
  const avatarStyle = {
    width: size,
    height: size,
    ...style,
  };
  const iconStyle = {
    fontSize: fontSize ?? size
  }

  return (
    <div className={clsx('avatar', className)} style={avatarStyle}>
      {src ? <img src={src} alt={alt} style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover' }} /> : React.cloneElement(icon, {style: iconStyle})}
    </div>
  );
};

export default Avatar;

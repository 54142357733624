import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Button from '../Button';
import './Pagination.scss';

const Pagination = ({ page, pages, onChange, className, ...props }) => {
  let pageButtons;

  if (page === 0) {
    pageButtons = new Array(Math.min(3, pages)).fill(0).map((_, index) => index);
  } else if (page === pages - 1) {
    pageButtons = new Array(Math.min(3, pages))
      .fill(0)
      .map((_, index) => pages - 1 - index)
      .reverse();
  } else {
    pageButtons = [page - 1, page, page + 1];
  }

  return (
    <div className={'pagination__wrapper ' + (className ?? '')} {...props}>
      <Button variant={'link'} className={`pagination__button ${page === 0 ? 'disabled' : ''}`} onClick={() => onChange?.(page - 1)}>
        <LeftOutlined />
      </Button>
      {pageButtons.map((elem) => (
        <Button variant={'link'} className={`pagination__button ${elem === page ? 'active' : ''}`} onClick={() => onChange?.(elem)}>
          {elem + 1}
        </Button>
      ))}
      <Button variant={'link'} className={`pagination__button ${page === pages - 1 ? 'disabled' : ''}`} onClick={() => onChange?.(page + 1)}>
        <RightOutlined />
      </Button>
    </div>
  );
};

export default Pagination;

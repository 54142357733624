import React, { useState } from 'react';

const CustomInputNumber = ({ value, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <div>
      <input type="number" value={inputValue} onChange={handleInputChange} {...props} />
    </div>
  );
};

export default CustomInputNumber;

import './styles.scss';
import { DECIMAL_RATING_TO_CLIENT } from 'common_constants/business/index';

const InputRange = ({ value, onChange, style }) => {
  return (
    <div className="input-range" style={style}>
      <input type="range" min={1} max={10} id="marks" name="marks" value={value} onChange={(e) => onChange(e.target.value)} />
      <datalist id="values">
        {DECIMAL_RATING_TO_CLIENT.map((item) => (
          <option value={item} label={item}></option>
        ))}
      </datalist>
    </div>
  );
};

export default InputRange;

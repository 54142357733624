import dayjs from 'dayjs';
import Alert from '../../../../components/Alert/Alert';
import { SERVICES_FOR_CLINETS_APP } from 'common_constants/business';
import { Column, Row } from '../../../../components';
import { translation } from 'common_constants/translation';

export default function AppointmentForToday({ appointmentForToday, data, lang, getCurrentFilForAlert }) {
  if (!appointmentForToday) return null;

  return (
    <Alert
      message={`${translation.clientHotline.youHaveAnAppointment[lang]} ${dayjs(appointmentForToday.date).format('DD.MM.YYYY')}`}
      description={
        <Column>
          <Row justify={'space-between'}>
            <Column>
              <strong>{translation.service[lang]}: </strong>
              {SERVICES_FOR_CLINETS_APP.find((service) => service.index === Number(data.type))?.label[lang]}
            </Column>
            <Column>
              <strong>{translation.branch[lang]}: </strong> {getCurrentFilForAlert()}
            </Column>
          </Row>

          <Row justify={'space-between'}>
            <Column>
              <strong>{translation.from[lang]}: </strong> {appointmentForToday.startT}
            </Column>
            <Column>
              <strong>{translation.to[lang]}: </strong> {appointmentForToday.endT}
            </Column>
          </Row>
        </Column>
      }
      type="info"
    />
  );
}

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { translation } from 'common_constants/translation';
import { Popconfirm } from '../../../components';

export const TaskActions = ({ item, rejectTask, setDoneTask, lang }) => {
  return (
    <div className="buttons-wrapper">
      {!item.rejected && !item.done && (
        <Popconfirm
          title={translation.taskDoneConfirmText[lang]}
          onConfirm={() => setDoneTask()}
          okText={translation.yes[lang]}
          cancelText={translation.no[lang]}
        >
          <div className="done-task-btn">
            <CheckOutlined />
          </div>
        </Popconfirm>
      )}

      {!item.rejected && !item.done && (
        <Popconfirm
          title={translation.taskRejectConfirmText[lang]}
          onConfirm={() => rejectTask()}
          okText={translation.yes[lang]}
          cancelText={translation.no[lang]}
        >
          <div className="cancel-task-btn">
            <CloseOutlined />
          </div>
        </Popconfirm>
      )}
    </div>
  );
};

import React, { forwardRef, useState } from 'react';
import clsx from 'clsx';
import './Input.scss';

const Input = forwardRef(({ rules, prefix, className, ...props }, ref) => {
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;

    if (rules) {
      const isValid = rules.some((rule) => {
        if (rule.required && !value) {
          return true;
        } else if (rule.pattern && !rule.pattern.test(value)) {
          return true;
        }
        return false;
      });

      setError(isValid);
    }
  };

  return (
    <div className={clsx('input-new-container', { 'with-prefix': prefix })}>
      {prefix && <span className="prefix">{prefix}</span>}
      <input ref={ref} className={clsx('input-new', className, { error: error })} {...props} />
    </div>
  );
});

export default Input;

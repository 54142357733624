import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RHForm, Modal, Spin } from '../../components';
import { error, request, success } from '../../tools/index.js';
import { translation } from 'common_constants/translation';

import './ClientContracts.scss';

const ComplaintForm = ({ open, setOpen, curator, contractId }) => {
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.common.language);

  const handleSubmit = (values) => {
    setLoading(true);
    const body = { text: values.complaint, curator: curator._id, contractId };
    request(
      '/complaint/add',
      body,
      (res) => {
        if (res.status) {
          success();
          setLoading(false);
          setOpen(false);
        }
      },
      () => {
        error();
        setLoading(false);
      },
    );
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} title={translation.ComplaintFormModalTitle[lang]} onCancel={onCancel}>
      <Spin spinning={loading} tip="Loading" size="large">
        <RHForm onFinish={handleSubmit} name="complaintForm" className="complaint-form">
          {[
            {
              name: 'complaint',
              type: 'textarea',
              rHFormOptions: {
                required: translation.requiredField[lang],
                minLength: {
                  value: 20,
                  message: translation.ComplaintFormMinCharactersValidation[lang],
                },
              },
              options: {
                placeholder: translation.ComplaintFormTextAreaPlaceholder[lang],
              },
            },
            {
              name: 'submit',
              options: { value: translation.ComplaintFormModalOkText[lang], className: 'submit-btn' },
            },
          ]}
        </RHForm>
      </Spin>
    </Modal>
  );
};

export default ComplaintForm;

import './BalanceNewButton.scss';

const InviteButton = ({ onClick = null, buttonText, arrow = true, disabled = false, ...props }) => {
  return (
    <div {...props} onClick={disabled ? null : onClick} className={`invite-button ${disabled ? 'disabled' : ''}`}>
      <div>{buttonText}</div>
      {arrow && <div className="invite-button-arrow"></div>}
    </div>
  );
};

export default InviteButton;

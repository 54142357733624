import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../components/Modal/Modal';
import Button from '../../components/Button';
import Radio from '../../components/Radio/Radio';
import { CURRENCIES, PAY_QUICKCHAT } from 'common_constants/business';
import { translation } from 'common_constants/translation';

import BalanceNew from '../../components/Balance/BalanceNew';
import ClientBalancePayModal from '../../components/ClientMenu/ClientBalancePayModal';
import { ROUTES } from 'common_constants/routes';

const PaymentQuickChat = ({ open, onCancel, onPay, paymentIndex, payModalLoading }) => {
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const { clientBalance } = clientInfo || 0;

  const [clientBalanceModalVisible, setClientBalanceModalVisible] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [isPayButtonDisabled, setIsPayButtonDisabled] = useState(true);

  const onClose = () => {
    setSelectedPaymentOption(null);
    setIsPayButtonDisabled(true);
    onCancel();
  };

  const handleRadioChange = (e) => {
    //can cause an error without converting string data to number in quickchat.jsx
    const value = Number(e.target.value);
    setSelectedPaymentOption(value);
  };

  const handlePay = () => {
    paymentIndex(selectedPaymentOption);
    onPay(selectedPaymentOption);
  };

  useEffect(() => {
    if (selectedPaymentOption !== null || clientBalance > PAY_QUICKCHAT[selectedPaymentOption]?.amount) {
      setIsPayButtonDisabled(false);
    } else {
      setIsPayButtonDisabled(true);
    }
  }, [selectedPaymentOption, clientBalance]);

  return (
    <Modal
      open={open}
      title={translation.quickQuestionTitle[lang]}
      onCancel={onClose}
      footer={[
        <Button key="cancel" variant="default-light" onClick={onClose}>
          {translation.cnlBtn[lang]}
        </Button>,
        <Button style={{ padding: '2px 10px' }} key="pay" variant="default" onClick={handlePay} disabled={isPayButtonDisabled || payModalLoading}>
          {translation.payBtn[lang]}
        </Button>,
      ]}
      footerStyle={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div>
        <BalanceNew balance={clientBalance} modal={() => setClientBalanceModalVisible(true)} />
        <ClientBalancePayModal
          arrayPayment={[250, 500, 1000, 1500, 2500, 5000]}
          open={clientBalanceModalVisible}
          setOpen={setClientBalanceModalVisible}
          title={translation.replenishmentBalance[lang]}
          placeholder={translation.clientBalancePayModalPlaceholder[lang]}
          buttonText={translation.clientBalancePayModalButtonText[lang]}
          redirectUrl={ROUTES.CLIENT_QUICK_CHAT}
        />
      </div>
      <p>{translation.quickQuestion1[lang]}</p>
      <div>
        {PAY_QUICKCHAT.map((option, index) => (
          <Radio
            key={index}
            style={{ display: 'flex', position: 'relative', padding: '8px' }}
            type="radio"
            name="paymentOption"
            value={index}
            onChange={handleRadioChange}
            isActive={selectedPaymentOption === index}
          >
            <div>
              {option.value} - {CURRENCIES[clientInfo.currency]?.symbol ?? '₴'}
              {option.amount} &nbsp;
              <span style={{ textDecoration: 'line-through', color: 'red' }}>
                {option.discountPrice > 0 && `${CURRENCIES[clientInfo.currency]?.symbol ?? '₴'}${option.amount + option.discountPrice}`}
              </span>
              {option.discount > 0 && (
                <span
                  style={{
                    borderRadius: '8px',
                    borderColor: 'red',
                    color: 'red',
                    padding: '2px 4px',
                    fontSize: '12px',
                    borderStyle: 'dashed',
                    borderWidth: '1px',
                    marginLeft: '4px',
                  }}
                >
                  -{option.discount}%
                </span>
              )}
              &nbsp;
              {option.discount === 80 && (
                <span style={{ borderRadius: '4px', padding: '4px', fontSize: '8px', backgroundColor: '#334768', color: 'white', marginLeft: '4px' }}>
                  {translation.quickQuestion2[lang]}
                </span>
              )}
            </div>
          </Radio>
        ))}
      </div>
    </Modal>
  );
};

export default PaymentQuickChat;

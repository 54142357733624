import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { postFile, error, success, warn } from '../../../tools';
import { Row as MyRow } from 'common_components';
import { Spin, Card } from '../../../components';
import CuratorButton from '../CuratorButton/CuratorButton';
import { icon_plusFile } from '../images';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import './AddFileTabList.scss'; // Импортируем стили

const AddFileTabList = ({ auditClose, contractId, massegeList }) => {
  dayjs.locale('uk');
  const formRef = useRef();
  const userAuth = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);

  const coreMegaState = {
    loading: false,
    loadingFile: false,
    correspondence: [],
    auditAddFile: false,
    fileList: [],
  };
  const [megaState, setMegaState] = useState(coreMegaState);

  const onSendFile = (params = {}) => {
    const { formData, callback, onError } = params;

    if (!formData) return error(translation.addFileTabListError1[lang]);
    if (typeof callback !== 'function') return error(translation.addFileTabListError2[lang]);

    formData.append('documentId', contractId);
    formData.append('userId', userAuth._id);

    postFile('/chatPrivate/chatSendFileClient', formData, callback, onError);
  };

  const makeMyFileMessage = ({ fileId, fileName, fileSize, comment }) => ({
    fileId,
    fileName,
    fileSize,
    isSentByCurrentUser: true,
    date: dayjs(),
    name: userAuth.name,
    ...(comment ? { comment: comment } : {}),
  });

  const launchFormAddFile = (event) => {
    event.preventDefault();

    const file = megaState.fileList[0];

    const comment = formRef.current['comment'].value;

    if (!file) return;

    setMegaState((prev) => ({ ...prev, loadingFile: true }));

    const formData = new FormData();
    formData.append('file', file, file.name);

    if (comment) formData.append('comment', comment);

    onSendFile({
      formData: formData,
      callback: (res) => {
        setMegaState((prev) => ({
          ...prev,
          loadingFile: false,
          auditAddFile: false,
          fileList: [],
        }));

        massegeList(makeMyFileMessage(res.data?.[0]));

        success('', translation.addFileTabListSuccess[lang]);
      },
      onError: () => {
        setMegaState((prev) => ({ ...prev, loadingFile: false, auditAddFile: false, fileList: [] }));

        error('', translation.addFileTabListError3[lang]);
      },
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file.name.includes('.')) {
      warn('', translation.addFileTabListWarn[lang]);
      return false;
    }

    setMegaState((prevState) => ({ ...prevState, fileList: [file] }));
  };

  useEffect(() => {
    if (!auditClose) setMegaState((prev) => ({ ...prev, ...coreMegaState }));
  }, [auditClose]);

  const buttonView = (
    <CuratorButton
      icon={icon_plusFile}
      text={translation.addFileTabListBtn[lang]}
      modal={() => setMegaState((prev) => ({ ...prev, auditAddFile: true }))}
    />
  );

  const formView = (
    <Card>
      <form className="add-file-tab-form" ref={formRef} onSubmit={launchFormAddFile}>
        <Spin spinning={megaState.loadingFile}>
          <div className="ant-spin ant-spin-lg">
            <div className="ant-card ant-card-small">
              <label className="ant-form-item-label">{translation.addFileTabListUploadFile[lang]}</label>
              <input type="file" onChange={handleFileChange} />
              <label className="ant-form-item-label">{translation.addFileTabListCommentLabel[lang]}</label>
              <input
                type="text"
                name="comment"
                placeholder={translation.addFileTabListCommentPlaceholder[lang]}
                aria-label="We need to enter a special comment for the file name in the chat"
              />

              <CuratorButton
                type="submit"
                disabled={!megaState.fileList[0]}
                icon={icon_plusFile}
                text={translation.sendFileBtn[lang]}
                modal={() => setMegaState((prev) => ({ ...prev, auditAddFile: true }))}
                className="ant-btn ant-btn-primary"
              />
            </div>
          </div>
        </Spin>
      </form>
    </Card>
  );

  return <MyRow key={contractId} className="add-file-tab-container" children={megaState.auditAddFile ? formView : buttonView} />;
};

export default AddFileTabList;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setClientEmailVerified } from '../../store/commonReducer';
import { translation } from 'common_constants/translation';
import { NOTIFICATION } from 'common_constants/modals';
import { request, warn, success } from '../../tools';
import RHForm from '../../components/RHForm';
import './confirmEmail.scss';

const ConfirmEmail = ({ email, setLoading, changeEmail, setModal, setNewEmail }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const lang = useSelector((state) => state.common.language);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const verificationCode = params.get('verificationCode');
    if (location.search.includes('emailActivated=true')) {
      onFinish({ clientInputCode: verificationCode });
    }
  }, []);
  const onFinish = async (values) => {
    const { clientInputCode } = values;

    setLoading(true);

    if (changeEmail) {
      request(
        '/auth/confirmChangeEmail',
        { token: clientInputCode, email },
        (res) => {
          setLoading(false);
          if (res.emailExists) return warn(translation.regStep1ConfirmWarn1[lang], ' ');
          dispatch(setClientEmailVerified({ email, emailVerified: true }));
          success(translation.success[lang], translation.regStep1ConfirmSuccess[lang]);
          setModal(false);
          setNewEmail('');
        },
        () => {
          warn(translation.confirmChangePhoneWarn2[lang], ' ');
          setLoading(false);
        },
      );
    } else {
      request(
        '/auth/emailVerify',
        { token: clientInputCode },
        (res) => {
          setLoading(false);
          // dispatch(setModal({ name: NOTIFICATION, data: { email } }));
          dispatch(setClientEmailVerified({ email, emailVerified: true }));
          success(translation.success[lang], translation.regStep1ConfirmSuccess[lang]);
        },
        () => {
          warn(translation.confirmChangePhoneWarn2[lang], ' ');
          setLoading(false);
        },
      );
    }
  };

  const resetEmail = () => {
    setLoading(true);

    request('/auth/resetClientEmail', {}, (res) => {
      setLoading(false);
      dispatch(setClientEmailVerified({ email: null, emailVerified: false }));
    });
  };

  return (
    <div className="confirm-email">
      <span>
        {translation.regStep1Confirm1[lang]}
        <br />
        <br />
        {translation.regStep1Confirm2[lang]} <strong>{email}</strong> {translation.regStep1Confirm3[lang]}
      </span>
      <RHForm onFinish={onFinish}>
        {[
          {
            name: 'clientInputCode',
            rHFormOptions: {
              required: translation.confirmChangePhoneRules1[lang],
              pattern: { value: /^.{6}$/, message: translation.confirmChangePhoneRules2[lang] },
            },
            options: { label: translation.regStep1Confirm4[lang], className: 'confirmEmail-item', style: { maxWidth: 80 } },
          },
          {
            name: 'submit',
            options: { value: translation.sendButton[lang], className: 'invite-submit-btn' },
          },
        ]}
      </RHForm>
      {!changeEmail && (
        <>
          {translation.regStep1Confirm5[lang]} <a onClick={resetEmail}> {translation.regStep1Confirm6[lang]} </a>
        </>
      )}
    </div>
  );
};

export default ConfirmEmail;

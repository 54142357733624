import React from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

import { Button } from '..';

const UnverifiedPhoneOverlay = ({ setShowPhoneVerify, showPhoneVerify }) => {
  const lang = useSelector((state) => state.common.language);

  const handleClickConfirmButton = () => {
    setShowPhoneVerify(true);
  };

  return (
    <div className="unverified-phone-overlay">
      <div>
        <p>{translation.chatUnverifiedPhoneOverlay[lang]}</p>
        {!showPhoneVerify && <Button onClick={handleClickConfirmButton}>{translation.confirmButton[lang]}</Button>}
      </div>
    </div>
  );
};

export default UnverifiedPhoneOverlay;

import { useLayoutEffect, useRef } from 'react';
import './Confetti.scss';
import { ConfettiCanvas } from './ConfettiCanvas';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setConfetti } from '../../store/uiReducer';

const Confetti = () => {
  const canvasRef = useRef(null);
  const canvasState = useSelector((state) => state.ui.confetti);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (!canvasState?.visible) return;
    let timeout;
    if (canvasState?.timeEnd) {
      timeout = setTimeout(() => {
        dispatch(setConfetti({ visible: false }));
      }, canvasState?.timeEnd + 1000);
    }

    const canvas = new ConfettiCanvas(canvasRef.current, canvasState?.timeEnd);
    canvas.update();
    return () => {
      canvas.destroy();
      clearTimeout(timeout);
    };
  }, [canvasState]);

  if (!canvasState?.visible) return;

  return <canvas ref={canvasRef} className="confettiCanvas" />;
};

export default Confetti;

import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { ZahistCred } from 'common_components';
import { ROUTES } from 'common_constants/routes';
import { warn } from '../../tools';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';
import Spin from '../../components/Spin';
import RHForm from '../../components/RHForm';

const ClientConfirmNewPassword = () => {
  const [loading, setLoading] = useState(false);
  const [ifPassChanged, setIfPassChanged] = useState(false);
  const { code, email } = useParams();
  const lang = useSelector((state) => state.common.language);

  const onFinish = (data) => {
    const { updatedPassword } = data;
    setLoading(true);

    fetch(process.env.REACT_APP_API + '/auth/setNewPass', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, code, updatedPassword }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setLoading(false);
          setIfPassChanged(true);
          return;
        }

        warn(res.error, ' ');
        setLoading(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="clientLogin_page">
      <div className="auth_page" style={{ margin: '8px' }}>
        <div className="menu">
          <div className="menu-wrapper">
            <MailOutlined />
            <p className="title"> {translation.loader[lang]}</p>
          </div>
        </div>

        <Spin spinning={loading}>
          {ifPassChanged ? (
            <div style={{ textAlign: 'center' }}>
              <div>{translation.clientConfirmNPSuccess[lang]}</div>
              <Link to={ROUTES.CLIENT_LOGIN}>{translation.enterLink[lang]}</Link>
            </div>
          ) : (
            <RHForm onFinish={onFinish} className="email-form">
              {[
                {
                  name: 'updatedPassword',
                  rHFormOptions: {
                    required: translation.requiredField[lang],
                  },
                  options: { label: translation.clientConfirmNPLabel[lang] },
                },
                {
                  name: 'submit',
                  options: { value: translation.clientConfirmNPUpdatePBtn[lang] },
                },
              ]}
            </RHForm>
          )}
        </Spin>
        <ZahistCred />
      </div>
    </div>
  );
};

export default ClientConfirmNewPassword;

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { CLIENT_TOKEN } from 'common_constants/business';
import { ROUTES, OPEN_ROUTES } from 'common_constants/routes';
import { ADD_CLIENT_EMAIL, FIRST_LOGIN_OF_THE_CLIENT, FIRST_LOGIN_AUTO_PAY } from 'common_constants/modals';

import { setClientEmailVerified, setModal, setMissingEmail, setClientPhoneVerified, setClientPolicyConfirmed } from '../../store/commonReducer';
import { clientLogOut } from '../../tools';
import { CheckClientInfo } from '../../tools/hooks';

import checkQueryParams from './checkQueryParams';

export default function AuthControl() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);

  const HotlineMeetInfo = pathname.match(/^\/.{24}$/);
  const CertificatePage =
    pathname.match(/^\/certificate\/.{10}$/) ||
    pathname.match(/^\/certificate\/.{9}$/) ||
    pathname.match(/^\/cert\/.{10}$/) ||
    pathname.match(/^\/cert\/.{9}$/);
  const CertificateLocated = CertificatePage ? ROUTES.ANONIM_CERTIFICATE_ : null;
  const LawyerCV = pathname.match(/^\/cv\/./);

  const openRoute = OPEN_ROUTES.includes(pathname) || HotlineMeetInfo || CertificateLocated || LawyerCV;

  // const clientSequredPage = [ROUTES.CLIENT_MENU, ROUTES.CLIENT_HOME, ROUTES.PARTNER_HOME, ROUTES.CLIENT_INSURANCE_DRIVER].includes(pathname);
  // const clientSequredPage = !OPEN_ROUTES.includes(pathname);

  const clientSequredPage = !OPEN_ROUTES.find((route) => {
    if (route.includes('/:') && route !== '/:hash') {
      const routeWithoutParams = route.split('/:')[0];
      return pathname.startsWith(routeWithoutParams);
    }
    return pathname === route;
  });

  const checkClientToken = () => {
    if (!clientAuth) return;

    const token = window.localStorage.getItem(CLIENT_TOKEN);

    fetch(process.env.REACT_APP_API + '/auth/checkClientToken', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) {
          clientLogOut();
          return;
        }

        dispatch(
          setClientEmailVerified({
            email: res.email,
            emailVerified: res.emailVerified,
          }),
        );
        dispatch(
          setClientPhoneVerified({
            ph: res.ph,
            phoneVerified: res.phoneVerified,
            verificationSmsSendTime: res.verificationSmsSendTime,
          }),
        );
        dispatch(setClientPolicyConfirmed({ policyConfirmed: res.policyConfirmed }));
        if (res.phoneVerified && res.policyConfirmed && !res.emailVerified) {
          dispatch(setModal({ name: ADD_CLIENT_EMAIL }));
          dispatch(setMissingEmail(true));
        }
      })
      .catch(clientLogOut);
  };

  const _useClientInfo = () => {
    if (!clientAuth || !clientAuth._id) return;
    CheckClientInfo(dispatch);
  };

  useEffect(() => {
    if (clientAuth) checkClientToken();
  }, []);

  checkQueryParams(clientAuth, history);

  // Redirect from Login pages

  useEffect(() => {
    if (clientAuth && (pathname === ROUTES.CLIENT_LOGIN || pathname === ROUTES.CLIENT_REGISTRATION)) {
      const makeAppointment = localStorage.getItem('makeAppointment');
      if (makeAppointment) {
        localStorage.removeItem('makeAppointment');
        history.push(ROUTES.CLIENT_HOTLINE);
      } else {
        history.push(ROUTES.CLIENT_HOME);
      }
      _useClientInfo();
    }
  }, [pathname, clientAuth, clientInfo]);

  useEffect(() => {
    if (!clientAuth?._id || !clientInfo?._id) return; //* Блок загальний

    if (!clientAuth?.emailVerified || !clientAuth?.phoneVerified) return; //* Блок для 2 модалок верифікації

    if (clientInfo?.contracts_for_questionnaire?.[0]) return; //* Блок для 2 анкет

    if (sessionStorage.getItem('modalFirst') === 'true') return; //* Блок останньої появи модалки під час сесії
    dispatch(setModal({ name: FIRST_LOGIN_AUTO_PAY }));
  }, [clientAuth, clientInfo]); //* the first launch of the application by the client

  if (!openRoute && ((!clientAuth && clientSequredPage) || ROUTES.HOME === pathname)) {
    sessionStorage.removeItem('modalFirst');
    history.push(ROUTES.CLIENT_LOGIN);
  }

  if (openRoute) return null;

  return null;
}

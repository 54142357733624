import './Upload.scss';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useToaster } from '../../tools/hooks';
import { translation } from 'common_constants/translation';
import { getBase64 } from '../../tools';
import { useRef } from 'react';

const Upload = ({ setUploadFile, isLoading, setIsLoading, setImageUrl, imageUrl, lang }) => {
  const inputRef = useRef();
  const { error } = useToaster();

  const validateFile = (file) => {
    const isJpg = file.type === 'image/jpeg';
    if (!isJpg) {
      return { error: translation.clientProfileError1[lang] };
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      return { error: translation.clientProfileError2[lang] };
    }
    return { success: true };
  };

  function handleChange(e) {
    setIsLoading(true);
    const file = e.target.files[0];
    if (!file) return;
    const res = validateFile(file);
    if (res.error) {
      error(res.error);
      setUploadFile(null);
      setIsLoading(false);
      return;
    }
    getBase64(file, (url) => {
      setImageUrl(url);
    });
    setUploadFile(file);
    setIsLoading(false);
  }
  return (
    <form
      onClick={() => {
        inputRef.current.click();
      }}
      className="client-avatar-upload"
    >
      {imageUrl ? (
        <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
      ) : (
        <div>
          {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            {translation.uploadBtn[lang]}
          </div>
        </div>
      )}
      <input ref={inputRef} accept="image/jpeg" className="client-avatar-upload-input" hidden type="file" onChange={handleChange} />
    </form>
  );
};
export default Upload;

import { translation } from 'common_constants/translation';
import { useDispatch, useSelector } from 'react-redux';

import { RHForm } from '../../components';
import { setClientEmailVerified } from '../../store/commonReducer';
import { request, warn, success } from '../../tools';

const SendEmail = ({ setLoading, setNewEmail }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);

  const onFinish = async ({ email }) => {
    setLoading(true);

    if (setNewEmail) {
      request('/auth/changeClientEmail', { email }, (res) => {
        setLoading(false);
        if (res.emailExists) return warn(translation.regStep1ConfirmWarn1[lang], ' ');
        setNewEmail(email);
        success(translation.success[lang], translation.letterSentMessage[lang]);
      });
      return;
    }

    request('/auth/emailForConfirmation', { email }, (res) => {
      setLoading(false);
      if (res.emailExists) return warn(translation.regStep1ConfirmWarn1[lang], ' ');
      dispatch(setClientEmailVerified({ email, emailVerified: res.emailVerified }));
      success(translation.success[lang], translation.letterSentMessage[lang]);
    });
  };

  return (
    <>
      <div className="text-content-wrapper">
        {translation.regStep1Descr[lang].split('\n').map((sentence, index) => (
          <p key={index}>{sentence}</p>
        ))}
      </div>

      <RHForm onFinish={onFinish}>
        {[
          {
            name: 'email',
            rHFormOptions: {
              required: translation.clientCPEmailMessage2[lang],
              pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: translation.clientCPEmailMessage1[lang] },
            },
            options: { label: 'Email', 'aria-label': 'input email client', autoComplete: 'email' },
          },
          {
            name: 'submit',
            options: { value: translation.sendButton[lang], className: 'invite-submit-btn' },
          },
        ]}
      </RHForm>
    </>
  );
};

export default SendEmail;

import { ROUTES } from 'common_constants/routes';

const checkQueryParams = (clientAuth, history) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('makeAppointment')) {
    if (clientAuth) history.push(ROUTES.CLIENT_HOTLINE);
    else localStorage.setItem('makeAppointment', 'true');
  }
};

export default checkQueryParams;

import './ThanksForLawyer.scss';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { request, success, error } from '../../tools';
import ModalWindow from '../ModalWindow';
import reactNativeService from '../../tools/reactNativeService';
import { CheckClientInfo } from '../../tools/hooks';
import { translation } from 'common_constants/translation';
import { PAYMENT_STATUS } from 'common_constants/business';
import InputNew from '../../components/InputNew';
import BalanceNewButton from '../../components/Balance/BalanceNewButton';

const ThanksForLawyer = ({ open, setOpen, clientId, ph, arrayPayment = [], title, placeholder, buttonText, paymentType, hotlineId, redirectUrl }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const [amount, setAmount] = useState();

  const handleClickModalPaymentButton = () => {
    if (!amount || amount < 1) return;
    const onError = (_, __, axiosError) => error('Помилка оплати!', axiosError.error);
    const body = { clientId, ph, amount, hotlineId };

    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          CheckClientInfo(dispatch);
          success(translation.ClientBPMSuccess1[lang]);
        }, 1000);
      }
      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              success(translation.ClientBPMSuccess1[lang]);
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              error(translation.addInsuranceError[lang]);
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
      }
    };
    request('/payment/mono/thanksForLawyer/link', body, onSuccess, onError);
    setAmount('');
    setOpen(false);
  };

  const handleClick = (index) => {
    const amount = arrayPayment[index];
    setAmount(amount);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <ModalWindow title={title} modal={open} setModal={setOpen}>
      <div className="balance-pay-content">
        <InputNew
          onKeyDown={handleKeyPress}
          className={'balance-pay-content-input'}
          type="number"
          value={amount || ''}
          onChange={(e) => setAmount(e.target.value)}
          placeholder={placeholder}
        />
        {/* <input className="balance-pay-content-input" type="number" value={amount} onChange={(e) => setAmount(e.target.value)} /> */}

        <div className="balance-pay-content-values">
          {arrayPayment.map((item, i) => (
            <div key={i} onClick={() => handleClick(i)}>
              ₴{item}
            </div>
          ))}
        </div>
        <BalanceNewButton onClick={handleClickModalPaymentButton} buttonText={buttonText} arrow={false} />
      </div>
    </ModalWindow>
  );
};

export default ThanksForLawyer;

import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ModalWindow from '../ModalWindow';
import { INSURANCE_PERIOD, PAYMENT_STATUS, CURRENCIES } from 'common_constants/business';
import Select from 'react-select';
import dayjs from 'dayjs';
import { error, request, success, warn } from '../../tools';
import InputNew from '../../components/InputNew';
import { translation } from 'common_constants/translation';
import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';

import BalanceNewButton from '../../components/Balance/BalanceNewButton';
import { CheckClientInfo } from '../../tools/hooks';
import reactNativeService from '../../tools/reactNativeService';
import Description from '../../pages/Insurances/Description';

import 'rc-switch/assets/index.css';
import { Collapse } from '../../components';

const AddInsurance = ({ visible, setInsuranceModal, getInsurances, insuranceTypes, type = 0 }) => {
  const dispatch = useDispatch();

  const [insuranceType, setInsuranceType] = useState(null);
  const [insurancePeriod, setInsurancePeriod] = useState(0);
  const [insuranceState, setInsuranceState] = useState({
    type: '',
    payment: 1,
  });
  const [insurancePrice, setInsurancePrice] = useState(0);
  const [autoPay, setAutoPay] = useState(false);
  const [clientNameForInsurance, setClientNameForInsurance] = useState('');
  const lang = useSelector((state) => state.common.language);
  const [disabled, setDisabled] = useState(false);
  const [isVisibleDescription, setIsVisibleDescription] = useState(false);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const currentCurrency = clientInfo?.currency ?? CURRENCIES.UAH;

  const handlerSuccessForAddInsurance = () => {
    CheckClientInfo(dispatch);
    getInsurances();
    success(translation.success[lang], translation.addInsuranceSuccess[lang]);
    setDisabled(false);
    setInsuranceModal(false);
  };

  const handleSwitchAutoPay = () => {
    success(translation.autoPayModalSuccess[lang]);
    setAutoPay(!autoPay);
  };

  const handleClick = () => {
    if (insurancePeriod === 0) return warn(translation.addInsuranceWarn[lang], translation.addInsuranceWarn1[lang]);
    if (insuranceState.type === '') return warn(translation.addInsuranceWarn[lang], translation.addInsuranceWarn2[lang]);
    if (clientNameForInsurance === '') return warn(translation.addInsuranceWarn[lang], translation.addInsuranceWarnName[lang]);

    const body = {
      ...insuranceState,
      amount: insurancePrice,
      period: +insurancePeriod,
      clientNameForInsurance,
      autoPay: autoPay,
      currency: currentCurrency,
    };

    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location.href = res.checkoutUrl;
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          handlerSuccessForAddInsurance();
        }, 1000);
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              clearInterval(timerId);
              handlerSuccessForAddInsurance();
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              getInsurances();
              error(translation.addInsuranceError[lang]);
              setDisabled(false);
              setInsuranceModal(false);
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
        return;
      }

      handlerSuccessForAddInsurance();
    };

    const onError = (_, __, axiosError) => {
      error(translation.addInsuranceError[lang], axiosError.error);
      setDisabled(false);
    };

    setDisabled(true);
    request('/payment/mono/insurance/link', body, onSuccess, onError);
  };

  const calculateBenefit = () => {
    const minPeriod = Object.keys(insuranceType?.minimalPrice)[0];
    const minPrice = insuranceType.minimalPrice[minPeriod];
    const benefit = (insurancePeriod / minPeriod) * minPrice - insurancePrice;
    return benefit;
  };

  const changeInsurancePrice = (e) => {
    setInsurancePrice(e.target.value.replace(/^0+/, ''));
  };

  const changeInsuranceName = (e) => {
    setClientNameForInsurance(e.target.value);
  };

  const findInsuranceType = () => {
    const insuranceType = insuranceTypes.find((el) => el.i === insuranceState.type);
    return insuranceType;
  };

  useEffect(() => {
    if (insuranceState.type !== '') {
      setInsuranceType(findInsuranceType());
    }
  }, [insuranceState.type]);

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <ModalWindow className="add_insurance_modal" title={translation.addInsuranceTitle[lang]} modal={visible} setModal={setInsuranceModal}>
      <div>
        <h4 style={{ marginBottom: 3, marginTop: 0, marginLeft: 10 }}>{translation.addClientNameForInsurance[lang]}</h4>
        <InputNew
          type="text"
          style={{ height: 38 }}
          placeholder={translation.addClientNameForInsurancePlaceholder[lang]}
          onChange={changeInsuranceName}
          value={clientNameForInsurance}
        />
      </div>
      <div>
        <h4 style={{ marginBottom: 3, marginLeft: 10 }}>{translation.addInsuranceTypeTitle[lang]}</h4>
        <Select
          isSearchable={false}
          placeholder={translation.addInsuranceTypePlaceholder[lang]}
          onChange={(c) => setInsuranceState({ ...insuranceState, type: c.value })}
          options={insuranceTypes.map((key) => ({
            value: key.i,
            label: key.name,
          }))}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 32,
              paddingLeft: 5,
              paddingRight: 5,
            }),
          }}
        />
        {/* <h3 style={{ margin: '8px 0 0 24px' }}>Опис:</h3> */}

        {insuranceState.type && (
          <Collapse style={{ marginTop: 20 }}>
            <Collapse.Panel header={translation.descriptionOfService[lang]}>
              <Description insuranceType={insuranceType} lang={lang} />
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
      <div>
        <h4 style={{ marginBottom: 3, marginLeft: 10 }}>{translation.addInsurancePeriodTitle[lang]}</h4>
        <Select
          defaultValue={6}
          isSearchable={false}
          placeholder={translation.addInsurancePeriodPlaceholder[lang]}
          onChange={(c) => {
            setInsurancePeriod(c.value);
            setInsurancePrice(+insuranceType?.minimalPrice?.[c.value] || 0);
          }}
          options={Object.keys(INSURANCE_PERIOD).map((e) => ({
            value: +e,
            label: INSURANCE_PERIOD[e],
          }))}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 32,
              paddingLeft: 5,
              paddingRight: 5,
            }),
          }}
        />
      </div>
      <div style={{ marginBottom: 15, paddingBottom: 5, position: 'relative' }}>
        <h4 style={{ marginBottom: 3, marginLeft: 10 }}>{translation.addInsuranceText1[lang]}</h4>
        <InputNew
          onKeyDown={handleKeyPress}
          type="number"
          style={{ height: 38 }}
          placeholder={insuranceType?.minimalPrice?.[insurancePeriod]}
          onChange={changeInsurancePrice}
          value={insurancePrice}
        />
        {insurancePrice < insuranceType?.minimalPrice?.[insurancePeriod] && (
          <p style={{ color: 'red', textAlign: 'center', margin: 0, position: 'absolute', width: '100%', fontSize: 13 }}>
            {translation.addInsuranceText2[lang]} {insuranceType.minimalPrice[insurancePeriod]}
            {CURRENCIES[currentCurrency].symbol}
          </p>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <p style={{ marginLeft: 10, marginTop: 0 }}>
          {translation.addInsuranceCost[lang]}
          <b>
            {insurancePrice}
            {CURRENCIES[currentCurrency].symbol}
          </b>
        </p>
        {insuranceType?.minimalPrice?.[insurancePeriod] === +insurancePrice && (
          <p style={{ marginTop: 0, marginRight: 10, color: 'red' }}>
            <b>
              {translation.addInsuranceProfit[lang]} {calculateBenefit()}
              {CURRENCIES[currentCurrency].symbol}
            </b>
          </p>
        )}
      </div>
      <div style={{ marginLeft: 10, marginBottom: 10 }}>
        {translation.autoPayModalText4[lang]}
        <Switch style={{ marginLeft: 10 }} checked={autoPay} onChange={handleSwitchAutoPay} />
      </div>
      <BalanceNewButton
        disabled={disabled || +insurancePrice < insuranceType?.minimalPrice?.[insurancePeriod]}
        onClick={handleClick}
        buttonText={translation.buyInsuranceBtn[lang]}
        arrow={false}
      />
    </ModalWindow>
  );
};

export default AddInsurance;

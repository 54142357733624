import React from 'react';
import Select from '../SelectNew';
import { JOBS } from 'common_constants/business';
import { useForm } from 'react-hook-form';

/**
 * prop list should be either:
 * 1. Array of strings: ['option1', 'option2', ...]
 * 2. Array of objects: [{ [valueName]: string, [labelName]: string }, ...]
 */

const SearchSelect = ({ name = 'select', className, list, value, onChange, disabled, placeholder, valueName, labelName }) => {
  const {control} = useForm()

  const options = Array.isArray(list) ? list?.map((i, index) => ({
    value: i[valueName] || index,
    label: i[labelName] || i
  })) : Object.keys(JOBS).map((e) => ({
    value: Number(e),
    label: JOBS[e]
  }))

  return (
    <Select
      showSearch
      // filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      className={className}
      defaultValue={value === undefined ? '' : value}
      onChange={(v) => {
        onChange(v);
      }}
      disabled={disabled}
      name={name}
      options={options}
      control={control}
      placeholder={placeholder}
    />      
  );
};

export default SearchSelect;
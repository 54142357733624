import React from 'react';
import './SimpleRating.scss';

const SimpleRating = ({ setRating }) => {
  const starsAmount = [5, 4, 3, 2, 1];

  return (
    <div className="simple-rating">
      <div className="simple-rating-items">
        {starsAmount.map((num) => (
          <React.Fragment key={num}>
            <input id={'simple-rating-' + num} type="radio" value={num} onChange={setRating} className="simple-rating-item" name="simple-rating" />
            <label htmlFor={'simple-rating-' + num} className="simple-rating-label"></label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SimpleRating;

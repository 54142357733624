import './SectionName.scss';

const SectionName = ({ text }) => {
  return (
    <div className="section-text">
      <div>{text}</div>
    </div>
  );
};

export default SectionName;

import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

const ToastItem = ({ toast, remove }) => {
  const [isVisible, setIsVisible] = useState(true);
  const handleRemove = () => {
    setIsVisible(false);
    setTimeout(() => {
      remove(toast.id);
    }, 500);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
      setTimeout(() => {
        handleRemove();
      }, 500);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className={clsx('toast-container', toast.variant, isVisible ? 'visible' : 'hidden')}>
      <div className="toast-header">
        <div className="toast-header-data">
          <div className={clsx('icon', toast.variant)}>
            {toast.variant === 'success' && <CheckOutlined />}
            {toast.variant === 'error' && <WarningOutlined />}
            {toast.variant === 'info' && <InfoCircleOutlined />}
            {toast.variant === 'warning' && <ExclamationCircleOutlined />}
          </div>
          <div>{toast.title}</div>
        </div>
        <div onClick={handleRemove} className={clsx('icon-close')}>
          <CloseOutlined />
        </div>
      </div>
      {toast.description && (
        <div className="toast-content">
          <div>{toast.description}</div>
        </div>
      )}
    </div>
  );
};
export default ToastItem;

import React, { useEffect, useState } from 'react';
import { getFormattedDateWithRelativeDays } from 'common_components';
import { useDispatch, useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { RED_BUTTON } from 'common_constants/business';
import dayjs from 'dayjs';

import UserAvatar from '../../components/UserAvatar';
import { setModal, setContractChat } from '../../store/commonReducer';
import { CHAT_FOR_CONTRACT } from 'common_constants/modals';
import { request, error } from '../../tools';

const ChatsItem = ({ data }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const contractChats = useSelector((state) => state.common.contractChats);
  const users = useSelector((state) => state.common.usersData);

  const [lastMessage, setLastMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const curator = users?.find((user) => user._id === data?.us) || null;
  const contractId = data._id;

  const getLastMessage = () => {
    setLoading(true);

    const findCurrentChat = contractChats?.find((item) => item.chatId === contractId) || null;

    if (findCurrentChat) {
      if (findCurrentChat?.messages && findCurrentChat?.messages?.length !== 0) {
        if (findCurrentChat.messages?.slice(-1)[0]?.message) {
          setLastMessage(findCurrentChat.messages.slice(-1)[0].message);
        } else {
          setLastMessage(translation.file[lang]);
        }
      } else {
        setLastMessage(translation.notMessages[lang]);
      }
      setLoading(false);
      return;
    }

    request(
      '/chatPrivate/getchatClient',
      { documentId: contractId },
      ({ data }) => {
        dispatch(setContractChat({ chatId: contractId, data: data }));
        if (data && data.length !== 0) {
          if (data.slice(-1)[0].message) {
            setLastMessage(data?.slice(-1)[0]?.message);
          } else {
            setLastMessage(translation.file[lang]);
          }
        } else {
          setLastMessage(translation.notMessages[lang]);
        }
        setLoading(false);
      },
      error,
    );
  };

  useEffect(() => {
    getLastMessage();
  }, [contractChats]);

  const openChat = () => {
    dispatch(
      setModal({
        name: CHAT_FOR_CONTRACT,
        data: {
          contractId: contractId,
          contractTitle:
            translation.clientContractInfoChat[lang] +
            ' ' +
            (data?.n
              ? '«' + data.n + '»'
              : data?.cn
              ? data.cn === RED_BUTTON.value
                ? '«' + RED_BUTTON.label + '»'
                : '«' + data.cn + '»'
              : `№${data?.i}`),
          curator: curator?.name,
        },
      }),
    );
  };

  return (
    <div className="chats" onClick={openChat}>
      <UserAvatar size={50} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }} user={curator} />
      <div className="info">
        <span className="name">
          {data?.n
            ? `«${data.n}»`
            : data?.cn
            ? data.cn === RED_BUTTON.value
              ? `«${RED_BUTTON.label}»`
              : `«${data.cn}»`
            : `${translation.contractItem[lang]} №${data?.i}`}
        </span>
        {loading ? <div className="spinner-opacity"></div> : <span className="message">{lastMessage}</span>}
      </div>
    </div>
  );
};

export default ChatsItem;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { TENDER_TERMS } from 'common_constants/business';
import { ROUTES } from 'common_constants/routes';

import { request, success, error } from '../../../tools';
import { setTenders } from '../../../store/commonReducer';
import { SelectNew, Spin } from '../../../components';
import { translation } from 'common_constants/translation';

import './CreateTender.scss';

const CreateTender = () => {
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const tendersList = useSelector((state) => state.common.tenders);
  const lang = useSelector((state) => state.common.language);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,

    reset,
    control,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    const body = {
      type: 0,
      ...data,
      phone: clientInfo.ph,
      name: clientInfo.n,
    };
    setLoading(true);
    const onSuccess = (res) => {
      success(translation.createTenderSuccess[lang]);
      setLoading(false);
      dispatch(setTenders([res.tender, ...tendersList]));
      reset();
      history.push(ROUTES.CLIENT_TENDERS);
    };
    const onError = (res) => {
      error(res.error);
      setLoading(false);
    };
    request('/tenders/add', body, onSuccess, onError);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Spin spinning={loading} tip={translation.loader[lang]}>
      <div className="tenders-page">
        <form className="tender-form" onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="problem">{translation.createTenderProblem[lang]}</label>
          <textarea
            placeholder={translation.createTenderProblem[lang]}
            required
            id="problem"
            rows={3}
            className="input-new tender-form__item"
            {...register('problem', { required: true })}
          />
          <label htmlFor="task">{translation.createTenderTask[lang]}</label>
          <textarea
            placeholder={translation.createTenderTask[lang]}
            required
            id="task"
            rows={3}
            className="input-new tender-form__item"
            {...register('task', { required: true })}
          />
          <label htmlFor="sum">{translation.createTenderSum[lang]}</label>
          <input
            required
            id="sum"
            className="input-new tender-form__item"
            type="number"
            onKeyDown={handleKeyPress}
            placeholder={translation.createTenderSumPlaceholder[lang]}
            {...register('sum', { required: true })}
          />
          <label htmlFor="term">{translation.createTenderTerm[lang]}</label>
          <SelectNew
            required
            inputId="term"
            placeholder={translation.term[lang]}
            className="tender-form__select"
            name="term"
            control={control}
            options={TENDER_TERMS}
          />
          <input value={translation.send[lang]} className="tender-form__submit" type="submit" />
        </form>
      </div>
    </Spin>
  );
};

export default CreateTender;

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MOB_RESOLUTION } from 'common_constants/ui';
import { USER_TOKEN_TYPE, WEBVIEW_MESSAGE_TYPES } from 'common_constants/business';
import * as MODALS from 'common_constants/modals';
import io from 'socket.io-client';

import { setConfetti, setMob } from '../store/uiReducer';
import { request, error } from './index';
import {
  setAppPrepared,
  setClientInfo,
  setFiliiList,
  setNewsFromSite,
  setWebinars,
  setTopFilials,
  setLanguage,
  setUsersData,
  setSocket,
  addNewMessageToContractChat,
  deleteContractChatMessage,
  editContractMessage,
  setBiometricEnabled,
  setModal,
} from '../store/commonReducer';
import reactNativeService from './reactNativeService';
import { addToast } from '../store/toastReducer';

const CheckClientInfo = (dispatch) => {
  request(
    '/clients/getClientInfo',
    null,
    (req) => {
      const { data } = req;
      if (!data) return;
      dispatch(setClientInfo(data));
      dispatch(setAppPrepared(true));
    },
    error,
  );

  request('/topFilials/getForClient', {}, ({ topFilialsRating }) => {
    dispatch(setTopFilials(topFilialsRating));
  });

  request(
    '/webinars/getWebinarsForClient',
    {},
    (req) => {
      if (req.status) {
        dispatch(setWebinars(req.data));
      }
    },
    error,
  );

  request(
    '/websiteNews/getNewsInReactClient',
    { language: 'ua' },
    (req) => {
      if (req.status) {
        dispatch(setNewsFromSite(req.data));
      }
    },
    error,
  );
}; //* AuthControl and hook.js work together in App.js - Authentication / Refresh accordingly.

const useControllView = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const handleResize = () => {
        dispatch(setMob(window.innerWidth < MOB_RESOLUTION));
      };

      window.screen?.orientation?.lock?.();
      window.screen?.lockOrientation?.('orientation');

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    } catch (e) {
      console.error(e);
    }
  }, []);
};

const useClientInfo = () => {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const users = useSelector((state) => state.common.users);
  const requested = useRef(false);
  const dispatch = useDispatch();

  const getClientInfo = () => {
    if (!clientAuth._id) return;
    CheckClientInfo(dispatch);
  };

  const getFilii = () => {
    const isPartial = document.location.pathname.match(/^\/.{24}$/);

    request.get('/filii', ({ data }) => {
      dispatch(setFiliiList(data));
      isPartial && dispatch(setAppPrepared(true));
    });
  };

  const getUsers = () => {
    if (!users) {
      request('/auth/getCrmUsersForClient', {}, ({ data }) => {
        dispatch(setUsersData(data));
      });
    }
  };

  useEffect(() => {
    getFilii();

    if (!clientAuth) return;
    if (requested.current) return;
    requested.current = true;
    getClientInfo();
    getUsers();
  }, [clientAuth._id]);
};

const useLanguage = () => {
  const dispatch = useDispatch();
  let currentLang = useSelector((state) => state.common.language);

  useEffect(() => {
    const localStoredLang = localStorage.getItem('language');

    if (localStoredLang && localStoredLang !== currentLang) {
      dispatch(setLanguage(localStoredLang));
      reactNativeService.sendMessageToWebview({ language: localStoredLang });
    }
  }, [dispatch]);
};

const useSetLanguageByIpAddress = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const setLanguageByIp = async () => {
      const languageFromStorage = localStorage.getItem('language');

      if (!languageFromStorage) {
        const germanSpeakingCountries = ['DE', 'AT', 'CH', 'LU', 'LI', 'BE', 'NL'];
        const UKRAINE = 'UA';

        const countryResponse = await fetch(`https://ipinfo.io/json`);

        const { country } = await countryResponse.json();

        let language = 'en';

        if (germanSpeakingCountries.includes(country)) {
          language = 'de';
        } else if (country === UKRAINE) {
          language = 'ua';
        }

        dispatch(setLanguage(language));
        localStorage.setItem('language', language);
      }
    };

    setLanguageByIp();
  }, [dispatch]);
};

const useMessage = () => {
  const [messages, setMessages] = useState([]);
  const [nextZIndex, setNextZIndex] = useState(100);

  const showMessage = (type, content) => {
    setMessages((prevMessages) => [
      {
        id: Date.now(),
        type,
        content,
        zIndex: nextZIndex,
      },
      ...prevMessages,
    ]);
    setNextZIndex(nextZIndex + 1);
  };

  return { messages, showMessage };
};

const useAuthData = () => {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);

  useEffect(() => {
    const data = {
      email: clientAuth?.email,
      _id: clientAuth?._id,
      ph: clientAuth?.ph,
      n: clientInfo?.n,
      token: localStorage.getItem('clientAccessToken'),
    };
    reactNativeService.sendMessageToWebview({ type: WEBVIEW_MESSAGE_TYPES.USER_AUTH_DATA, data: data });
  }, [clientAuth?._id, clientInfo?._id]);
};

const useConnectSocket = () => {
  const dispatch = useDispatch();
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const socket = useSelector((state) => state.common?.socket);

  const connectSocket = (userToken) => {
    if (window?.ReactNativeWebView) return;
    const socket = io(process.env.REACT_APP_API, {
      query: {
        token: userToken,
        tokenType: USER_TOKEN_TYPE.client,
      },
    });

    return socket;
  };

  useEffect(() => {
    if (window?.ReactNativeWebView) return;
    const token = localStorage.getItem('clientAccessToken') ?? clientAuth?.token;

    if (token) {
      const currentSocket = connectSocket(token);

      dispatch(setSocket(currentSocket));

      return () => currentSocket.close();
    } else {
      if (socket) {
        socket.close();
        dispatch(setSocket(null));
      }
    }
  }, [clientAuth?._id]);
};

const useListenMessages = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.common.socket);

  useEffect(() => {
    if (!socket) return;

    socket.on('newMessage', ({ message, chatId }) => {
      dispatch(addNewMessageToContractChat({ chatId: chatId, data: message }));
    });

    socket.on('removeMessage', ({ chatId, messageId }) => {
      dispatch(deleteContractChatMessage({ chatId, messageId }));
    });

    socket.on('editMessage', ({ chatId, messageId, newMessageText }) => {
      dispatch(editContractMessage({ chatId, messageId, newMessageText }));
    });

    return () => {
      socket?.off('newMessage');
      socket?.off('removeMessage');
      socket?.off('editMessage');
    };
  }, [socket]);
};
const useToaster = () => {
  const dispatch = useDispatch();
  const createToast = (toast) => {
    dispatch(
      addToast({
        id: Date.now(),
        ...toast,
      }),
    );
  };
  const toast = {
    success: (title) => createToast({ title: title, variant: 'success' }),
    error: (title) => createToast({ title: title, variant: 'error' }),
    custom: (data) => createToast(data),
  };

  return toast;
};

const useListenWebviewMessages = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!window?.ReactNativeWebView) return;

    const messageListener = (event) => {
      try {
        const receivedData = JSON.parse(event?.data);

        if (receivedData?.type === WEBVIEW_MESSAGE_TYPES.BIOMETRIC_INITIAL) {
          dispatch(setBiometricEnabled(receivedData.data.value));
        }
      } catch (error) {
        console.log(error);
      }
    };

    window.addEventListener('message', messageListener);
    document.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
      document.removeEventListener('message', messageListener);
    };
  }, []);
};

const useCheckForGift = () => {
  const clubCard = useSelector((state) => state.common?.clientInfo?.clubCard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clubCard?.isGift && !clubCard?.giftSeen && new Date(clubCard?.expiredAt) > new Date()) {
      dispatch(setModal({ name: MODALS.CLUB_CARD_GIFT }));
      dispatch(setConfetti({ visible: true }));
    }
  }, [clubCard]);
};

export {
  useControllView,
  useClientInfo,
  CheckClientInfo,
  useLanguage,
  useMessage,
  useAuthData,
  useConnectSocket,
  useListenMessages,
  useToaster,
  useListenWebviewMessages,
  useCheckForGift,
  useSetLanguageByIpAddress,
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { List } from '../../components';

import { request, error } from '../../tools';
import ModalWindow from '../ModalWindow';
import ContractTaskItem from './ContractTaskItem/ContractTaskItem';
import { editContracts } from '../../store/commonReducer';
import { translation } from 'common_constants/translation';

import './styles.scss';

const ContractTasks = ({ open, setOpen, contractId }) => {
  const dispatch = useDispatch();

  const contracts = useSelector((state) => state.common.contracts);
  const lang = useSelector((state) => state.common.language);

  const ITEMS_PER_PAGE = 10;

  const [currentTasks, setCurrentTasks] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [creatingTaskValue, setCreatingTaskValue] = useState({});

  const defaultTitle = translation.contractTasksTitle1[lang];

  const onCancel = () => setOpen(false);

  useEffect(() => {
    const contract = contracts.find((item) => item._id === contractId);
    const sortedTasks = contract?.tasks
      ? contract?.tasks
          .map((task) => task)
          .sort((task) => (task?.done ? 1 : -1))
          .sort((task) => (task?.rejected ? 1 : -1))
      : [];
    setCurrentTasks(sortedTasks);
  }, [contractId]);

  const rejectTask = (index) => {
    onChangeInputValue({ value: true, index, field: 'rejected' });
  };

  const setDoneTask = (index) => {
    onChangeInputValue({ value: true, index, field: 'done' });
  };

  const onChangeInputValue = ({ value, index, field }) => {
    const updatedTasks = currentTasks.map((item, itemIndex) => (itemIndex === index ? { ...item, [field]: value } : item));
    setCurrentTasks(updatedTasks);
    saveData(updatedTasks);
  };

  const onChangeCreatingInputValue = (value, field) => {
    setCreatingTaskValue((prev) => ({ ...prev, [field]: value }));
  };

  const addCreatingTask = () => {
    const formattedDeadline = creatingTaskValue.deadline ? new Date(creatingTaskValue.deadline).toISOString() : new Date().toISOString();

    const newTask = {
      ...creatingTaskValue,
      deadline: formattedDeadline,
      title: creatingTaskValue.title || defaultTitle,
    };

    const updatedTasks = [...currentTasks, newTask];
    setIsCreating(false);
    setCurrentTasks(updatedTasks);
    saveData(updatedTasks);
  };

  const saveData = (tasks) => {
    const requestData = {
      tasks: tasks.length > 0 ? tasks : currentTasks,
      _id: contractId,
    };

    request(
      '/contracts/addTasks',
      requestData,
      (req) => {
        if (req.status) {
          dispatch(editContracts(requestData));
          setCreatingTaskValue({});
        }
      },
      error,
    );
  };

  return (
    <ModalWindow modal={open} title={translation.contractTasksTitle2[lang]} setModal={onCancel} footer={null} className="tasksModal">
      {open && (
        <div className="tasks">
          <List
            pagination={{
              pageSize: ITEMS_PER_PAGE,
              style: { justifyContent: 'center', marginTop: 15 },
              onChange: (page) => setPageIndex(page * ITEMS_PER_PAGE - ITEMS_PER_PAGE),
            }}
            grid={{
              gutter: 16,
              column: 1,
            }}
            dataSource={currentTasks}
            renderItem={(item, index) => {
              return (
                <ContractTaskItem
                  item={item}
                  key={index}
                  index={index}
                  rejectTask={rejectTask}
                  setDoneTask={setDoneTask}
                  onChangeInputValue={onChangeInputValue}
                  lang={lang}
                />
              );
            }}
          >
            <form className="form-create-task" onSubmit={(e) => e.preventDefault}>
              <div className="task-item">
                <div className="task-item-date create">
                  <input
                    type="date"
                    value={creatingTaskValue?.deadline || ''}
                    onChange={(e) => onChangeCreatingInputValue(e.target.value, 'deadline')}
                    className="form-input"
                  />
                </div>

                <div className="row-bottom">
                  <div className="form-group">
                    <input
                      value={creatingTaskValue?.title || ''}
                      className="form-input _title"
                      onChange={(e) => onChangeCreatingInputValue(e.target.value, 'title')}
                      placeholder="Опишіть завдання..."
                    />
                  </div>
                </div>
              </div>
            </form>

            <div className="add-new-task">
              <button className="add-new-task-btn" disabled={isCreating} onClick={addCreatingTask}>
                <PlusOutlined /> <p>{translation.contractTasksText3[lang]}</p>
              </button>
            </div>
          </List>
        </div>
      )}
    </ModalWindow>
  );
};

export default ContractTasks;

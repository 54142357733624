import React, { useState, useRef, useEffect } from 'react';
import jsQR from 'jsqr';
import { warn } from '../../tools';

const EnhancedQRScanner = ({ onScan, onClose, onCloseModal }) => {
  const videoRef = useRef();
  const timerRef = useRef(null);
  let stream;
  const [scanning, setScanning] = useState(true);

  useEffect(() => {
    const video = videoRef.current;

    const handleSuccess = (stream) => {
      video.srcObject = stream;
      video.onloadedmetadata = () => {
        video
          .play()
          .then(() => {
            handleDecode();
          })
          .catch(handleError);
      };
    };

    const handleError = (error) => {
      warn('', '', { 'Error accessing media devices:': error });
    };

    const startCamera = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
        handleSuccess(stream);
      } catch (error) {
        handleError(error);
      }
    };

    startCamera();

    return () => {
      if (video.srcObject) {
        video.srcObject.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [onCloseModal]);

  const handleDecode = () => {
    if (!scanning) return;

    const video = videoRef.current;

    if (video.videoWidth === 0 || video.videoHeight === 0) {
      timerRef.current = setTimeout(handleDecode, 200);
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height);

    if (code?.data || onCloseModal) {
      if (code?.data) onScan(code.data);
      onClose(false);

      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }

      setScanning(false);
    } else if (scanning && stream?.active) {
      timerRef.current = requestAnimationFrame(handleDecode);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      cancelAnimationFrame(timerRef.current);
    };
  }, []);

  return (
    <div>
      <video ref={videoRef} style={{ width: '100%' }} playsInline autoPlay />
    </div>
  );
};

export default EnhancedQRScanner;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DAILY_PARTNER_INVITE_LIMIT } from 'common_constants/business';

import { clientLogOut, request } from '../../tools';
import { setClientInfo } from '../../store/commonReducer';
import BalanceNew from '../../components/Balance/BalanceNew';
import InvitesList from '../../components/Partner/InvitesList/InvitesList';
import RequestPayment from '../../components/Partner/RequestPayment/RequestPayment';
import InvitationTransactions from '../../components/Partner/InvitationTransactions/InvitationTransactions';
import PaymentCard from '../../components/PaymentCard';
import Invite from './Invite';
import Rules from './Rules';
import ClubCard from '../../components/ClubCard';
import TransactionsButton from './BonusesTransactionsButton';
import QRCodeScannerContainer from '../../components/QRCodeReader/QRCodeScannerContainer';
import { translation } from 'common_constants/translation';

import CARDFRONT from '../../images/club-card-front-2.jpg';
import CARDBACK from '../../images/club-card-back-2.jpg';
import './PartnerHome.scss';
import ClientButton from '../../components/ClientButton/ClientButton';

import bonusesImg from './images/bonusesImg.svg';
import rulesImg from './images/rulesImg.svg';
import PartnerButtons from '../../components/Partner/PartnerButtons/PartnerButtons';
import QRCodeScanButton from '../../components/QRCodeReader/QRCodeScanButton';

const PartnerHome = () => {
  const [history, dispatch] = [useHistory(), useDispatch()];
  const [clientInfo, clientAuth] = [useSelector((state) => state.common.clientInfo), useSelector((state) => state.common.clientAuth)];

  const [balance, setBalance] = useState('0.00');
  const [invitesListState, setInvitesListState] = useState(false);
  const [inviteState, setInviteState] = useState(false);
  const [invitedClients, setInvitedClients] = useState([]);
  const [requestPaymentState, setRequestPaymentState] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [remainingInvitationsToday, setRemainingInvitationsToday] = useState(0);
  const [rulesModal, setRulesModal] = useState(false);
  const [paymentCardModal, setPaymentCardModal] = useState(false);
  const [paymentCard, setPaymentCard] = useState();
  const [isScannerVisible, setScannerVisible] = useState(false);
  const lang = useSelector((state) => state.common.language);

  useEffect(() => {
    if (!clientAuth) return;
    // todo: move calculations of dayly things from /getPartnerData to /getClientInfo and remove this request end endpoint.
    // (states should be recived from clientInfo)
    // todo validation of data transmitted in the request ?
    fetch(process.env.REACT_APP_API + '/partners/getPartnerData', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + clientAuth?.token },
    })
      .then((res) => res.json())
      .then(({ status, partner_invitedClients, partner_balance, paymentCard, partner_transactions, todayInvites }) => {
        if (status) {
          setInvitedClients(partner_invitedClients ?? []);
          setBalance(partner_balance ? partner_balance : '0.00');
          setTransactions(partner_transactions ?? []);
          setRemainingInvitationsToday(DAILY_PARTNER_INVITE_LIMIT - (todayInvites ?? 0));
          setPaymentCard(paymentCard);
        }
      });

    request('/partners/watchPartnerTransactions', null, () => {
      dispatch(
        setClientInfo({
          ...clientInfo,
          partner_transactions: clientInfo?.partner_transactions?.map((transaction) => ({ ...transaction, saw: true })),
        }),
      );
    });

    const rulesReed = localStorage.getItem('rulesReed');
    if (!rulesReed) {
      setRulesModal(true);
    }
  }, []);

  const handleOpenScanner = () => {
    setScannerVisible(true);
  };

  const handleCloseScanner = () => {
    setScannerVisible(false);
  };

  return (
    <div className="partner-home_page">
      <BalanceNew
        balance={balance}
        balanceName={`${translation.balanceName[lang]} ${invitedClients.length || 0}`}
        partnerPage={true}
        modal={() => setInviteState(true)}
      />
      <div className="qr-scanner">
        <QRCodeScanButton className={`qrcode-link`} onClick={handleOpenScanner} label={translation.scanQRcode?.[lang] ?? ''} />
      </div>
      <div className="client-menu">
        <ClientButton text={translation.clientButtonText[lang]} icon={bonusesImg} modal={setInvitesListState} />
        {/* <ClientButton text={'Запросити виплату'} icon={rulesImg} modal={setRequestPaymentState} /> */}
      </div>
      <TransactionsButton transactions={transactions} />
      {/* <NewClubCard front={CARDFRONT} back={CARDBACK} /> */}
      <InvitesList open={invitesListState} setInvitesListState={setInvitesListState} invitedClients={invitedClients} />
      <Invite
        open={inviteState}
        setInviteModal={setInviteState}
        invitedClients={invitedClients}
        setInvitedClients={setInvitedClients}
        remainingInvitationsToday={remainingInvitationsToday}
        setRemainingInvitationsToday={setRemainingInvitationsToday}
      />
      <RequestPayment
        open={requestPaymentState}
        balance={balance}
        setBalance={setBalance}
        setRequestPaymentState={setRequestPaymentState}
        transactions={transactions}
        setTransactions={setTransactions}
        paymentCard={paymentCard}
      />
      {/* <PartnerButtons setInvitesListState={setInvitesListState} setInviteState={setInviteState} setRequestPaymentState={setRequestPaymentState} /> */}
      {/* <InvitationTransactions transactions={transactions} /> */}
      <Rules open={rulesModal} setRulesModal={setRulesModal} />
      <PaymentCard
        paymentCardModal={paymentCardModal}
        setPaymentCardModal={setPaymentCardModal}
        paymentCard={paymentCard}
        setPaymentCard={setPaymentCard}
      />
      <QRCodeScannerContainer
        className="qrcode-link"
        isScannerVisible={isScannerVisible}
        handleCloseScanner={handleCloseScanner}
        modalTitle={translation.scanQRcodeModalTitle?.[lang] ?? ''}
      />
    </div>
  );
};

export default PartnerHome;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputNew from '../../components/InputNew';
import BalanceNewButton from '../../components/Balance/BalanceNewButton';
import { translation } from 'common_constants/translation';
import { request, success, error } from '../../tools';
import reactNativeService from '../../tools/reactNativeService';
import { CheckClientInfo } from '../../tools/hooks';
import Modal from '../../components/Modal';

import { PAYMENT_FRAME } from 'common_constants/modals';

import { setModal } from '../../store/commonReducer';

const AddTips = () => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.common.language);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const [amount, setAmount] = useState();

  const arrayPayment = [50, 100, 150, 200];
  const title = translation.addTipsModalTitle[lang];
  const placeholder = translation.addTipsModalPlaceholder[lang];
  const buttonText = translation.addTipsModalButton[lang];

  const handleClickModalPaymentButton = () => {
    if (!amount || amount < 1) return;

    const onError = (_, __, axiosError) => error('Помилка оплати!', axiosError.error);

    const body = { clientId: clientInfo._id, ph: clientInfo.ph, amount };
    const onSuccess = (res) => {
      success(translation.addTipsModalSuccess[lang]);
      if (res.data.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ paymentUrl: res.data.checkoutUrl });
        return dispatch(setModal({ name: PAYMENT_FRAME, data: { paymentUrl: res.data.checkoutUrl, payType: 'tips' } }));
      }
      CheckClientInfo(dispatch);
    };

    request('/payment/mono/payForTips/link', body, onSuccess, onError);
    setAmount('');
    return;
  };

  const handleClick = (index) => {
    const amount = arrayPayment[index];
    setAmount(amount);
  };

  const onClose = () => {
    dispatch(setModal());
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal title={title} open footer={false} onCancel={onClose}>
      <div className="balance-pay-content">
        <InputNew
          onKeyDown={handleKeyPress}
          className={'balance-pay-content-input'}
          type="number"
          style={{ backgroundColor: '#ceecfd' }}
          value={amount || ''}
          onChange={(e) => setAmount(e.target.value)}
          placeholder={placeholder}
        />

        <div className="balance-pay-content-values">
          {arrayPayment.map((item, i) => (
            <div key={i} onClick={() => handleClick(i)}>
              ₴{item}
            </div>
          ))}
        </div>
        <BalanceNewButton onClick={handleClickModalPaymentButton} buttonText={buttonText} arrow={false} />
      </div>
    </Modal>
  );
};

export default AddTips;

import { useSelector } from 'react-redux';
import { WebinarItem } from './WebinarItem/WebinarItem';
import { WEBINAR_STATUSES } from 'common_constants/business';
import { translation } from 'common_constants/translation';

import SectionName from '../../../components/SectionName';

import './WebinarSwiper.scss';

export const WebinarSwiper = () => {
  const allWebinars = useSelector((state) => state.common.webinars);
  const { webinars: userWebinars } = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);

  const updatedList = allWebinars
    .map((webinar) => {
      const userWebinar = userWebinars?.find((webinarItem) => webinarItem._id === webinar._id) || { ownStatus: 0 };
      return { ...webinar, ...userWebinar };
    })
    .filter((item) => item.status !== WEBINAR_STATUSES.FINISHED);

  const webinarList = updatedList?.map((webinar) => (
    <swiper-slide key={webinar._id}>
      <WebinarItem item={webinar} />
    </swiper-slide>
  ));

  if (!updatedList.length) {
    return null;
  }

  return (
    <>
      <SectionName text={translation.webinars[lang]} />

      <div className="webinars-swiper">
        {updatedList.length > 1 ? (
          <swiper-container class="mySwiper" space-between="-12" slides-per-view="auto" free-mode="true" initial-slide="news">
            {webinarList}
          </swiper-container>
        ) : (
          <div className="full-width">
            <WebinarItem item={updatedList[0]} />
          </div>
        )}
      </div>
    </>
  );
};

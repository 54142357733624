import SelectNew from '../SelectNew';

import UA from 'country-flag-icons/react/3x2/UA';
import DE from 'country-flag-icons/react/3x2/DE';
import CZ from 'country-flag-icons/react/3x2/CZ';

import { COUNTRY_PHONE_CODES } from 'common_constants/business';

import './PhoneCodeSwitcher.scss';
import { useForm } from 'react-hook-form';

const codesOptions = [
  {
    value: COUNTRY_PHONE_CODES.UA,
    label: 'UA',
    code: `+${COUNTRY_PHONE_CODES.UA}`,
  },
  {
    value: COUNTRY_PHONE_CODES.DE,
    label: 'DE',
    code: `+${COUNTRY_PHONE_CODES.DE}`,
  },
  {
    value: COUNTRY_PHONE_CODES.CZ,
    label: 'CZ',
    code: `+${COUNTRY_PHONE_CODES.CZ}`,
  },
];

const PhoneCodeSwitcher = ({ onChange, countryPhCode, setCountryPhCode }) => {
  const { control } = useForm({
    defaultValues: {
      language: countryPhCode,
    },
  });
  const handleLanguageChange = (value) => {
    onChange(value);
    setCountryPhCode(value);
  };

  const optionsWithFlags = codesOptions.map((option) => {
    let flag;
    switch (option.value) {
      case COUNTRY_PHONE_CODES.UA:
        flag = <UA />;
        break;
      case COUNTRY_PHONE_CODES.CZ:
        flag = <CZ />;
        break;
      case COUNTRY_PHONE_CODES.DE:
        flag = <DE />;
        break;
      default:
        flag = null;
    }

    return {
      ...option,
      label: (
        <div>
          {flag} {option.code}
        </div>
      ),
    };
  });

  return (
    <SelectNew name="language" control={control} value={countryPhCode} className="phone" onChange={handleLanguageChange} options={optionsWithFlags} />
  );
};

export default PhoneCodeSwitcher;

import './UniversalItemButton.scss';

const UniversalItemButton = ({ title, onClick, icon, link }) => {
  return (
    <div onClick={onClick} className="first-log-button">
      {link && <a href="tel:0800403303" className="first-log-button-link" />}
      <div className="first-log-button-text">
        <span>
          {icon && icon} {title}
        </span>
        <div className="first-log-arrow-button"></div>
      </div>
    </div>
  );
};

export default UniversalItemButton;

import React from 'react';
import { useSelector } from 'react-redux';
import { UKRAINE_STATES } from 'common_constants/business';
import { Link } from 'react-router-dom';
import './NewsSwiper.scss';

const OFFICES_ORDER = [3, 41, 1, 32, 33, 7, 13, 34, 4, 21, 36, 29];

const OfficeSwiper = () => {
  const filii = useSelector((state) => state.common.filii) ?? [];

  const reduceImageFil = (item) => {
    if (item.image && item.ext) {
      return `${process.env.REACT_APP_API}/filiiImages/${item.image}.${item.ext}`;
    } else {
      return '/ao-zahist.jpg';
    }
  };

  const sortedFils = filii.filter((i) => OFFICES_ORDER.includes(i.i));
  const filteredFilii = sortedFils.sort((a, b) => OFFICES_ORDER.indexOf(a.i) - OFFICES_ORDER.indexOf(b.i));
  const restFils = filii.filter((i) => !OFFICES_ORDER.includes(i.i));

  const result = filteredFilii.concat(restFils).filter((i) => !i.isDisabled);

  return (
    <div className="news-swiper">
      <swiper-container class="mySwiper" space-between="-12" slides-per-view="auto" free-mode="true" initial-slide="news">
        {result?.map((item) => (
          <swiper-slide key={item._id}>
            <Link to={{ pathname: `filiiState/${Object.keys(UKRAINE_STATES)[item.states?.[0]]}`, state: { filNameParam: item.name } }}>
              <div className="news-content" style={{ height: '142px' }}>
                <img src={reduceImageFil(item)} alt="fil-img" />
                <div className="news-title">{item.name ?? ''}</div>
              </div>
            </Link>
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
};

export default OfficeSwiper;

import React from 'react';
import Avatar from '../Avatar/Avatar';
import { UserOutlined } from '@ant-design/icons';

const UserAvatar = ({ style, size, user, customUrl }) => {
  const imageName = user?.upa;
  // const defaultURL = 'https://th.bing.com/th/id/R.742272f24d8a63716316916334873f53?rik=ucSMX3z0NapV8A&pid=ImgRaw&r=0';
  const unknownURL = 'https://th.bing.com/th/id/OIP.NmdbjgnCEbGC6o4A43nhJgHaHs?pid=ImgDet&w=195&h=202&c=7&dpr=1.5';
  const userAvatarURL = imageName ? process.env.REACT_APP_API + `/avatars/${imageName}.jpeg` : customUrl;

  return <Avatar size={size} style={style} icon={<UserOutlined />} src={user || customUrl ? userAvatarURL : unknownURL} />;
};

export default UserAvatar;

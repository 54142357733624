import ModalWindow from '../ModalWindow';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContracts, setModal, setContract } from '../../store/commonReducer';
import {
  FIRST_LOGIN_OF_THE_CLIENT,
  PAYMENT_FRAME,
  FIRST_LOGIN_AUTO_PAY,
  LEAVE_SUGGESTION_MODAL,
  FEEDBACK_BONUS_MODAL,
  FUNCTIONS_NOT_USED,
} from 'common_constants/modals';
import { Popconfirm } from '../../components';
import { CONTRACT_AUTOPAY_CASHBACK, RED_BUTTON } from 'common_constants/business';
import dayjs from 'dayjs';
import { translation } from 'common_constants/translation';

import Switch from 'rc-switch';
import 'rc-switch/assets/index.css';
import reactNativeService from '../../tools/reactNativeService';
import { request, error, success } from '../../tools';

const FirstLoginAutoPay = () => {
  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const { contracts = [] } = clientInfo;
  const paymentCard = clientInfo?.paymentCard;
  const [contract, setRandomContract] = useState();

  const oneRandomContract = () => {
    if (!clientInfo?._id) return false;
    const openOneOfTwenty = Math.floor(Math.random() * 20 + 1);
    if (openOneOfTwenty === 1) {
      dispatch(setModal({ name: FUNCTIONS_NOT_USED }));
      return false;
    }
    const findContract = (contract) => contract?.fbBonus === false;
    const fBBonusContract = clientInfo.contracts?.find(findContract);
    if (fBBonusContract && openOneOfTwenty === 2) {
      dispatch(setContract(fBBonusContract));
      dispatch(setModal({ name: FEEDBACK_BONUS_MODAL }));
      return false;
    }
    const openOneOfTen = Math.floor(Math.random() * 10 + 1);
    if (openOneOfTen === 1) {
      dispatch(setModal({ name: LEAVE_SUGGESTION_MODAL }));
      return false;
    }
    const openOneOfFive = Math.floor(Math.random() * 5 + 1);
    if (openOneOfFive !== 1) {
      dispatch(setModal({ name: FIRST_LOGIN_OF_THE_CLIENT }));
      return false;
    }
    if (contracts.length === 0) {
      dispatch(setModal({ name: FIRST_LOGIN_OF_THE_CLIENT }));
      return false;
    }
    const contractsToModal = contracts.filter((contract) => !contract?.autoPay && !contract?.ad);
    if (contractsToModal.length === 0) {
      dispatch(setModal({ name: FIRST_LOGIN_OF_THE_CLIENT }));
      return false;
    }
    const contract = contractsToModal[Math.floor(Math.random() * contractsToModal.length)];
    dispatch(setModal({ name: FIRST_LOGIN_AUTO_PAY }));
    return contract;
  };

  const handleOpenPayWindow = () => {
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location.href = res.checkoutUrl;
      }
    };
    const onError = (_, __, axiosError) => error('', 'axiosError.error');
    request('/payment/mono/bindCard/link', { redirectUrl: `/contractInfo/${contract._id}` }, onSuccess, onError);
  };

  const [open, setOpen] = useState(false);
  useEffect(() => {
    setRandomContract(oneRandomContract());
  }, [clientInfo]);

  useEffect(() => {
    if (contract) setOpen(true);
  }, [contract]);

  sessionStorage.setItem('modalFirst', true);

  const handleSwitchAutoPay = (autoPay) => {
    const onSuccess = () => {
      success(translation.autoPayModalSuccess[lang]);
      dispatch(setContracts(contracts.map((item) => (item._id === contract._id ? { ...item, autoPay } : item))));
    };
    const onError = () => {
      error(translation.error[lang]);
    };
    request('/contracts/setAutoPay', { _id: contract._id, autoPay }, onSuccess, onError);
  };

  return (
    <ModalWindow title={translation.autoPayModalTitle[lang]} modal={open} setModal={setOpen}>
      <div>
        <div style={{ marginBottom: 10 }}>
          {
            <>
              {translation.firstLoginAutoPayText1[lang]}
              {contract?.cn ? (
                <b> "{contract.cn === RED_BUTTON.value ? RED_BUTTON.label : contract.cn}"</b>
              ) : (
                <>
                  {' '}
                  {translation.firstLoginAutoPayText2[lang]} <b>{dayjs(contract?.sd).format('DD.MM.YYYY')}</b>
                </>
              )}
              ?
            </>
          }
          <br />
          <br />
          {contract?.mg ? (
            <div>
              {translation.autoPayModalText1[lang]} <b>{contract?.mg}₴</b>
            </div>
          ) : (
            <div>{translation.autoPayModalText2[lang]}</div>
          )}
          <br />
          <i style={{ fontSize: 12 }}>{translation.autoPayModalText3[lang]}</i>
        </div>
        {paymentCard ? (
          contract?.mg && (
            <div style={{ marginBottom: 10 }}>
              {translation.autoPayModalText4[lang]}
              <Switch checked={contract?.autoPay} onChange={handleSwitchAutoPay} style={{ marginLeft: 10 }} />
            </div>
          )
        ) : (
          <div>
            {translation.autoPayModalText5[lang]}
            <br />
            <br />
            <Popconfirm
              title={translation.popconfirmTitle[lang]}
              description={
                <div>
                  <div>{translation.autoPayModalText6[lang]}</div>
                  <div>{translation.autoPayModalText7[lang]}</div>
                </div>
              }
              onConfirm={handleOpenPayWindow}
              okText={translation.yes[lang]}
              cancelText={translation.no[lang]}
            >
              <div className="cardForPayments_button">+</div>
            </Popconfirm>
            <br />
          </div>
        )}
        <div>
          {translation.autoPayModalText8[lang]}
          <b>
            {translation.autoPayModalText8_1[lang]} {CONTRACT_AUTOPAY_CASHBACK * 100}%
          </b>
          {translation.autoPayModalText8_2[lang]}
          <br />
          <br />
        </div>
        <i style={{ fontSize: 12 }}>{translation.autoPayModalText9[lang]}</i>
      </div>
    </ModalWindow>
  );
};

export default FirstLoginAutoPay;

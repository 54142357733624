import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';

import './BalanceNew.scss';
import BalanceNewButton from './BalanceNewButton';
import { CURRENCIES } from 'common_constants/business';

const BalanceNew = ({ bonusesBalance = '0.00', balance = '0.00', loading, menuItems = [], balanceName, partnerPage, modal }) => {
  const lang = useSelector((state) => state.common.language);
  const client = useSelector((state) => state.common.clientInfo);
  return (
    <div className="container_balance-new">
      <div className="balance-wrapper">
        <div className="balance-text">{partnerPage ? translation.partnershipBalance[lang] : translation.account[lang]}</div>
        <div className="balance-bonuses">
          {balance < 0
            ? `-${CURRENCIES[client.currency]?.symbol ?? '₴'}${Math.abs(balance)?.toLocaleString('uk-UA')}`
            : `${CURRENCIES[client.currency]?.symbol ?? '₴'}${balance?.toLocaleString('uk-UA')}`}
        </div>
        {balanceName && <div className="balance-invite-text">{balanceName}</div>}
      </div>
      <div className="invite-content">
        {/* <div className="invite-button-text">
          <span>{partnerPage ? translation.inviteTitle[lang] : translation.topUpYourBalance[lang]} </span>
        </div> */}
        <BalanceNewButton
          onClick={modal}
          buttonText={partnerPage ? translation.inviteBtn[lang] : translation.clientBalancePayModalButtonText[lang]}
        />
      </div>
    </div>
    // <div className="container_balance">
    //   <div className="balance">
    //     {!loading ? (
    //       <>
    //         <h4 className="balancebig">{(balance + '').slice(0, -3)}</h4> <h4 className="balancesmall">{(balance + '').slice(-3)}</h4>
    //       </>
    //     ) : (
    //       <h4 className="balancebig">
    //         <Spin size="large" />
    //       </h4>
    //     )}
    //   </div>
    //   <div className="balanceBonusText">{balanceName}</div>
    // </div>
  );
};

export default BalanceNew;

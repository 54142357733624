import React from 'react';
import { Link } from 'react-router-dom';

import Badge from '../Badge/Badge';
import SmallRoundedArrow from '../SmallRoundedArrow/SmallRoundedArrow';

import './ClientButton.scss';

const ClientButton = ({ text, icon, antIcon, link, badge, modal, setModal }) => {
  const renderComponent = (
    <Badge count={badge} className="menu-item-badge">
      <div className="menu-item-text-new">
        <span>{text}</span>
        <SmallRoundedArrow />
      </div>

      {icon ? (
        <div className="icon-wrapper-new">
          <img src={icon} alt="logo" />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          {antIcon}
        </div>
      )}
    </Badge>
  );
  return link ? (
    <Link className="menu-item-new" to={link} children={renderComponent} />
  ) : (
    <div onClick={modal ? modal : () => setModal(true)} className="menu-item-new" children={renderComponent} />
  );
};

export default ClientButton;

import React, { useState } from 'react';
import { SwiperInsurance } from '../../components/SwiperInsurance/SwiperInsurance';
import AddInsurance from '../../Modals/AddInsurance';

const NewInsurancePresentation = ({ getInsurances, insuranceTypes }) => {
  const [addModal, setAddModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleAddClick = () => {
    setAddModal(true);
  };

  return (
    <div className="new_insurance_presentation">
      <SwiperInsurance insuranceTypes={insuranceTypes} handleAddClick={handleAddClick} setActiveSlide={setActiveSlide} />
      <AddInsurance
        setInsuranceModal={setAddModal}
        visible={addModal}
        getInsurances={getInsurances}
        insuranceTypes={insuranceTypes}
        type={activeSlide}
      />
    </div>
  );
};

export default NewInsurancePresentation;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { request, success, error } from '../../tools';
import { setMainLoader } from '../../store/uiReducer';
import { setHotlines, setHistoryHotlines } from '../../store/commonReducer';
import Col from '../../components/Col';
import ClientMainPageHotlines from './ClientMainPageHotlines';
import { fetchHotlines } from '../ClientContracts/ClientContractsHelpers';

import './ClientMainPage.scss';

// todo: rename please
const ClientHotlineListPage = () => {
  const dispatch = useDispatch();
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const historyHotlines = useSelector((state) => state.common.historyHotlines);
  const hotlines = useSelector((state) => state.common.hotlines);
  const [currentHotlines, setCurrentHotlines] = useState(hotlines);

  const core_megaState = {
      clientHotlines: [],
      certHotline: [],
      clientHistoryHotlines: [],
      hotlinesLoaded: false,
    },
    [megaState, setMegaState] = useState(core_megaState);

  // todo: Корисно було б додати редакс сюди для запису контрактів та хотлайну, та відображення - якщо вони є. А запит зробити затримку десь на 5 хвилин через редакс. Тобто, якщо запит був, то не запитуй знову, якщо клієнт постійно перескакує між сторінками, щоб просто подивитися. Так ми зменшимо навантаження  на додаток через завантаження та обробку даних

  useEffect(() => {
    if (!clientAuth) return;

    const getData = async () => {
      dispatch(setMainLoader(true));

      const { hotlines, _clientHotlines, _certHotline, _historyHotlines } = await fetchHotlines();
      dispatch(setHotlines(hotlines));
      dispatch(setHistoryHotlines(_historyHotlines));
      setMegaState((prev) => ({
        ...prev,
        clientHotlines: _clientHotlines,
        certHotline: _certHotline,
        hotlinesLoaded: true,
      }));

      dispatch(setMainLoader(false));
    };

    getData();
  }, [currentHotlines]);

  const paymentByClubCard = (vip, consultationType, correntMonth, hotlineId) => {
    const requestData = { vip, consultationType, correntMonth, hotlineId };
    const onSuccess = ({ data }) => {
      const _hotlines = hotlines?.map((ho) => (ho?._id === data?.updatedHotline?.value?._id ? data.updatedHotline.value : ho));
      dispatch(setHotlines(_hotlines));
      setCurrentHotlines(_hotlines);
      success('Консультацію оплачено');
      return true;
    };
    const onError = () => {
      error('Не вдалось списати послугу з клубної карти');
      return false;
    };
    request('/hotline/paymentByClubCard', requestData, onSuccess, onError);
  };

  return (
    <section className="clientMain_page">
      <Col className="clientMain-body">
        <ClientMainPageHotlines
          clientHotlines={megaState.clientHotlines}
          hotlinesLoaded={megaState.hotlinesLoaded}
          certHotline={megaState.certHotline}
          clientHistoryHotlines={historyHotlines ?? []}
          paymentByClubCard={paymentByClubCard}
        />
      </Col>
    </section>
  );
};

export default ClientHotlineListPage;

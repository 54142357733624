import ModalWindow from '../ModalWindow';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { request, error as handleError } from '../../tools';
import { useSelector } from 'react-redux';
import { PaymentManagementBox } from 'common_components';
import { translation } from 'common_constants/translation';
import dayjs from 'dayjs';
import './ClientPaymentManagement.scss';
import Button from '../../components/Button/Button';
import { Spin } from '../../components';
import { useToaster } from '../../tools/hooks';
import { CURRENCIES } from 'common_constants/business';

const PAY_FOR_ASSIGNMENT = '4';

const ClientPaymentManagement = ({ modal, setModal, contract, transactions }) => {
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const [loading, setLoading] = useState(false);
  const [sugar, setSugar] = useState([]);
  const [totalPayByClient, setTotalPayByClient] = useState(0);
  const [totalInstallment, setTotalInstallment] = useState(0);
  const [selectedBoxes, setSelectedBoxes] = useState([]);
  const [error, setError] = useState(null);
  const { error: toasterError } = useToaster();
  let remainingMoney = totalPayByClient;
  const paymentManagement = contract?.paymentManagement || [];
  const monthlyPayments = contract?.monthlyPaymentsManagement || [];
  const assignments =
    contract?.assignments?.filter((item) => !item?.incorrect && dayjs(item?.date).startOf('day').isAfter(dayjs(contract?.md))) || [];

  const sumSelectedBoxesAmounts = (selectedBoxes) => selectedBoxes.reduce((total, box) => total + Number(box.amount), 0);

  const countTotalPayByClient = () => sugar?.reduce((acc, item) => acc + Number(item?.amount), 0);

  const countTotalInstallment = () => {
    const paymentSum = paymentManagement.reduce((acc, item) => acc + +item.amount, 0);
    const sum = (+contract?.pa || 0) - (contract?.fp || 0) - paymentSum;
    setTotalInstallment(sum);
  };

  useEffect(() => {
    setTotalPayByClient(countTotalPayByClient());
  }, [sugar]);

  const getSugarForClient = () => {
    setLoading(true);
    setError(null);

    const params = { contractIndex: contract?.i };

    request(
      '/sugar/getContractSugarForClient',
      params,
      ({ sugar }) => {
        setSugar(sugar);
        setLoading(false);
      },
      (err) => {
        setError('Failed to fetch payment data');
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    getSugarForClient();
    countTotalInstallment();
  }, []);

  const payForContract = async () => {
    setLoading(true);
    setError(null);

    const withCashback = selectedBoxes.every((box) => dayjs().isBefore(dayjs(box.date).startOf('day')));
    try {
      await request(
        '/payment/mono/payContractInAdvance/link',
        {
          clientId: contract?.C,
          amount: sumSelectedBoxesAmounts(selectedBoxes),
          contractId: contract?._id,
          contractIndex: contract?.i,
          fil: contract?.f,
          withCashback,
          currency: clientInfo?.currency,
        },
        (res) => {
          if (res.status === true && res.paymentStatus === 'success') {
            remainingMoney += sumSelectedBoxesAmounts(selectedBoxes);
            setSelectedBoxes([]);
            setTimeout(() => {
              getSugarForClient(); // Refresh sugar data or any other necessary data
              countTotalInstallment();
            }, 3000);
          } else {
            setError('Payment failed');
          }
        },
      );
    } catch (err) {
      setError('Payment failed');
      toasterError('Payment failed');
    } finally {
      setLoading(false);
    }
  };

  const renderPaymentBox = (payment, isPaid, isPastDate, isSelected, handleClick) => (
    <div onClick={handleClick}>
      <PaymentManagementBox
        key={payment.date}
        amount={payment.amount}
        date={payment.date}
        style={isSelected ? { background: 'lightblue' } : isPaid ? { background: 'lightgreen' } : isPastDate ? { background: 'lightcoral' } : null}
        disabled
      />
    </div>
  );

  const renderBoxes = (payments) => {
    return payments.map((item, index) => {
      const isPaid = remainingMoney - item.amount >= 0;
      const isPastDate = dayjs(item.date).startOf('day').isBefore(dayjs());
      const isSelected = selectedBoxes.some((box) => box.date === item.date);

      const handleClick = () => {
        if (isSelected) {
          setSelectedBoxes(selectedBoxes.filter((box) => box.date !== item.date));
        } else if (!isPaid) {
          setSelectedBoxes([...selectedBoxes, { date: item.date, amount: item.amount }]);
        }
      };

      // Only deduct from remainingMoney if the payment is actually paid
      if (isPastDate || remainingMoney - item.amount >= 0) {
        remainingMoney -= item.amount;
      }

      return renderPaymentBox(item, isPaid, isPastDate, isSelected, handleClick);
    });
  };
  const assigmentsBoxes = (assignments) => {
    return assignments.map((item, index) => {
      const amount = Number(item.clientAmount) || 0;
      const isPaid = remainingMoney - amount >= 0;
      const isPastDate = dayjs(item.date).startOf('day').isBefore(dayjs());
      const isSelected = selectedBoxes.some((box) => box.date === item.date);

      const handleClick = () => {
        if (isSelected) {
          setSelectedBoxes(selectedBoxes.filter((box) => box.date !== item.date));
        } else if (!isPaid) {
          setSelectedBoxes([...selectedBoxes, { date: item.date, amount }]);
        }
      };

      // Only deduct from remainingMoney if the payment is actually paid
      remainingMoney -= amount;
      return (
        <div onClick={handleClick}>
          <PaymentManagementBox
            key={item.date}
            amount={amount}
            date={item.date}
            style={
              isSelected ? { background: 'lightblue' } : isPaid ? { background: 'lightgreen' } : isPastDate ? { background: 'lightcoral' } : null
            }
            disabled
          />
        </div>
      );
    });
  };
  const firstPaymentBox = () => {
    const firstPayment = Number(contract?.fp) || 0;
    const firstPaymentDate = contract?.md || contract?.sd;
    const isPaid = remainingMoney - firstPayment >= 0;
    const isPastDate = dayjs(firstPaymentDate).startOf('day').isBefore(dayjs());
    const isSelected = selectedBoxes.some((item) => item.date === firstPaymentDate);

    const handleClick = () => {
      if (isSelected) {
        setSelectedBoxes(selectedBoxes.filter((item) => item.date !== firstPaymentDate));
      } else if (!isPaid) {
        setSelectedBoxes([...selectedBoxes, { date: firstPaymentDate, amount: firstPayment }]);
      }
    };

    // Only deduct from remainingMoney if the payment is actually paid
    remainingMoney -= firstPayment;

    return renderPaymentBox({ amount: firstPayment, date: firstPaymentDate }, isPaid, isPastDate, isSelected, handleClick);
  };

  const firstPaymentBoxRender = firstPaymentBox();
  const installmentBoxRender = renderBoxes(paymentManagement);
  const assignmentsPaymentBoxRender = assigmentsBoxes(assignments);
  const monthlyPaymentBoxRender = renderBoxes(monthlyPayments);

  return (
    <ModalWindow title="Менеджер платежів" modal={modal} setModal={setModal} onCancel={() => setModal(false)}>
      {error && <div style={{ color: 'red' }}>{error}</div>}

      <>
        {paymentManagement?.length > 0 && (
          <div className="payment-management">
            <div>
              <b>{translation.installment[lang]}</b>
            </div>
            <div className="payment-management-block">{installmentBoxRender}</div>
          </div>
        )}

        {firstPaymentBoxRender && (
          <div className="payment-management">
            <div>
              <b>{translation.firstPayment[lang]}</b>
            </div>
            <div className="payment-management-block">{firstPaymentBoxRender}</div>
          </div>
        )}

        {assignments?.length > 0 && (
          <div className="payment-management">
            <div>
              <b>{translation.assignment[lang]}</b>
            </div>
            <div className="payment-management-block">{assignmentsPaymentBoxRender}</div>
          </div>
        )}

        {monthlyPayments?.length > 0 && (
          <div className="payment-management">
            <div>
              <b>{translation.monthlyPayments[lang]}</b>
            </div>
            <div className="payment-management-block">{monthlyPaymentBoxRender}</div>
          </div>
        )}

        {!!selectedBoxes.length && (
          <>
            <div style={{ textAlign: 'center', fontSize: 20, height: 30, marginTop: 20 }}>
              Сума до оплати: {sumSelectedBoxesAmounts(selectedBoxes)}
            </div>
            <Button onClick={payForContract} disabled={loading}>
              {loading ? 'Оплачую...' : 'Оплатити'}
            </Button>
          </>
        )}

        <div style={{ textAlign: 'right', fontSize: 20, height: 30, marginTop: 20 }}>
          {remainingMoney > 0 ? (
            <>
              Переплата:
              <b style={{ color: 'green' }}>
                {remainingMoney}
                {CURRENCIES[clientInfo?.currency].symbol}
              </b>
            </>
          ) : (
            <>
              Борг:
              <b style={{ color: 'red' }}>
                {Math.abs(remainingMoney)}
                {CURRENCIES[clientInfo?.currency].symbol}
              </b>
            </>
          )}
        </div>

        {transactions.length > 0 && (
          <div>
            <b>{translation.clientCInfCuratorButton10[lang]}</b> {transactions}
          </div>
        )}
      </>
    </ModalWindow>
  );
};

export default ClientPaymentManagement;

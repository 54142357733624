import { ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Certificate from '../../../images/certificat-free.svg';
import Col from '../../../components/Col';

const ClientMainPageHotlineCert = ({ chn }) => {
  const history = useHistory();

  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);

  const onClick = () => {
    history.push(ROUTES.ANONIM_CERTIFICATE_ + clientInfo.ph);
  };

  return (
    <Col className="client-hotlines__certification">
      <div className="certificate-photo">
        <img src={Certificate} alt="Free Certificate Logo" />
      </div>
      <div className="certificate-info">
        <p className="certificate-info__text">
          {translation.cert0[lang]
            .replace('{chn}', chn)
            .split('\n')
            .map((sentence, index) => (
              <span key={index}>
                {sentence}
                <br />
              </span>
            ))}
        </p>
        <button className="certificate-info__btn" onClick={onClick}>
          {translation.viewCert[lang]}
          <div className="menu-item-text-arrow"></div>
        </button>
      </div>
    </Col>
  );
};

export default ClientMainPageHotlineCert;

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLanguage } from "../../store/commonReducer";
import { languageOptions } from "common_constants/translation";
import { useEffect } from "react";
import reactNativeService from "../../tools/reactNativeService";

const LanguageDetector = () => {
    const { search } = useLocation();
    const dispatch = useDispatch();


  useEffect(() => {
    const params = new URLSearchParams(search)
    const lang = params.get('lang')

    if (languageOptions.find((elem) => elem.value === lang?.toLowerCase())) {
      dispatch(setLanguage(lang));
      reactNativeService.sendMessageToWebview({ language: lang });
    }
  }, [search]);

  return null
}

export default LanguageDetector
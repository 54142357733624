import React, { Fragment, useState } from 'react';
import './List.scss';
import { InboxOutlined } from '@ant-design/icons';
import Pagination from '../Pagination';

const List = (props) => {
  const { className, style, children, dataSource, renderItem, footer, header, pagination = {}, ...rest } = props;

  const [page, setPage] = useState(0);
  const { pageSize, ...paginationProps } = pagination;

  let childrenContent;
  if (dataSource?.length > 0) {
    let source = dataSource;
    if (pageSize) {
      source = source.slice(page * pageSize, page * pageSize + pageSize);
    }
    childrenContent = source.map((elem, index) => <Fragment key={index}>{renderItem(elem, index, page)}</Fragment>);
  } else if (!children) {
    childrenContent = (
      <div className="list-no-data">
        <InboxOutlined style={{ fontSize: 64 }} />
        <p>No data</p>
      </div>
    );
  }
  const changePage = (p) => {
    setPage(p);
  };

  return (
    <div className={`custom-list ${className || ''}`} style={style} {...rest}>
      {!!header && <div>{header}</div>}
      {childrenContent}
      {!!footer && <div>{footer}</div>}
      {children}
      {pageSize && <Pagination page={page} onChange={changePage} pages={Math.ceil(dataSource.length / pagination.pageSize)} {...paginationProps} />}
    </div>
  );
};

const Item = ({ className, children, ...props }) => {
  return (
    <div className={`list-item ${className ? className : ''}`} {...props}>
      {children}
    </div>
  );
};

List.Item = Item;
export default List;

import FeedbacksForm from '../../components/ClientFeedbacks/FeedbacksForm';
import ClientFeedback from '../../components/ClientFeedbacks/ClientFeedback/ClientFeedback';
import FeedbackAnswer from '../../components/ClientFeedbacks/FeedbackAnswer/FeedbackAnswer';

import React, { useEffect, useState } from 'react';
import { request, error } from '../../tools';

import './ClientFeedbacks.scss';

const ClientFeedbacks = () => {
  const [feedbackInfo, setFeedbackInfo] = useState([]);

  useEffect(() => {
    const getFeedbacks = async () => {
      request(
        '/clientFeedback/getFeedbacks',
        {},
        (res) => {
          setFeedbackInfo(res.data);
        },
        error,
      );
    };

    getFeedbacks();
  }, []);

  return (
    <section className="client-feedbacks">
      <div className="client-feedbacks-container">
        <div className="client-feedbacks-wrap">
          <div className="client-feedbacks-inner">
            {feedbackInfo.length !== 0 && (
              <div className="client-feedbacks-all-comments">
                {feedbackInfo.map((item) => (
                  <React.Fragment key={item._id}>
                    <ClientFeedback name={item.name} message={item.message} rating={item.rating} date={item.date} img={item.clientUpa} />
                    <FeedbackAnswer
                      name={item.answerAutorInfo.name}
                      message={item.answer}
                      date={item.answerDate}
                      img={item.answerAutorInfo.upa}
                      jobPosition={item.answerAutorInfo.jobPosition}
                    />
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
          <div className="client-feedbacks-inner">
            <FeedbacksForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientFeedbacks;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { NAME_ZONE_FILES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { warn } from '../../tools';
import { Spin, AvatarFile, PreviewModal } from '../';
import clsx from 'clsx';
import './PreviewImage.scss';

const PreviewImage = ({ item, formatFile, nameZone }) => {
  const token = window.localStorage.getItem('clientAccessToken');
  const params = useParams();

  const clientInfo = useSelector((state) => state.common.clientInfo) ?? [];
  const lang = useSelector((state) => state.common.language);

  const [red, green, blue] = ['#f5222d', '#52c41a', '#1677ff'];

  const core_megaState = {
    loading: true, //* Downloaded images
    url: '',
    status: blue,
  };

  const [megaState, setMegaState] = useState(core_megaState);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleImageClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const dynamicURL =
    {
      [NAME_ZONE_FILES.CHAT_IN_CONTRACT]: '/chat/getChatDriveFileForClientWithSecuredVAF',
      [NAME_ZONE_FILES.CORRESPONDENCE_UPLOADED_FILE]: '/correspondence/getCorrespondenceDriveFileForClientWithSecuredVAF',
    }[nameZone] || null;

  useEffect(() => {
    const timerId = setTimeout(() => {
      const transactionData = {
        clientId: clientInfo?._id,
        contractId: params?.id,
        fileId: item,
        formatFile: formatFile,
      };

      const validation = ({ nameZone, clientId, contractId, fileId, formatFile }) => {
        if (!nameZone) {
          warn('', 'Виникла помилка з місцем завантаження файла. Зверніться, будь ласка, до адміністрації.', { _nameZone: !!nameZone });
          return;
        }

        if (!clientId) {
          warn('', 'Виникла помилка з індентифікуванням вас як клієнта. Зверніться, будь ласка, до адміністрації.', { _clientId: !!clientId });
          return;
        }

        if (!contractId) {
          warn('', 'Виникла помилка з індентифікуванням вашого договору. Зверніться, будь ласка, до адміністрації.', { _contractId: !!contractId });
          return;
        }

        if (!fileId) {
          warn('', 'Виникла помилка з індентифікуванням вашого файлу. Зверніться, будь ласка, до адміністрації.', { _fileId: !!fileId });
          return;
        }

        if (!formatFile) {
          warn('', 'Виникла помилка з індентифікуванням формата вашого файлу. Зверніться, будь ласка, до адміністрації.', {
            _formatFile: !!formatFile,
          });
          return;
        }

        return true;
      };

      if (!validation({ nameZone: nameZone, ...transactionData })) return;

      fetch(process.env.REACT_APP_API + dynamicURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(transactionData),
      })
        .then((response) => {
          if (!response.ok) {
            setMegaState((prev) => ({ ...prev, url: '', status: red, err: true }));
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then((blob) => {
          const url = URL.createObjectURL(blob);

          setMegaState((prev) => ({ ...prev, url: url, status: green, loading: false }));
        })
        .catch((error) => {
          console.error('Error fetching image from server:', error);
          setMegaState((prev) => ({ ...prev, url: '', status: red, err: true }));
        });
    }, 2000);

    return () => clearTimeout(timerId);
  }, []);

  const imgDrive = megaState.loading ? (
    <div className="loading-spinner">
      <Spin tip={translation.loader[lang]} spinning={megaState.loading} />
    </div>
  ) : (
    <img src={megaState.url} className="img-drive" alt="file view" onClick={handleImageClick} />
  );

  return (
    <>
      {megaState.loading && megaState.err ? (
        <AvatarFile item={formatFile} />
      ) : (
        <div className={clsx('preview-image')} onClick={handleImageClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {imgDrive}
          {isHovered && !megaState.loading && (
            <div className="overlay-content">
              <span>{translation.clientInComponentPreview[lang]}</span>
            </div>
          )}
        </div>
      )}
      <PreviewModal visible={isModalVisible} onClose={handleCloseModal}>
        <img src={megaState.url} alt="full view" />
      </PreviewModal>
    </>
  );
};

export default PreviewImage;

import clsx from 'clsx';
import React from 'react';
import './Space.scss';

const Space = ({ direction, align, children, className }) => {
  const classNames = ['space', direction, align].filter(Boolean).join(' ');

  return <div className={clsx(classNames, className)}>{children}</div>;
};

export default Space;

import React from 'react';
import clsx from 'clsx';
import './Image.scss';

const Image = ({ src, alt, caption, className, ...props }) => {
  return (
    <div className={clsx('image-wrapper', className)}>
      <img src={src} alt={alt} className="image" {...props} />
      {caption && <div className="caption">{caption}</div>}
    </div>
  );
};

export default Image;

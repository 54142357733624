import ModalWindow from '../ModalWindow';
import Button from '../../components/Button';
import Input from './../../components/Input/Input';
import { useForm } from 'react-hook-form';
import './GiftHotline.scss';
import { useState } from 'react';
import { request } from '../../tools';
import { useToaster } from '../../tools/hooks';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import { Spin, PhoneCodeSwitcher } from '../../components';

const GiftHotline = ({ open, setOpen }) => {
  const [countryPhCode, setCountryPhCode] = useState('38');
  const lang = useSelector((state) => state.common.language);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      ph: '',
    },
  });
  const { success, error } = useToaster();
  const onSubmit = async ({ ph }) => {
    setLoading(true);
    await request(
      '/hotline/giftHotline',
      { ph, countryPhCode },
      (res) => {
        success('Консультацію подаровано');
        setOpen(false);
      },
      (s1, s2, res) => {
        error(res.data.error);
      },
    ).finally(() => {
      setLoading(false);
    });
  };

  const onChange = (code) => setCountryPhCode(code);

  return (
    <ModalWindow className="gift-hotline" title={`Вкажіть номер телефону отримувача`} setModal={setOpen} modal={open}>
      <Spin tip={translation.loader[lang]} spinning={loading}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: 'flex' }}>
            <PhoneCodeSwitcher countryPhCode={countryPhCode} setCountryPhCode={setCountryPhCode} onChange={onChange} value={countryPhCode} />
            <Input
              type="tel"
              placeholder="xxxxxxxxxx"
              {...register('ph', {
                required: {
                  value: true,
                  message: "Це обв'язкове поле",
                },
                pattern: {
                  value: /^0{1}[0-9]{9}$/,
                  message: 'Неправильний номер телофону',
                },
              })}
            />
          </div>
          <div style={{ marginBottom: '140px' }}>{errors.ph?.message && <span className="error-text">{errors.ph.message}</span>}</div>
          <Button type="submit">Підтвердити</Button>
        </form>
      </Spin>
    </ModalWindow>
  );
};
export default GiftHotline;

import ModalWindow from '../ModalWindow';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../store/commonReducer';
import { CHAT_FOR_CONTRACT } from 'common_constants/modals';
import { RED_BUTTON, CHAT_TYPES } from 'common_constants/business';
import { translation } from 'common_constants/translation';
import { getFormattedDateWithRelativeDays } from 'common_components';
import UniversalItemButton from '../../components/UniversalItemButton/UniversalItemButton';
import { MessageOutlined, PhoneOutlined, PaperClipOutlined } from '@ant-design/icons';
import reactNativeService from '../../tools/reactNativeService';

const RedButtonContactModal = ({ open, setOpen, redButton }) => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const openChat = (sendFileDirectly) => {
    if (window?.ReactNativeWebView) {
      reactNativeService.sendMessageToWebview({ type: 'navigateToChat', data: { chatId: redButton._id, type: CHAT_TYPES.chatWithClient.key } });
      return;
    }
    dispatch(
      setModal({
        name: CHAT_FOR_CONTRACT,
        data: {
          contractId: redButton._id,
          contractTitle:
            translation.clientContractInfoChat[lang] +
            ' ' +
            (redButton?.n
              ? '«' + redButton.n + '»'
              : '«' + redButton?.cn + '»'
              ? '«' + (redButton.cn === RED_BUTTON.value ? RED_BUTTON.label : redButton.cn) + '»'
              : `№${redButton?.i}`),
          curator: translation.specialist[lang],
          sendFileDirectly,
        },
      }),
    );
  };

  return (
    <ModalWindow title={translation.redButtonModalTitle[lang]} modal={open} setModal={setOpen}>
      <UniversalItemButton link icon={<PhoneOutlined />} title={translation.callBtn[lang]} />
      <UniversalItemButton icon={<MessageOutlined />} onClick={openChat} title={translation.redButtonModalMessage[lang]} />
      <UniversalItemButton icon={<PaperClipOutlined />} onClick={() => openChat(true)} title={translation.sendFileBtn[lang]} />
    </ModalWindow>
  );
};

export default RedButtonContactModal;

import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { CLUB_CARD_PRICES, CLUB_CARDS_TYPES, PAYMENT_STATUS, CLUB_CARDS_PERIODS, CURRENCIES } from 'common_constants/business';
import { translation } from 'common_constants/translation';

import { request, error, success, warn } from '../../../tools';
import { setModal } from '../../../store/commonReducer';
import { CheckClientInfo } from '../../../tools/hooks';

import ModalWindow from '../../../Modals/ModalWindow';
import './BuyClubCardModal.scss';
import ClubCardInfoAccordion from './ClubCardInfoAccordion';
import reactNativeService from '../../../tools/reactNativeService';

const BuyClubCardModal = ({ open, setOpen, title, clubCard, currentTypeCard }) => {
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.common.language);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const currentCurrency = clientInfo?.currency ?? CURRENCIES.UAH;
  const periodCardWasPurchased = Object.keys(clubCard?.services).length;
  const currentPeriod = CLUB_CARDS_PERIODS[periodCardWasPurchased];

  const [loading, setLoading] = useState(false);
  const [cardType, setCardType] = useState(clubCard?.cardType);
  const [period, setPeriod] = useState(currentPeriod ? currentPeriod : 0);
  const [updateClubCardWarn, setUpdateClubCardWarn] = useState(false);

  const isCurrentCardSilverCard = clubCard?.cardType === CLUB_CARDS_TYPES.SILVER;
  const isSilverCard = cardType === CLUB_CARDS_TYPES.SILVER;
  const firstCardTypePrice = CLUB_CARD_PRICES[0][cardType]?.AMOUNT;
  const selectedCardTypePrice = CLUB_CARD_PRICES[period]?.[cardType]?.AMOUNT;
  const periodMonths = CLUB_CARD_PRICES[period]?.PERIOD.MONTHS;
  const totalFirstTypePrice = firstCardTypePrice * periodMonths;
  const totalSecondTypePrice = selectedCardTypePrice * periodMonths;
  const priceDifference = totalFirstTypePrice - totalSecondTypePrice;
  const currency = translation.currencyUah[lang];
  const silverCardNavTitleClass = isSilverCard ? 'checked' : '';
  const goldCardNavTitleClass = cardType === CLUB_CARDS_TYPES.GOLD ? 'checked' : '';
  const platinumCardNavTitleClass = cardType === CLUB_CARDS_TYPES.PLATINUM ? 'checked' : '';
  const isTariffHasNotChanged =
    clubCard && clubCard.cardType === cardType && CLUB_CARD_PRICES[period].PERIOD.MONTHS === Object.keys(clubCard.services).length;

  const checkboxClass = (index) => (period === index ? 'checked' : '');

  const periodsRef = useRef(null);
  const handleChangePeriod = () => {
    if (isCurrentCardSilverCard) {
      if (isSilverCard) return;
      periodsRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    setCardType(clubCard?.cardType);
    if (periodsRef.current) {
      periodsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleBuyClubCard = () => {
    const body = { cardType, period, currency: currentCurrency };
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          CheckClientInfo(dispatch);
          success(translation.clubCardPurchased[lang]);
          setLoading(false);
          setOpen(false);
        }, 1000);
      }
      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              success(translation.clubCardPurchased[lang]);
              setLoading(false);
              setOpen(false);
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              error(translation.addInsuranceError[lang]);
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
      }
    };

    const onError = (_, __, axiosError) => {
      error('Помилка оплати!', axiosError.error);
      setLoading(false);
    };

    setLoading(true);
    request('/payment/mono/clubCard/link', body, onSuccess, onError);
  };

  const handleBuyClubCardUsual = () => {
    if (isTariffHasNotChanged) {
      warn(translation.alert[lang], `${translation.clubCardWarn_1[lang]} ${cardType} ${translation.clubCardWarn_2[lang]}`);
      return;
    }
    if (clubCard.cardType !== CLUB_CARDS_TYPES.SILVER) {
      setUpdateClubCardWarn(true);
      return;
    }
    handleBuyClubCard();
  };
  const handleBuyClubCardReplacement = () => {
    if (isTariffHasNotChanged) {
      warn(translation.alert[lang], `${translation.clubCardWarn_1[lang]} ${cardType} ${translation.clubCardWarn_2[lang]}`);
      setUpdateClubCardWarn(false);
      return;
    }
    setUpdateClubCardWarn(false);
    handleBuyClubCard();
  };

  const rowItems = isSilverCard
    ? ''
    : Object.keys(CLUB_CARD_PRICES).map((item, index) => (
        <li key={index} className="row" onClick={() => setPeriod(index)}>
          <div className={`checkbox ${checkboxClass(index)}`}>
            <span></span>
            <span></span>
          </div>
          <div className={`label ${checkboxClass(index)}`}>
            <span>{translation.clubCardPeriod[index][lang]}</span>
            <span>
              {CLUB_CARD_PRICES[index][cardType].LABEL} {translation.currencyUah[lang]}/{translation.month[lang]}
            </span>
          </div>
        </li>
      ));

  useEffect(() => {
    setLoading(false);
    setOpen(false);
  }, [clientInfo?.clubCard]);

  return (
    <ModalWindow title={''} modal={open} setModal={setOpen}>
      <div className="clubCardPay">
        <div className="inner">
          <div className="tab" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div className={`item ${silverCardNavTitleClass}`} onClick={() => setCardType(CLUB_CARDS_TYPES.SILVER)}>
              {translation.clubCardType.silver[lang]}
            </div>
            <span className={`item ${goldCardNavTitleClass}`} onClick={() => setCardType(CLUB_CARDS_TYPES.GOLD)}>
              {translation.clubCardType.gold[lang]}
            </span>
            <div className={`item ${platinumCardNavTitleClass}`} onClick={() => setCardType(CLUB_CARDS_TYPES.PLATINUM)}>
              {translation.clubCardType.platinum[lang]}
            </div>
          </div>
          {!isCurrentCardSilverCard ? (
            <div className="currentTariffWrapper">
              <p>
                {translation.cardTypeTitle[lang]}: <span>{translation.clubCardType[currentTypeCard][lang]}</span>
              </p>
              <p>
                {translation.currentPeriod[lang]}: <span>{translation.clubCardPeriod[currentPeriod][lang]}</span>
              </p>
              <p>
                {translation.addInsuranceCost[lang]}
                <span>
                  {CLUB_CARD_PRICES[currentPeriod][clubCard?.cardType].LABEL} {translation.currencyUah[lang]}/{translation.month[lang]}
                </span>
              </p>
              <p>
                <button type="button" onClick={handleChangePeriod}>
                  {translation.choosePeriod[lang]} {cardType === CLUB_CARDS_TYPES.GOLD ? '65%' : '40%'}!
                </button>
              </p>
            </div>
          ) : (
            <button type="button" className="button-silver" onClick={handleChangePeriod}>
              {translation.choosePeriod[lang]} {cardType === CLUB_CARDS_TYPES.GOLD ? '65%' : '40%'}!
            </button>
          )}

          <ClubCardInfoAccordion cardType={cardType} />
          <ul className="body" ref={periodsRef}>
            {rowItems}
          </ul>
          {!isSilverCard && (
            <>
              <div className="total">
                <span className="title">{translation.savings[lang]}</span>
                <span className="amount">
                  {priceDifference}&nbsp;{currency}
                </span>
              </div>
              <div className="total">
                <span className="title">{translation.totalPrice[lang]}</span>
                <span className="amount">
                  {selectedCardTypePrice * periodMonths} {translation.currencyUah[lang]}
                </span>
              </div>{' '}
            </>
          )}
        </div>
        {!isSilverCard ? (
          updateClubCardWarn ? (
            <div className="warn-wrapper">
              <h3>{translation.clubCardWarnText_1[lang]}</h3>
              <h3>{`${translation.clubCardWarnText_2[lang]} ${dayjs(clubCard.expiredAt).format('YYYY. MM. DD')}`}</h3>

              <button onClick={handleBuyClubCardReplacement} className={`button${loading ? ' loading' : ''}`}>
                {translation.buyClubCard[lang]}
              </button>
            </div>
          ) : (
            <button onClick={handleBuyClubCardUsual} className={`button${loading ? ' loading' : ''}`}>
              {translation.buyClubCard[lang]}
            </button>
          )
        ) : (
          <button className={`button`}>{translation.clubCardWarnTextFreeCard[lang]} </button>
        )}
      </div>
    </ModalWindow>
  );
};

export default BuyClubCardModal;

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'common_constants/routes';
import { translation } from 'common_constants/translation';
import { PAYMENT_STATUS, CURRENCIES } from 'common_constants/business';
import dayjs from 'dayjs';

import ClubCard from '../../components/ClubCard';
import ClientButton from '../../components/ClientButton/ClientButton';
import SectionName from '../../components/SectionName';
import InviteButton from '../../components/Balance/BalanceNewButton';
import { Spin } from '../../components';
import { WebinarSwiper } from './WebinarSwiper/webinarSwiper';
import { error, request, success, countContractDebt } from '../../tools';
import { CheckClientInfo } from '../../tools/hooks';
import reactNativeService from '../../tools/reactNativeService';
import { NewsSwiper } from './newsSwiper';
import OfficeSwiper from './OfficeSwiper';
import WantedBlock from './WantedBlock.jsx';

import ContractsImage from './images/contracts.svg';
import HotlineImage from './images/hotline.svg';

import './Homepage.scss';

const Homepage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const contracts = useSelector((state) => state.common.contracts) ?? [];
  const hotlines = useSelector((state) => state.common.hotlines) ?? [];
  const lang = useSelector((state) => state.common.language);
  const clientAuth = useSelector((state) => state.common.clientAuth);

  const { currency = 0, clientBalance = '' } = clientInfo;
  const [loading, setLoading] = useState(false);

  if (!clientAuth.phoneVerified) {
    history.push(ROUTES.CLIENT_VERIFICATION_PHONE);
    return;
  }
  if (!clientAuth.policyConfirmed) {
    history.push(ROUTES.CLIENT_POLICY_CONFIRM);
    return;
  }

  const countContractsDebt = () => {
    const notArchivedContracts = contracts.filter((contract) => !contract?.ad);
    const countContractsPayments =
      notArchivedContracts.map((contract) => {
        const debt = countContractDebt(contract);
        return {
          debt,
          contractId: contract._id,
          contractIndex: contract.i,
          clientId: contract?.C,
          contractName: contract?.rb ? 'Тривожна кнопка' : contract?.cn ? contract.cn : dayjs(contract?.sd).format('DD.MM.YYYY'),
          editPMonceAccess: contract?.editPMonceAccess,
        };
      }) ?? [];
    const debtContracts = countContractsPayments.filter((item) => item.debt > 0);
    return debtContracts;
  };

  const debtContracts = countContractsDebt();

  const payContractDebt = (contract) => {
    const body = {
      clientId: contract?.clientId,
      amount: contract?.debt,
      contractIndex: contract?.contractIndex,
      contractId: contract?.contractId,
      fil: contract?.f,
      currency: currency,
    };
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location = res.checkoutUrl;
        return;
      }
      if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
        setTimeout(() => {
          CheckClientInfo(dispatch);
          history.push(ROUTES.CLIENT_HOME);
          success(translation.payContractDebt[lang]);
          setLoading(false);
        }, 1000);
      }
      if (res.paymentStatus === PAYMENT_STATUS.PROCESSING) {
        const timerId = setInterval(() => {
          const onSuccess = (res) => {
            if (res.paymentStatus === PAYMENT_STATUS.SUCCESS) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              history.push(ROUTES.CLIENT_HOME);
              success(translation.payContractDebt[lang]);
              setLoading(false);
            }
            if (res.paymentStatus === PAYMENT_STATUS.FAILURE) {
              clearInterval(timerId);
              CheckClientInfo(dispatch);
              error(translation.addInsuranceError[lang]);
              setLoading(false);
            }
          };
          request('/payment/mono/status', { invoiceId: res.invoiceId }, onSuccess, onError);
        }, 1000);
      }
    };

    const onError = (_, __, axiosError) => {
      const errorMessage = axiosError?.error || translation.addInsuranceError[lang];
      error(errorMessage);
      setLoading(false);
    };

    const onSuccessPayFromBalance = () => {
      success(translation.payContractDebt[lang]);
      CheckClientInfo(dispatch);
      setLoading(false);
    };
    const onErrorPayFromBalance = () => {
      error('', translation.addInsuranceError[lang]);
      setLoading(false);
    };

    setLoading(true);

    if (clientBalance >= contract.debt) {
      request('/payment/payContractDebtFromBalance', body, onSuccessPayFromBalance, onErrorPayFromBalance);
    } else {
      request('/payment/mono/payContractDebt/link', body, onSuccess, onError);
    }
  };
  const DebtContractsRender = () => {
    return debtContracts.map((item) => {
      if (item?.editPMonceAccess) return null;
      return (
        <div key={item.contractId} style={{ padding: '0 16px' }}>
          <div className="debt-item">
            <div className="debt-content color-red title">{translation.debtName[lang]}</div>
            <div className="debt-content">
              {translation.debtContentContract[lang]} {item.contractName}
            </div>
            <div className="debt-content color-red title">
              {item.debt} {CURRENCIES[clientInfo.currency].symbol}
            </div>
            <InviteButton onClick={() => payContractDebt(item)} buttonText={translation.payBtn[lang]} arrow={true} />
            <br />
          </div>
        </div>
      );
    });
  };

  const combinedNotifEventsPartHotline = hotlines?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0,
    [combinedNotifCorrespondence, combinedNotifTasks, combinedNotifEvents] = [
      contracts.reduce((combined, contract) => {
        combined.push(
          ...(contract.correspondence || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)),
        );
        return combined;
      }, [])?.length ?? 0,
      contracts.reduce((combined, contract) => {
        combined.push(...(contract.tasks || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)));
        return combined;
      }, [])?.length ?? 0,
      combinedNotifEventsPartHotline +
        (hotlines?.filter((item) => item.s)?.filter((item) => !item.notif?.some((notifItem) => notifItem.watched))?.length || 0) +
        (contracts.reduce((combined, contract) => {
          combined.push(...(contract.assignments || []).filter((item) => !item.notif || !item.notif.some((notifItem) => notifItem.watched === true)));
          return combined;
        }, [])?.length ?? 0),
    ],
    multiNotificationsInContracts = combinedNotifCorrespondence + combinedNotifTasks + combinedNotifEvents;

  return (
    <Spin spinning={loading} tip={translation.loader1[lang]}>
      <div className="home-page-content">
        <WantedBlock />
        <DebtContractsRender />
        <SectionName text={translation.clubCard[lang]} />
        <div className="homepage-club-card">
          <ClubCard />
        </div>
        <div className="homepage-menu">
          <div className="client-menu">
            <ClientButton
              text={translation.myBusiness[lang]}
              link={ROUTES.CLIENT_CONTRACTS}
              icon={ContractsImage}
              badge={contracts?.length > 0 ? multiNotificationsInContracts : 0}
            />
            <ClientButton
              text={translation.myConsultations[lang]}
              link={ROUTES.CLIENT_MY_HOTLINES}
              icon={HotlineImage}
              badge={combinedNotifEventsPartHotline}
            />
          </div>
        </div>

        <WebinarSwiper />
        <SectionName text={translation.news[lang]} />
        <NewsSwiper />
        <SectionName text={translation.office[lang]} />
        <OfficeSwiper />
      </div>
    </Spin>
  );
};

export default Homepage;

import React from 'react';
import { useSelector } from 'react-redux';
import { translation } from 'common_constants/translation';
import dayjs from 'dayjs';

const WantedBlock = () => {
  const { wanted } = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);

  return (
    <div className="wanted-block-container">
      <div className="wanted-block">
        {wanted?.status === 'searched' ? (
          <div>
            <p className="status _searched">{translation.WantedSearchedStatus[lang]}</p>
            <div className="details-wrapper">
              <p>
                <b>{translation.name[lang]}: </b>
                {wanted?.data?.full_name ?? '-'}
              </p>
              <p>
                <b>{translation.birthTitle[lang]}: </b>
                {wanted?.data?.birth_date ?? '-'}
              </p>
              <p>
                <b>{translation.LostDate[lang]}: </b>
                {wanted?.data?.lost_date ?? '-'}
              </p>
              <p>
                <b>{translation.Region[lang]}: </b>
                {wanted?.data?.lost_place ?? '-'}
              </p>
              <p>
                <b>{translation.Category[lang]}: </b>
                {wanted?.data?.category ?? '-'}
              </p>
              <p>
                <b>{translation.Article[lang]}: </b>
                {wanted?.data?.article_crim ?? '-'}
              </p>
            </div>
          </div>
        ) : (
          <div>
            <p className="status">{translation.WantedNotSearchedStatus[lang]}</p>
          </div>
        )}
        {wanted?.updatedAt ? (
          <p className="updatedAt-text">
            {translation.Updated[lang]}: {dayjs(wanted.updatedAt)?.format('DD.MM.YYYY HH:mm')}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default WantedBlock;

import { useState, useEffect, useRef } from 'react';
import Input from '../../components/Input/Input';
import { Spin, Modal, Button } from '../../components';
import { SendOutlined } from '@ant-design/icons';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { useDispatch, useSelector } from 'react-redux';
import { request, error } from '../../tools';
import { setModal } from '../../store/commonReducer';
import axios from 'axios';

import Message from '../../components/Chat/Message';
import { ReactComponent as Pen } from '../../images/pen.svg';

import './style.scss';
import { translation } from 'common_constants/translation';

const DozhimsAI = () => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [sendLoader, setSendLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [threadId, setThreadId] = useState('');

  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    }),
  );

  const getMessages = async () => {
    request(
      '/dozhimsAI/getChat',
      { userId: clientAuth._id },
      ({ chat }) => {
        setMessages(
          chat?.map((item) => ({
            message: item.message,
            role: item.sender,
            date: item.date,
            isSentByCurrentUser: item.sender === 'user',
            name: item.sender === 'user' ? clientInfo.n : 'Помічник',
          })) ?? [],
        );
      },
      error,
    );
  };

  const getDisplay = async () => {
    await getMessages();
    await createThread();

    setIsLoading(false);
  };

  useEffect(() => {
    getDisplay();
  }, []);

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const makeMyMessage = (text) => ({
    message: text,
    isSentByCurrentUser: true,
    date: new Date(),
    name: clientInfo.n,
    role: 'user',
  });

  const makeAssistantMessage = (text) => ({
    message: text,
    isSentByCurrentUser: false,
    date: new Date(),
    name: 'Помічник',
    role: 'assistant',
  });

  const handleSend = async () => {
    if (inputValue === '') return;

    setInputValue('');
    setSendLoader(true);

    setMessages((prev) => [...prev, makeMyMessage(inputValue)]);

    await sendMessage(inputValue);

    let errorOccurred;
    let lastMessage;
    do {
      errorOccurred = false;
      try {
        const run = await runsAssistant();

        let status = await checkAssistantRuns(run);

        while (status === 'in_progress' || status === 'queued') {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          status = await checkAssistantRuns(run);
        }

        lastMessage = await getLastMessage(run);
      } catch (error) {
        errorOccurred = true;
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } while (errorOccurred);

    if (lastMessage) {
      cache.current.clearAll();
      setSendLoader(false);
      setMessages((prev) => [...prev, makeAssistantMessage(lastMessage)]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSend();
    }
  };

  const createThread = async () => {
    let errorOccurred;
    do {
      errorOccurred = false;
      try {
        request('/dozhimsAI/createThread', {}, (res) => {
          setThreadId(res.threadId);
        });
      } catch (error) {
        errorOccurred = true;
      }
    } while (errorOccurred);
  };

  const runsAssistant = async () => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');

      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/dozhimsAI/runAssistant`,
        { threadId: threadId },
        {
          headers: headers,
        },
      );

      return response.data.runId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const sendMessage = async (message) => {
    await request('/dozhimsAI/sendMessage', { threadId: threadId, message: message, userId: clientAuth._id }, () => {});
  };

  const checkAssistantRuns = async (runId) => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/dozhimsAI/checkAssistantRun`,
        { threadId: threadId, runId: runId },
        {
          headers: headers,
        },
      );

      return response.data.contour;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getLastMessage = async (runId) => {
    try {
      const token = window.localStorage.getItem('clientAccessToken');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(
        `${process.env.REACT_APP_API}/dozhimsAI/getAssistantMessage`,
        { threadId: threadId, userId: clientAuth._id, runId: runId },
        {
          headers: headers,
        },
      );

      return response.data.lastMessage;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return (
    <Modal open title={translation.chatForDozhimsAITitle[lang]} onCancel={() => dispatch(setModal())} footer={false}>
      <Spin spinning={isLoading}>
        <div className="dozhims-AI-page">
          <div className="list">
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={cache.current.rowHeight}
                  deferredMeasurementCache={cache.current}
                  rowCount={messages.length}
                  scrollToIndex={messages.length - 1}
                  overscanRowCount={0}
                  rowRenderer={({ key, index, style, parent }) => {
                    const message = messages[index];
                    return (
                      <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                        <div style={style}>
                          <Message item={message} onDownload={() => {}} />
                        </div>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
          {sendLoader && (
            <div className="response-status">
              <span>{translation.answerQuickQuestion[lang]}</span>
              <Pen className="pen-icon" />
            </div>
          )}
          <div className="input-container">
            <Input
              placeholder={translation.inputQuickQuestion[lang]}
              value={inputValue}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
              disabled={sendLoader}
            />
            {sendLoader ? (
              <Spin className="spin" />
            ) : (
              <Button type="default straight-left" style={{ margin: '0px' }} onClick={handleSend} loading={sendLoader} icon={<SendOutlined />} />
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default DozhimsAI;

import { translation } from 'common_constants/translation';
import Input from '../../Input';
import { useState } from 'react';
import { request } from '../../../tools';
import Button from '../../Button';
import Modal from '../../Modal';

const ApproveDeleteAccountModal = ({ removeModal, setRemoveModal, id, lang, ph }) => {
  const [approve, setApprove] = useState('');
  const removeAccount = () => {
    request('/auth/clientRemoveAccaunt', { _id: id }, (res) => {
      window.localStorage.removeItem('clientAccessToken');
      window.location.href = '/';
    });
  };
  return (
    <Modal
      title={translation.accountDeletionModalTitle[lang]}
      open={removeModal}
      onCancel={() => setRemoveModal(false)}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <p>{translation.accountDeletionModalEnterPhone[lang]}</p>
      <Input placeholder={translation.accountDeletionModalEnterPhonePlaceholder[lang]} value={approve} onChange={(e) => setApprove(e.target.value)} />

      {approve && approve !== ph && <p style={{ color: 'red' }}>{translation.accountDeletionModalEnterPhoneError[lang]}</p>}
      <Button danger disabled={ph !== approve} onClick={removeAccount}>
        {translation.deleteBtn[lang]}
      </Button>
    </Modal>
  );
};
export default ApproveDeleteAccountModal;

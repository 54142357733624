import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClientInfo } from '../../store/commonReducer';
import { error, request } from '../../tools';

import { Popconfirm, Button, Message } from '../';
import { useMessage } from '../../tools/hooks';
import reactNativeService from '../../tools/reactNativeService';
import { translation } from 'common_constants/translation';
import './CardForPayments.scss';

const CardForPayments = () => {
  const dispatch = useDispatch();
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const lang = useSelector((state) => state.common.language);
  const { messages, showMessage } = useMessage();
  const handleOpenPayWindow = () => {
    const onSuccess = (res) => {
      if (res.checkoutUrl) {
        reactNativeService.sendMessageToWebview({ type: 'handlePayment', data: { paymentUrl: res.checkoutUrl } });
        window.location.href = res.checkoutUrl;
      }
    };
    const onError = (_, __, axiosError) => error('', 'axiosError.error');
    request('/payment/mono/bindCard/link', {}, onSuccess, onError);
  };
  const confirm = (e) => {
    request(
      '/payment/mono/deleteCard',
      {},
      (res) => {
        showMessage('success', translation.cardForPaymentsSuccess[lang]);
        dispatch(
          setClientInfo({
            ...clientInfo,
            cardForPayment: null,
            paymentCard: null,
          }),
        );
      },
      () => {
        showMessage('error', translation.cardForPaymentsError[lang]);
      },
    );
  };
  return (
    <div className="cardForPayments">
      <div className="messages-container">
        {messages.map((message) => (
          <Message key={message.id} type={message.type} content={message.content} zIndex={message.zIndex} />
        ))}
      </div>
      {!clientInfo.paymentCard ? (
        <>
          <div>{translation.bindtheCard[lang]}</div>
          <Popconfirm
            title={translation.popconfirmTitle[lang]}
            description={
              <div>
                <div>{translation.popconfirmText1[lang]}</div>
                <div>{translation.popconfirmText2[lang]}</div>
              </div>
            }
            onConfirm={handleOpenPayWindow}
            okText={translation.yes[lang]}
            cancelText={translation.no[lang]}
          >
            <div className="cardForPayments_button">+</div>
          </Popconfirm>
        </>
      ) : null}

      {clientInfo.paymentCard ? (
        <>
          <div style={{ margin: '0', display: 'flex', alignItems: 'center' }}>{clientInfo.paymentCard.maskedPan}</div>
          <Popconfirm
            title={translation.popconfirmRemoveTitle[lang]}
            description={translation.popconfirmRemoveDescription[lang]}
            onConfirm={confirm}
            okText={translation.yes[lang]}
            cancelText={translation.no[lang]}
          >
            <Button danger style={{ marginTop: '8px' }}>
              {translation.deleteBtn[lang]}
            </Button>
          </Popconfirm>
        </>
      ) : null}
    </div>
  );
};

export default CardForPayments;

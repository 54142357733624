import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import clsx from 'clsx';

import SwiperRefresh from './components/SwipeRefresh';
import RouterSwitch from './RouterSwitch';
import AuthControl from './components/AuthControl';
import MainLoader from './components/MainLoader';
import Modals from './Modals';
import {
  useControllView,
  useClientInfo,
  useLanguage,
  useAuthData,
  useConnectSocket,
  useListenMessages,
  useListenWebviewMessages,
  useCheckForGift,
  useSetLanguageByIpAddress,
} from './tools/hooks';
import ClientMenu from './components/ClientMenu/ClientMenu';
import Ticker from './components/Ticker';
import AppSuggestionNotification from './components/AlertNotificationCustom/AppSuggestionNotification/AppSuggestionNotification';
import Swipe from './components/Swipe';
import { Toaster } from './components/Toast';
import Confetti from './components/Confetti';
import LanguageDetector from './components/LanguageDetector/LanguageDetector';

export default function App() {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const clientInfo = useSelector((state) => state.common.clientInfo);
  const { mainLoader, mobSidebar } = useSelector((state) => state.ui);
  const appPrepared = useSelector((state) => state.common.appPrepared);

  useControllView();
  useClientInfo();
  useSetLanguageByIpAddress();
  useLanguage();
  useAuthData();
  useConnectSocket();
  useListenMessages();
  useListenWebviewMessages();
  useCheckForGift();

  if (clientAuth && !appPrepared)
    return (
      <Router>
        <AuthControl />
        <MainLoader show={true} />
      </Router>
    );

  return (
    <Router>
      <AuthControl />
      <LanguageDetector />
      <main className={clsx('ant-layout layout', clientInfo && 'clientAuth')}>
        {clientInfo?._id && clientAuth?.phoneVerified && clientAuth?.policyConfirmed ? <ClientMenu /> : null}
        <Ticker />
        <section className={clsx('ant-layout-content', mobSidebar && clientInfo && 'mobDisabled')}>
          <SwiperRefresh />
          <RouterSwitch />
          <AppSuggestionNotification />
        </section>
      </main>
      <MainLoader show={mainLoader} />
      <Modals />
      <Swipe />
      <Toaster />
      <Confetti />
    </Router>
  );
}

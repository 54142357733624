import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toasts: [],
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action) => {
      state.toasts.push(action.payload);
    },
    removeToast: (state, action) => {
      state.toasts = state.toasts.filter((toast) => toast.id !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { addToast, removeToast } = toastSlice.actions;

export default toastSlice.reducer;

import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import relativeTime from 'dayjs/plugin/relativeTime';
import { translation } from 'common_constants/translation';
import { TaskActions } from './TaskActions';
import Badge from '../../../components/Badge/Badge';

dayjs.extend(relativeTime);

const getDate = (date) => {
  return dayjs(date).fromNow();
};

const ContractTaskItem = ({ item, index, rejectTask, setDoneTask, lang }) => {
  const handleSetDoneTask = () => {
    if (!item.rejected) setDoneTask(index);
  };

  const handleRejectTask = () => {
    if (!item.done) rejectTask(index);
  };

  const isOutdated = (item) => {
    return new Date() > new Date(item?.deadline) && !item?.done && !item?.rejected;
  };

  const commentList = item?.comments
    ?.flatMap((comment, key, arr) => [
      <div key={key} className="comment-item">
        <Badge count={comment.userNotSaw} className="comment-badge">
          <p className="date">{getDate(comment?.created)}</p>
          <p style={{ maxWidth: '75%', marginBottom: 8 }}>{comment.comment}</p>
        </Badge>
      </div>,
      key < arr.length - 1 && <hr key={`sep-${key}`} style={{ border: '0.5px solid #ccc', margin: '16px 0' }} />,
    ])
    .filter(Boolean);

  const currentDate = (item?.deadline ? new Date(item?.deadline) : new Date()).toLocaleString('UK-ua', {
    day: '2-digit',
    month: 'numeric',
    year: 'numeric',
  });

  return (
    <form className="form-edit-task" onSubmit={(e) => e.preventDefault}>
      <div className="task-item">
        {item?.rejected ? <span className="rejected-label">{translation.contractTasksText1[lang]}</span> : null}
        {item?.done ? <span className="done-label">{translation.contractTasksText4[lang]}</span> : null}
        <div className={`task-item-date ${isOutdated(item) ? 'outdated' : ''}`}>
          <p>{currentDate}</p>
        </div>
        <div className="row-bottom">
          <div className="form-group">
            <p>{item?.title}</p>
          </div>
          <TaskActions item={item} rejectTask={handleRejectTask} setDoneTask={handleSetDoneTask} lang={lang} />
        </div>
        <div className="comments">{commentList}</div>
      </div>
    </form>
  );
};

export default ContractTaskItem;

import React from 'react';
import './Badge.scss';

const Badge = (props) => {
  const { color, count, offset, className, children } = props;

  const badgeStyle = {
    color: color ? color : 'white',
    backgroundColor: count ? '#ff5d5d' : 'transparent',
  };

  return (
    <div>
      <div
        className={`${className ? className : ''}`}
        style={{
          ...(props.style ? props.style : badgeStyle),
          marginTop: offset?.[1],
          right: -offset?.[0],
        }}
      >
        {count ? count : ''}
      </div>
      {children}
    </div>
  );
};

const Ribbon = ({ text, color, placement = 'end', children, className, style, ...props }) => {
  let cornerStyles
  if (placement === 'start') {
    cornerStyles = { borderRightColor: color, borderTopColor: color }
  } else {
    cornerStyles = { borderLeftColor: color, borderTopColor: color }
  }
  return (
    <div className={`ribbon-wrapper ${className ? className : ''}`} {...props}>
      {children}
      <div className={`ribbon ${placement}`} style={{ ...style, backgroundColor: color }}>
        {text}
        <div className="ribbon-corner" style={cornerStyles} />
      </div>
    </div>
  );
};

Badge.Ribbon = Ribbon;
export default Badge;

import dayjs from 'dayjs';
import './FeedbackAnswer.scss';
import { translation } from 'common_constants/translation';
import { useSelector } from 'react-redux';

const FeedbackAnswer = ({ name, message, date, img, jobPosition }) => {
    const lang = useSelector((state) => state.common.language);
  return (
    <div className="feedback-answer">
      <div className="feedback-answer-info mb-3">
        <div className="feedback-answer-photo">
          <div className="feedback-answer-photo-img">
            {img ? (
              <img src={process.env.REACT_APP_API + `/avatars/${img}.jpeg`} alt="client avatar" />
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" fill="#a3c6d2" viewBox="0 0 448 512">
                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
              </svg>
            )}
          </div>
        </div>
        <div>
          <h3 className="feedback-answer-name">{name}</h3>

          <p className="feedback-answer-position">{jobPosition}</p>
        </div>
      </div>

      <p className="feedback-answer-text">{message}</p>
      <p className="feedback-answer-signature">{translation.FeedbackAnswer[lang]} {name}</p>

      <div className="feedback-answer-date">{dayjs(date).format('DD-MM-YYYY')}</div>
    </div>
  );
};

export default FeedbackAnswer;

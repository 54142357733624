import React, { useEffect, useState } from 'react';
import './SwipeRefresh.scss';
import { useDispatch, useSelector } from 'react-redux';
import { request, error } from '../../tools';
import { setUsersData, setClientInfo } from '../../store/commonReducer';

const SWIPE_TO_REFRESH = 68;

const SwiperRefresh = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState();
  const [loaderY, setLoaderY] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) return;
    const pullStart = (e) => {
      const { clientY } = e.targetTouches[0];

      setStartPoint(clientY + document.getElementById('root').scrollTop);
    };
    const pullCancel = (e) => {
      setStartPoint(null);
      setPullChange(null);
    };

    window.addEventListener('touchstart', pullStart);
    window.addEventListener('touchcancel', pullCancel);

    return () => {
      window.removeEventListener('touchstart', pullStart);
      window.removeEventListener('touchcancel', pullCancel);
    };
  }, [isLoading]);

  useEffect(() => {
    const pull = (e) => {
      if (!startPoint) {
        setPullChange(0);
        return;
      }
      e.preventDefault();
      const touch = e.targetTouches[0];
      const { clientY } = touch;
      let pullLength = clientY - startPoint;
      setPullChange(() => {
        if (pullLength <= SWIPE_TO_REFRESH) return pullLength * 0.7;
        return ((pullLength - SWIPE_TO_REFRESH) * 0.85 + SWIPE_TO_REFRESH) * 0.7;
      });
    };

    window.addEventListener('touchmove', pull);
    return () => {
      window.removeEventListener('touchmove', pull);
    };
  }, [startPoint]);

  useEffect(() => {
    const refresh = async () => {
      await Promise.all([
        request(
          '/auth/getCrmUsersForClient',
          {},
          ({ data }) => {
            dispatch(setUsersData(data));
          },
          error,
        ),
        request(
          '/clients/getClientInfo',
          null,
          (req) => {
            const { data } = req;
            if (!data) return;
            dispatch(setClientInfo(data));
          },
          error,
        ),
      ]);
    };

    const pullEnd = async () => {
      setPullChange(0);
      setStartPoint(null);

      if (pullChange >= SWIPE_TO_REFRESH) {
        setIsLoading(true);
        setLoaderY(130);
        await refresh();
        setLoaderY(null);
        setIsLoading(false);
      }
    };
    window.addEventListener('touchend', pullEnd);
    return () => {
      window.removeEventListener('touchend', pullEnd);
    };
  }, [pullChange]);

  return (
    <>
      <div
        className="swipe-refresh-icon"
        style={{
          transform: `translateY(${loaderY ?? pullChange}px) translateX(-50%) rotate(${(pullChange / SWIPE_TO_REFRESH) * 360}deg)`,
          transition: startPoint ? '' : '0.5s ease-in-out',
        }}
      >
        <div className={isLoading ? 'swipe-refresh-icon-rotating' : ''} />
      </div>
    </>
  );
};

const AuthWrappedSwipeRefresh = () => {
  const clientAuth = useSelector((state) => state.common.clientAuth);

  if (!clientAuth) return null;

  return <SwiperRefresh />;
};

export default AuthWrappedSwipeRefresh;

import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../store/commonReducer';
import { languageOptions } from 'common_constants/translation';
import SelectNew from '../SelectNew';
import reactNativeService from '../../tools/reactNativeService';

import GB from 'country-flag-icons/react/3x2/GB';
import UA from 'country-flag-icons/react/3x2/UA';
import DE from 'country-flag-icons/react/3x2/DE';

import './LanguageSwitcher.scss';
import { useForm } from 'react-hook-form';

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.common.language);
  const {control} = useForm({
    defaultValues: {
      language: new URL(window.location.href)?.searchParams.get('lang') || currentLang
    },
  })
  const handleLanguageChange = (value) => {
    dispatch(setLanguage(value));
    reactNativeService.sendMessageToWebview({ language: value });
    localStorage.setItem('language', value);
  };

  const optionsWithFlags = languageOptions.map((option) => {
    let flag;
    switch (option.value) {
      case 'ua':
        flag = <UA />;
        break;
      case 'en':
        flag = <GB />;
        break;
      case 'de':
        flag = <DE />;
        break;
      default:
        flag = null;
    }

    return {
      ...option,
      label: (
        <div>
          {flag} 
        </div>
      ),
    };
  });

  return <SelectNew name="language" control={control} value={currentLang} className="lang" onChange={handleLanguageChange} options={optionsWithFlags} />;
};

export default LanguageSwitcher;

import clsx from 'clsx';

import './AlertNotificationCustom.scss';

const AlertNotificationCustom = ({ icon, title, text, imgPath, imgAlt, link, buttonLabel, closeNotificationFunc, closeNotification, imgBg }) => {
  return (
    <div className={clsx('alert-notification-custom', { 'alert-notification-custom-show': !closeNotification })}>
      <div className="alert-notification-custom-body">
        {icon && (
          <div className="alert-notification-custom-icon" onClick={closeNotificationFunc}>
            {icon}
          </div>
        )}

        {imgPath && (
          <div className="alert-notification-custom-img" style={{ backgroundColor: imgBg }}>
            <img src={imgPath} alt={imgAlt} />
          </div>
        )}

        <div>
          {title && <h5 className="alert-notification-custom-title">{title}</h5>}

          {text && <p className="alert-notification-custom-text">{text}</p>}
        </div>
        {link && (
          <button type="button" className="alert-notification-custom-btn">
            <a rel="noreferrer" href={link} target="_blank">
              {buttonLabel}
            </a>
          </button>
        )}
      </div>
    </div>
  );
};

export default AlertNotificationCustom;

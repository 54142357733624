import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import './Button.scss';
import clsx from 'clsx';

const Button = ({ variant, to, icon, children, onClick, className, disabled, type = 'button', loading = false, ...props }) => {
  if (to) {
    return (
      <Link className={`link ${variant}`} to={to}>
        {icon && <div className="inner-icon">{icon}</div>}
        {children && <div className="inner-children">{children}</div>}
      </Link>
    );
  } else if (onClick) {
    return (
      <button className={clsx('button', variant ?? 'default', className ?? '', disabled ? 'disabled' : '')} type={type} onClick={onClick} {...props}>
        {loading ? <div className="inner-icon">{<LoadingOutlined />}</div> : icon ? <div className="inner-icon">{icon}</div> : null}
        {children && <div className="inner-children">{children}</div>}
      </button>
    );
  } else {
    return (
      <button
        className={clsx('button', variant ?? 'default', className ?? '', disabled ? 'disabled' : '')}
        disabled={disabled}
        type={type}
        {...props}
      >
        {icon && <div className="inner-icon">{icon}</div>}
        {children && <div className="inner-children">{children}</div>}
      </button>
    );
  }
};

export default Button;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../Modal';
import List from '../../List/List';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';
import { translation } from 'common_constants/translation';
import { getFormattedDateWithRelativeDays } from 'common_components';

import './InvitesList.scss';

const ITEMS_PER_PAGE = 5;

const InvitesList = ({ open, setInvitesListState, invitedClients }) => {
  const lang = useSelector((state) => state.common.language);

  const dataSource = [...invitedClients].reverse();
  return (
    <Modal
      className="invites-modal"
      title={translation.partnerInvitesListTitle[lang]}
      open={open}
      onCancel={() => setInvitesListState(false)}
      footer={null}
    >
      <List
        className="invites"
        pagination={{
          pageSize: ITEMS_PER_PAGE,
          style: { justifyContent: 'center' },
        }}
        grid={{
          gutter: 16,
          column: 1,
        }}
        dataSource={dataSource}
        renderItem={(item, index, pageIndex) => {
          const itemDateBefore = dataSource[index + pageIndex * ITEMS_PER_PAGE - 1]?.dateOfInvitation;

          return (
            <>
              <span className="invites-list-item-date">
                {index === 0 || dayjs(itemDateBefore).format('DD-MM-YYYY') !== dayjs(item.dateOfInvitation).format('DD-MM-YYYY')
                  ? getFormattedDateWithRelativeDays(item.dateOfInvitation)
                  : ''}
              </span>
              <li className="invites-list-item">
                <div className="invites-list-item__status">
                  <span>{item.phone}</span>
                  <span>
                    {item.status ? (
                      <CheckCircleTwoTone twoToneColor={'#52c41a'} style={{ marginRight: '4px' }} />
                    ) : (
                      <CloseCircleTwoTone twoToneColor={'#d50000'} style={{ marginRight: '4px' }} />
                    )}
                    {item.status ? translation.confirmed[lang] : translation.notConfirmed[lang]}
                  </span>
                </div>
              </li>
            </>
          );
        }}
      />
    </Modal>
  );
};

export default InvitesList;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { AppstoreOutlined } from '@ant-design/icons';
import { CLIENT_TOKEN, UKRAINE_STATES } from 'common_constants/business';
import { ROUTES, WEB_SITE_LINK, GET_IP_API } from 'common_constants/routes';
import { ZahistCred } from 'common_components';
import { setClientAuth, setModal } from '../../store/commonReducer';
import { setMainLoader } from '../../store/uiReducer';
import { ADD_CLIENT_EMAIL } from 'common_constants/modals';
import Contacts from '../../components/Contacts/Contacts';
import { translation } from 'common_constants/translation';
import RHForm from '../../components/RHForm';
import { useToaster } from '../../tools/hooks';
import { COUNTRY_PHONE_CODES } from 'common_constants/business';
import { getMaxPhoneLength, getPhonePattern } from '../../tools';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';

import './ClientReg.scss';

const getTokenData = (token) => (token ? JSON.parse(window.atob(token.split('.')[1])) : null);

const ClientReg = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.common.language);
  const [activeClass, setActiveClass] = useState(false);
  const makeAppointment = localStorage.getItem('makeAppointment');
  const [countryPhCode, setCountryPhCode] = useState(COUNTRY_PHONE_CODES.UA);

  const toaster = useToaster();

  const onFinish = (data) => {
    regRequest(data);
  };

  const regRequest = async (data) => {
    dispatch(setMainLoader(true));

    fetch(process.env.REACT_APP_API + '/auth/clientRegistration', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(registerCallback);
  };

  const registerCallback = (res) => {
    if (!res.status) {
      toaster.error(translation.clientLoginError[lang]);
      dispatch(setMainLoader(false));
      return;
    }

    if (res.clientExists) {
      toaster.error(translation.clientRegError1[lang]);
      dispatch(setMainLoader(false));
      return;
    }

    if (!res.accessToken) {
      toaster.error(translation.clientRegError2[lang]);
      dispatch(setMainLoader(false));
      return;
    }

    window.localStorage.setItem(CLIENT_TOKEN, res.accessToken);
    const { ph, _id, fil } = getTokenData(res.accessToken);
    dispatch(setClientAuth({ ph, _id, fil, token: res.accessToken }));
    dispatch(setMainLoader(false));

    toaster.success(translation.clientRegSuccess[lang]);

    fetch(GET_IP_API)
      .then((res) => res.json())
      .then(({ ip }) =>
        fetch(process.env.REACT_APP_API + '/clients/clientUpdateIp', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + res.accessToken },
          body: JSON.stringify({ ip }),
        }),
      );

    history.push(ROUTES.CLIENT_HOME);
  };

  const arrayFromObject = (obj) => Object.entries(obj).map((el) => ({ value: el[0], label: el[1] }));

  return (
    <div className="ClientReg_page">
      <a href={WEB_SITE_LINK}>
        <img src="/logoWithText.svg" alt="zahist" />
      </a>
      <div className="card-reg">
        {makeAppointment && <div style={{ textAlign: 'center', fontSize: '20px' }}>{translation.clientRegTitle1[lang]}</div>}
        <div className="menu">
          <div className="menu-wrapper">
            <AppstoreOutlined />
            <p className="title"> {translation.clientRegTitle2[lang]}</p>
          </div>
        </div>
        <div style={{ marginTop: 6 }}>
          <LanguageSwitcher />
        </div>
        <RHForm onFinish={onFinish} countryPhCode={countryPhCode} setCountryPhCode={setCountryPhCode}>
          {[
            {
              name: 'login',
              type: 'phone',
              rHFormOptions: {
                required: translation.clientRegPhoneMessage1[lang],
                maxLength: {
                  value: getMaxPhoneLength(countryPhCode),
                  message: translation.clientLoginPhoneMes[lang],
                },
                pattern: {
                  value: getPhonePattern(countryPhCode),
                  message: translation.clientLoginPhoneMes[lang],
                },
              },
              options: { label: translation.phone[lang], type: 'number' },
            },
            {
              name: 'state',
              type: 'select',
              rHFormOptions: {
                required: translation.clientRegStateMessage[lang],
              },
              options: {
                label: translation.clientRegStateLabel[lang],
                selectoptions: arrayFromObject(UKRAINE_STATES),
                placeholder: translation.clientRegStateLabel[lang],
              },
            },
            {
              name: 'name',
              rHFormOptions: {
                required: translation.clientRegNameMessage[lang],
              },
              options: { label: translation.clientProfileFullName[lang] },
            },
            {
              name: 'password',
              rHFormOptions: { required: translation.clientRegPasswordMessage[lang] },
              options: { label: translation.password[lang], type: 'password' },
            },
            {
              name: 'policyConfirmed',
              type: 'checkbox',
              rHFormOptions: { validate: (value) => value === true || translation.clientRegPivacyPolicy[lang] },
              options: {},
              component: (
                <>
                  {translation.clientRegPivacyPolicyCheckbox1[lang]}&nbsp;
                  <a href={`${WEB_SITE_LINK}/PolitykaKonfidentsiinosti.html`} rel="noreferrer" target="_blank">
                    {translation.clientRegPivacyPolicyCheckbox2[lang]}
                  </a>
                </>
              ),
            },
            {
              name: 'submit',
              options: { value: translation.register[lang] },
            },
          ]}
        </RHForm>

        <div style={{ textAlign: 'center' }}>
          {translation.clientRegQuestion[lang]} <Link to={ROUTES.CLIENT_LOGIN}>{translation.enter[lang]}</Link>
        </div>

        <div style={{ textAlign: 'center', fontSize: 10 }}>{translation.clientRegText[lang]}</div>
        <ZahistCred />
      </div>
      <Contacts setActiveClass={setActiveClass} activeClass={activeClass} />
    </div>
  );
};

export default ClientReg;

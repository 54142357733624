import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { request } from '../../tools';
import { translation } from 'common_constants/translation';

import './FiliiState.scss';

const FiliiState = () => {
  const params = useParams();
  const location = useLocation();
  const isSpecificFilParam = location.state?.filNameParam;

  const [filiaInfo, setFiliaInfo] = useState([]);
  const lang = useSelector((state) => state.common.language);

  useEffect(() => {
    const getInitialData = async () => {
      request(`/filii/filiiState/${params.state}`, null, (res) => {
        const arrFil = isSpecificFilParam ? res.data.filter((filii) => filii.name === isSpecificFilParam) : res.data;
        setFiliaInfo(arrFil);
      });
    };

    if (!filiaInfo.length) {
      getInitialData();
    }
  }, []);

  return (
    <section className="single-filia-section">
      <div className="single-filia-container">
        {filiaInfo.map((item) => (
          <React.Fragment key={item.i}>
            {item.name && <h3 className="single-filia-title">{item.full_name ? item.full_name : item.name}</h3>}

            <div className="single-filia-wrap">
              <div className="single-filia-card">
                {item.filiaManagerInfo[0]?.upa && (
                  <div className="single-filia-card-img">
                    <img src={process.env.REACT_APP_API + `/avatars/${item.filiaManagerInfo[0]?.upa}.jpeg`} loading="lazy" alt="Фото голови філії" />
                  </div>
                )}

                <div>
                  {item.filiaManagerInfo[0]?.name && <h5 className="single-filia-card-title">{item.filiaManagerInfo[0]?.name}</h5>}

                  {item.filiaManagerInfo[0]?.jobPosition && <p className="single-filia-card-text">{item.filiaManagerInfo[0]?.jobPosition}</p>}

                  {item.filiaManagerInfo[0]?.workPhone && (
                    <a className="single-filia-card-text fw-700" href="tel:$phoneLink">
                      {item.filiaManagerInfo[0]?.workPhone}
                    </a>
                  )}
                </div>
              </div>
              <div className="single-filia-card address">
                {item.image && item.ext && (
                  <div className="single-filia-card-img">
                    <img src={process.env.REACT_APP_API + `/filiiImages/${item.image}.${item.ext}`} loading="lazy" alt="Фото філії" />
                  </div>
                )}

                <div className="single-filia-card-wrap address">
                  {item.address && (
                    <>
                      <div className="single-filia-card-text">{translation.clientContactUsInfo1[lang]}</div>
                      <div className="single-filia-card-text fw-700">{item.address}</div>
                    </>
                  )}

                  {item.address_description && (
                    <>
                      <div className="single-filia-card-text ">{translation.filiiState[lang]}</div>

                      <div className="single-filia-card-text fw-700">{item.address_description}</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="single-filia-underline">
              <div></div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

export default FiliiState;

import { ROUTES } from 'common_constants/routes';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import AnonimBonusHome from './pages/AnonimBonusHome';
import AnonimCertificateHome from './pages/AnonimCertificateHome';
import ClientHome from './pages/ClientHome';
import PartnerHome from './pages/PartnerHome';
import PaymentForHotline from './components/PaymentForHotline';
import ClientChangePass from './pages/ClientChangePass';
import ClientConfirmNewPassword from './pages/ClientConfirmNewPassword';
import ClientContractInfo from './pages/ClientContractInfo';
import { PaymentSuccess } from './pages/ClientContracts/ClientContractsPartials';
import ClientContracts2 from './pages/ClientContracts2';
import ClientAutoPayment from './pages/ClientAutoPayment';
import ClientHotline from './pages/ClientHotline';
import Insurances from './pages/Insurances';
import ClientLogin from './pages/ClientLogin';
import ClientHotlineListPage from './pages/ClientMainPage';
import ClientProfile from './pages/ClientProfile';
import ClientReg from './pages/ClientReg';
import Homepage from './pages/Homepage';
import HotlineMeetInfo from './pages/HotlineMeetInfo';
import ClientTenders from './pages/ClientTenders';
import CreateTender from './pages/ClientTenders/CreateTender';
import ClientTenderInfo from './pages/ClientTenders/ClientTenderInfo';
import LawyerCV from './pages/LawyerCV';
import QuickChat from './pages/QuickChat';
import ClientContactUs from './pages/ClientContactUs';
import ClientFeedbacks from './pages/ClientFeedbacks';
import FiliiState from './pages/FiliiState';
import RedButtonPage from './pages/RedButtonPage';
import ClubCardPage from './pages/ClubCardPage';
import Chats from './pages/Chats';
import Webinar from './pages/Webinar';
import Register from './pages/Register';
import RegisterList from './pages/RegisterList';
import RegisterSearch from './pages/RegisterSearch';
import RegisterSelfSearch from './pages/RegisterSelfSearch';
import ConfirmChangePhone from './components/ConfirmChangePhone';
import PolicyConfirm from './Modals/PolicyConfirm';

export default function RouterSwitch() {
  const clientAuth = useSelector((state) => state.common.clientAuth);
  const { clientname } = clientAuth;

  return (
    <Switch>
      <Route exact path={ROUTES.CLIENT_LOGIN}>
        <ClientLogin />
      </Route>
      <Route exact path={ROUTES.CLIENT_REGISTRATION}>
        <ClientReg />
      </Route>
      <Route exact path={ROUTES.CLIENT_CHANGE_PASSWORD}>
        <ClientChangePass />
      </Route>
      <Route exact path={ROUTES.CLIENT_CONFIRM_NEW_PASSWORD}>
        <ClientConfirmNewPassword />
      </Route>
      <Route exact path={ROUTES.CLIENT_MY_HOTLINES}>
        <ClientHotlineListPage />
      </Route>
      <Route exact path={ROUTES.CLUB_CARD}>
        <ClientHome username={clientname} />
      </Route>
      <Route exact path={ROUTES.CLIENT_HOTLINE}>
        <ClientHotline username={clientname} />
      </Route>
      <Route exact path={ROUTES.PARTNER_ACCOUNT}>
        <PartnerHome username={clientname} />
      </Route>
      <Route exact path={ROUTES.ANONIM_BONUS_HOME}>
        <AnonimBonusHome />
      </Route>
      <Route exact path={ROUTES.CLIENT_HOME}>
        <Homepage username={clientname} />
      </Route>
      <Route exact path={ROUTES.CLIENT_VERIFICATION_PHONE}>
        <ConfirmChangePhone />
      </Route>
      <Route exact path={ROUTES.CLIENT_POLICY_CONFIRM}>
        <PolicyConfirm />
      </Route>

      <Route exact path={ROUTES.CLIENT_INSURANCE}>
        <Insurances />
      </Route>
      <Route path={ROUTES.ANONIM_CERTIFICATE_OLD_}>
        <AnonimCertificateHome />
      </Route>
      <Route path={ROUTES.ANONIM_CERTIFICATE_}>
        <AnonimCertificateHome />
      </Route>
      <Route path={ROUTES.CLIENT_CONTRACTS}>
        <ClientContracts2 />
      </Route>
      <Route path={ROUTES.AUTOPAY_PAGE}>
        <ClientAutoPayment />
      </Route>
      <Route path={ROUTES.CLIENT_CONTRACT_INFO}>
        <ClientContractInfo />
      </Route>
      <Route path={ROUTES.CLIENT_PROFILE}>
        <ClientProfile />
      </Route>
      <Route path={ROUTES.PAYMENT_SUCCESS}>
        <PaymentSuccess />
      </Route>
      <Route path={ROUTES.PAYMENT_FOR_HOTLINE}>
        <PaymentForHotline />
      </Route>
      <Route path={ROUTES.CLIENT_TENDERS}>
        <ClientTenders />
      </Route>
      <Route path={ROUTES.CREATE_TENDER}>
        <CreateTender />
      </Route>
      <Route path={ROUTES.CLIENT_TENDER_INFO}>
        <ClientTenderInfo />
      </Route>
      <Route path={ROUTES.CLIENT_QUICK_CHAT}>
        <QuickChat />
      </Route>
      <Route path={ROUTES.CLIENT_CONTACT_US}>
        <ClientContactUs />
      </Route>
      <Route path={ROUTES.CLIENTS_FEEDBACK}>
        <ClientFeedbacks />
      </Route>
      <Route path={ROUTES.FILII_STATE}>
        <FiliiState />
      </Route>
      <Route path={ROUTES.RED_BUTTON}>
        <RedButtonPage />
      </Route>
      <Route path={ROUTES.CLUB_CARD_PAGE}>
        <ClubCardPage />
      </Route>
      <Route path={ROUTES.CLIENT_CHATS}>{!window?.ReactNativeWebView ? <Chats /> : <Redirect to={ROUTES.CLIENT_HOME} />}</Route>
      <Route exact path={ROUTES.WEBINAR_ID}>
        <Webinar />
      </Route>
      <Route exact path={ROUTES.REGISTER}>
        <Register />
      </Route>
      <Route exact path={ROUTES.REGISTER_LIST}>
        <RegisterList />
      </Route>
      <Route exact path={ROUTES.REGISTER_SEARCH}>
        <RegisterSearch />
      </Route>
      <Route exact path={ROUTES.REGISTER_SELF_SEARCH}>
        <RegisterSelfSearch />
      </Route>
      {/* should be last */}
      <Route exact path={ROUTES.LAWYER_CV}>
        <LawyerCV />
      </Route>
      <Route exact path={ROUTES.HOTLINE_MEET_INFO}>
        <HotlineMeetInfo />
      </Route>
    </Switch>
  );
}

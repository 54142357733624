import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../Modal/Modal';
import Button from '../../Button';
import Form from '../../Form/Form';
import InputNumber from '../../InputNumber/InputNumber';
import { translation } from 'common_constants/translation';

import { info, warn } from '../../../tools';

import './RequestPayment.scss';

const RequestPayment = ({ open, setRequestPaymentState, balance, setBalance, transactions, setTransactions, paymentCard }) => {
  const { _id, token } = useSelector((state) => state.common.clientAuth);
  const [temporaryBalanceState, setTemporaryBalanceState] = useState(balance);
  const lang = useSelector((state) => state.common.language);
  const handleChangeTemporaryBonusesState = (value) => setTemporaryBalanceState(value);

  const onChangePrice = (value) => {
    const calcValue = balance - value;

    if (!value) return handleChangeTemporaryBonusesState(balance);

    if (calcValue < 0) {
      warn(
        translation.partnerRequestPaymentWarn1_1[lang],
        `${translation.partnerRequestPaymentWarn1_2[lang]} ${calcValue * -1} ${translation.currency[lang]}!`,
      );
      setTemporaryBalanceState(balance);
      return;
    }

    if (value < 0) {
      warn(translation.partnerRequestPaymentWarn2_1[lang], translation.partnerRequestPaymentWarn2_2[lang]);
      handleChangeTemporaryBonusesState(balance);
      return;
    }

    handleChangeTemporaryBonusesState((balance - value).toFixed(2));
  };

  useEffect(() => {
    setTemporaryBalanceState(balance);
  }, [balance]);

  const onFinish = (values) => {
    const { amount } = values;

    fetch(process.env.REACT_APP_API + '/partners/requestPayment', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
      body: JSON.stringify({ partnerId: _id, amount }),
    })
      .then((res) => res.json())
      .then(({ status, newTransaction }) => {
        if (status) {
          info(translation.partnerRequestPaymentInfo[lang]);
          const updatedTransactions = [...transactions, newTransaction];
          setTransactions(updatedTransactions);
          return setBalance((balance - amount).toFixed(2));
        }

        warn(translation.partnerRequestPaymentWarn3[lang]);
      });
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    if (['.', ','].includes(key)) {
      e.preventDefault();
    }
  };

  return (
    <Modal
      className="requestPayment"
      title={translation.partnerRequestPaymentModalTitle[lang]}
      open={open}
      onCancel={() => setRequestPaymentState(false)}
      footer={null}
    >
      <div className="requestPayment-balance-inner">
        {paymentCard ? (
          <div className="requestPayment-card">
            <span className="requestPayment-card__title">{translation.partnerRequestPaymentModalText1[lang]} </span>
            <span className="requestPayment-card__number">{paymentCard.match(/.{1,4}/g).join(' ')}</span>
          </div>
        ) : (
          <div className="requestPayment-card">
            <div className="requestPayment-card__info">{translation.partnerRequestPaymentModalText2[lang]}</div>
          </div>
        )}
        <span className="requestPayment-balance__title">{translation.partnerRequestPaymentModalText3[lang]} </span>
        <span> {temporaryBalanceState} </span>
      </div>
      <Form name="basic" style={{ maxWidth: 600, overflow: 'hidden' }} initialValues={{ amount: balance }} onFinish={onFinish}>
        <Form.Item
          name="amount"
          label="Сума"
          rules={[
            {
              type: 'number',
              min: 0.01,
              max: Number(balance),
              required: true,
              message: `${translation.partnerRequestPaymentModalMessage[lang]} ${balance}`,
            },
          ]}
        >
          <InputNumber onChange={onChangePrice} onKeyDown={handleKeyPress} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button className="button requestPayment-submit-btn" htmlType="submit">
            {translation.inviteBtn[lang]}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RequestPayment;
